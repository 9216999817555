import { Progress } from 'antd';
import React from 'react';
import styles from './ProgressLine.module.scss';
import { formVolume } from '../../Constans/Constans';
import { nomenclatureUnitMapping } from '../../../../common/nomenclature-unit.mapping';

const ProgressLine = ({ infoData, remainVolume }) => {
  const formPercent = value => {
    return new Intl.NumberFormat('ru', { style: 'percent', maximumFractionDigits: 1 }).format(value);
  };
  const currentPercent = (): number => {
    return Number((infoData?.distributedVolume * 100) / infoData?.volume);
  };
  const remainCurrentPercent = (): number => {
    return Number(100 - currentPercent());
  };

  return (
    <div className={styles.container}>
      <div className={styles.distributionContainer}>
        <div className={styles.distributionBlock}>
          <p className={styles.distributionFirstColumnText}>Распределено</p>
          <p className={styles.distributionFirstColumnUnit}>
            {infoData?.distributedVolume === null ? '0' : formVolume(infoData?.distributedVolume)}&nbsp;
            {nomenclatureUnitMapping[infoData?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.unit]}
          </p>
        </div>
        <div>
          <p className={styles.distributionSecondColumnText}>Осталось распределить</p>
          <p className={styles.distributionSecondColumnUnit}>
            {infoData?.volume === null ? '0' : formVolume(remainVolume())}&nbsp;
            {nomenclatureUnitMapping[infoData?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.unit]}
          </p>
        </div>
      </div>
      <Progress
        size={['100%', 12]}
        strokeColor={'var(--color-orange400)'}
        percent={currentPercent()}
        showInfo={false}
      />
      <div className={styles.percentContainer}>
        <p className={styles.textStartPercent}>{formPercent(currentPercent() / 100)}</p>
        <p className={styles.textEndPercent}>{formPercent(remainCurrentPercent() / 100)}</p>
      </div>
    </div>
  );
};

export default ProgressLine;
