import React from 'react';

const ChevronIconSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.41 9L12 13.59L16.59 9L18 10.42L12 16.42L6 10.42L7.41 9Z" fill="var(--color-orange300)" />
    </svg>
  );
};

export default ChevronIconSvg;
