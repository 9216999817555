import React, { useEffect, useLayoutEffect, useState } from 'react';
import styles from './PublicTN.module.scss';
import PublicHeader from '../../components/PublicHeader/PublicHeader';
import FooterLk from '../../components/newUI/FooterLk/FooterLk';
import { fetchGet } from '../../common/proxy-1C-fetch-auth';
import ButtonNotFilled from '../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import iconFile from './assets/IconFile.png';
import MobileIconFile from './assets/MobileIconFile.png';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { toastError } from '../../common/toastError.helper';

import errorImg from '../../assets/img/EmptyPng.png';
import LoadingGif from '../../assets/img/Loader-Solber.gif';
import { nomenclatureUnitMapping } from '../../common/nomenclature-unit.mapping';

interface IFile {
  acceptRanges: string;
  contentLength: number;
  lastModified: string;
  location: string;
}

interface IInfoTN {
  title: string;
  value: string;
}

const styleStatusProfile = {
  Отклонен: {
    color: 'var(--color-red600)',
    border: '1px solid var(--color-red600)',
    padding: ' 4px 12px',
    borderRadius: '30px',
    width: 'fit-content',
  },
  'На проверке': {
    color: 'var(--color-orange400)',
    border: '1px solid var(--color-orange400)',
    padding: ' 4px 12px',
    borderRadius: '30px',
    width: 'fit-content',
  },
  Выполнен: {
    color: 'var(--color-green600)',
    border: '1px solid var(--color-green600)',
    padding: ' 4px 12px',
    borderRadius: '30px',
    width: 'fit-content',
  },
};
const PublicTn = () => {
  const [infoTN, setInfoTn] = useState<IInfoTN[]>([]);
  const [file, setFile] = useState<IFile>();
  const [numberTn, setNumberTn] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const location = useLocation();
  const idTN = location.pathname.split('/')[3];
  const getTnInfo = async () => {
    setSending(true);
    try {
      const response = await fetchGet(`/bill-lading/get-link/${idTN}`, {});
      if (response?.message?.[0] === 'Вы ещё не подгрузили ТН в личный кабинет или ТН была распечатана не через ЛК') {
        setError(true);
      }
      if (toastError(response)) {
        return;
      }
      const correctResponse = [
        { title: 'Номер рейса', value: response.response.flightName || 'Нет данных' },
        { title: 'Транспорт', value: response.response.car || 'Нет данных' },
        { title: 'Водитель', value: response.response.driver || 'Нет данных' },
        { title: 'Номенклатура', value: response.response.nomenclature || 'Нет данных' },
        {
          title: 'Объем',
          value: `${response.response.volumeDownload} ${nomenclatureUnitMapping?.[response.response.unitDownload] || 'Нет данных'}
                / ${response.response.volumeUnload} ${nomenclatureUnitMapping?.[response.response.unitUnload] || 'Нет данных'}`,
        },
        { title: 'Статус', value: 'Выполнен' },
      ];
      setFile(response.response.files[0]);
      setNumberTn(response.response.numberTn);
      setInfoTn(correctResponse);
    } catch (e) {
      console.log(e);
    } finally {
      setSending(false);
    }
  };
  const handleDownload = async (): Promise<void> => {
    try {
      const response = await fetch(file.location);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = 'filename';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Ошибка при скачивании файла:', error);
    }
  };

  useLayoutEffect(() => {
    getTnInfo();
  }, []);
  if (sending)
    return (
      <div className={styles.errorContentContainer}>
        <div className={styles.preLoader}>
          <div>
            <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
          </div>
        </div>
      </div>
    );
  return (
    <div className={styles.pageContainer}>
      <PublicHeader />
      {error ? (
        <div className={styles.errorContentContainer}>
          <img className={styles.errorImg} src={errorImg} alt="errorImg" />
          <p className={styles.errorTitle}>Транспортная накладная отсутствует</p>
          <p className={styles.subTitle}>Транспортная накладная появится, как только рейс будет завершен</p>
        </div>
      ) : (
        <div className={styles.contentContainer}>
          <h1 className={styles.title}> Транспортная накладная {numberTn} </h1>
          <div className={styles.tableHeadersContainer}>
            {infoTN.map(item => (
              <div key={item.title}>
                <p className={styles.tableHeader}>{item.title}</p>
                <p style={item.title === 'Статус' ? styleStatusProfile[item?.value] : {}} className={styles.tableBody}>
                  {item.value}
                </p>
              </div>
            ))}
          </div>
          <div className={styles.fileContainer}>
            <img src={isMobile ? MobileIconFile : iconFile} alt="iconFile" />
            <div>
              <p className={styles.fileText}>Размер файла: {file?.contentLength} Мб</p>
              <p className={styles.fileText} style={{ marginBottom: '16px' }}>
                Дата: {file?.lastModified ? new Date(file?.lastModified).toLocaleString().split(',')[0] : 'Нет данных'}
              </p>
              <ButtonNotFilled text={'Скачать'} sizeText={'small'} width={'137px'} onClick={() => handleDownload()} />
            </div>
          </div>
        </div>
      )}
      <FooterLk />
    </div>
  );
};

export default PublicTn;
