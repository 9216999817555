import React from 'react';
import { formPrice } from '../../common/form-price.helper';
import styles from './UpdAndRegistryPage.module.scss';

export const columnsUpd = [
  {
    title: 'Даты документа',
    dataIndex: 'date',
    width: 226,
    key: 'date',
    render: text => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '0 0 0 24px',
          justifyContent: 'space-between',
          height: '46px',
        }}
      >
        <div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <p style={{ whiteSpace: 'nowrap' }}>Дата УПД:</p>
            <p
              className={styles.dateMainText}
              style={{
                marginLeft: '10px',
                height: '22px',
                alignItems: 'center',
                textAlign: 'center',
                width: '113px',
                borderRadius: '30px',
                border: '1px solid var(--color-blue600)',
                color: 'var(--color-blue600)',
              }}
            >
              {text?.registryDate?.slice(0, 10).split('-').reverse().join('.')}
            </p>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}>
            <p style={{ whiteSpace: 'nowrap' }}>Дата договора:</p>
            <p
              className={styles.dateMainText}
              style={{
                marginLeft: '10px',
                height: '22px',
                alignItems: 'center',
                textAlign: 'center',
                width: '113px',
                borderRadius: '30px',
                border: '1px solid var(--color-blue600)',
                color: 'var(--color-blue600)',
              }}
            >
              {text?.contractDate?.slice(0, 10).split('-').reverse().join('.')}
            </p>
          </div>
        </div>
        <div
          style={{
            color: 'var(--color-gray200)',
            height: '28px',
            border: '1px solid var(--color-gray200)',
            margin: '0 24px',
          }}
        ></div>
      </div>
    ),
  },
  {
    title: 'Номенклатура',
    dataIndex: 'nomenclatureContentAll',
    width: 264,
    key: 'nomenclatureContentAll',
    render: text => (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div></div>
        <p>{text}</p>
        <div
          style={{
            color: 'var(--color-gray200)',
            height: '28px',
            border: '1px solid var(--color-gray200)',
            margin: '0 24px',
          }}
        ></div>
      </div>
    ),
  },
  {
    title: 'Номер УПД',
    dataIndex: 'registryNumber',
    width: 264,
    key: 'registryNumber',
    render: text => (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div>
          <div style={{ display: 'flex' }}>
            <p> {text}</p>
          </div>
        </div>
        <div
          style={{
            color: 'var(--color-gray200)',
            height: '28px',
            border: '1px solid var(--color-gray200)',
            margin: '0 24px',
          }}
        ></div>
      </div>
    ),
  },
  {
    title: 'Номер договора',
    dataIndex: 'contractTitle',
    width: 264,
    key: 'contractTitle',
    render: text => (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div>
          <div style={{ display: 'flex' }}>
            <p> {text}</p>
          </div>
        </div>
        <div
          style={{
            color: 'var(--color-gray200)',
            height: '28px',
            border: '1px solid var(--color-gray200)',
            margin: '0 24px',
          }}
        ></div>
      </div>
    ),
  },
  {
    title: 'Сумма в руб. с НДС',
    dataIndex: 'documentAmount',
    width: 264,
    key: 'documentAmount',
    render: text => (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div>
          <div style={{ display: 'flex' }}>
            <p> {formPrice(text, 'RUB')} </p>
          </div>
        </div>
      </div>
    ),
  },
];

export const FiltersInitialState = {
  dateRange: ['', ''],
  dateRangeSecond: ['', ''],
  filterSearch: '',
};
