import React from 'react';

export const CloseCircleRedSvg = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.0002 3.33398C29.2168 3.33398 36.6668 10.784 36.6668 20.0007C36.6668 29.2173 29.2168 36.6673 20.0002 36.6673C10.7835 36.6673 3.3335 29.2173 3.3335 20.0007C3.3335 10.784 10.7835 3.33398 20.0002 3.33398ZM25.9835 11.6673L20.0002 17.6507L14.0168 11.6673L11.6668 14.0173L17.6502 20.0007L11.6668 25.984L14.0168 28.334L20.0002 22.3507L25.9835 28.334L28.3335 25.984L22.3502 20.0007L28.3335 14.0173L25.9835 11.6673Z"
        fill="var(--color-red500)"
      />
    </svg>
  );
};
