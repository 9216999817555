import React from 'react';

const HandCoinSvg = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5 2C13.74 2 11.5 4.24 11.5 7C11.5 9.76 13.74 12 16.5 12C19.26 12 21.5 9.76 21.5 7C21.5 4.24 19.26 2 16.5 2ZM16.5 10C14.84 10 13.5 8.66 13.5 7C13.5 5.34 14.84 4 16.5 4C18.16 4 19.5 5.34 19.5 7C19.5 8.66 18.16 10 16.5 10ZM19.5 16H17.5C17.5 14.8 16.75 13.72 15.63 13.3L9.47 11H1.5V22H7.5V20.56L14.5 22.5L22.5 20V19C22.5 17.34 21.16 16 19.5 16ZM5.5 20H3.5V13H5.5V20ZM14.47 20.41L7.5 18.5V13H9.11L14.93 15.17C15.27 15.3 15.5 15.63 15.5 16C15.5 16 13.5 15.95 13.2 15.85L10.82 15.06L10.19 16.96L12.57 17.75C13.08 17.92 13.61 18 14.15 18H19.5C19.89 18 20.24 18.24 20.4 18.57L14.47 20.41Z"
        fill="var(--color-orange400)"
      />
    </svg>
  );
};

export default HandCoinSvg;
