import React from 'react';

const LogoMobileSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
      <g clip-path="url(#clip0_25853_94502)">
        <mask
          id="mask0_25853_94502"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="48"
          height="48"
        >
          <path d="M48 0H0V48H48V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_25853_94502)">
          <path
            d="M6 0C2.5 0 0 3 0 6V42C0 45 2.5 48 6 48H42C45.5 48 48 45.2 48 42V6C48 3 45.5 0 42 0H6Z"
            fill="url(#paint0_linear_25853_94502)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M48 12.6225L47.9935 10.8935L47.8524 10.9007H33.9412L33.301 9.59954H48V8.76141H33.0231C32.8888 8.57658 32.7769 7.56842 32.8373 7.45967H37.8552C37.8552 6.37551 37.9049 5.63759 38.71 4.78263C39.8979 3.52195 42.0526 3.52578 43.2658 4.74025C44.1081 5.58464 44.1718 6.44822 44.1718 7.45967H48V6.52964H47.4243C47.4243 4.81349 46.5521 3.38564 45.4514 2.27388C42.5859 -0.617524 37.2874 0.0443506 35.2189 4.07969C34.8789 4.74388 34.7853 5.20534 34.6599 5.82433C34.6169 6.03626 34.5702 6.26665 34.5086 6.52997H32.8373C32.9424 5.51459 33.1884 4.58601 33.5592 3.72557C34.229 2.17151 35.2131 0.871758 36.5763 0H35.2491C35.0038 0.199412 34.766 0.41384 34.5368 0.643331C33.773 1.40819 33.2699 2.17791 32.8092 3.2482C32.3854 4.2334 32 5.63072 32 6.99475C32 9.00531 32.7719 10.881 33.5975 12.0935C34.499 13.4189 35.8416 14.5244 37.2977 15.1789C41.4058 17.0233 45.6067 15.5834 48 12.6225ZM45.0069 6.52997H46.5865C46.5865 4.63128 44.934 2.4361 43.2315 1.7986C41.2919 1.0713 39.5378 1.18507 37.8341 2.32457C37.6372 2.45668 37.5146 2.56229 37.3858 2.67327C37.3032 2.7444 37.2181 2.81773 37.1092 2.90166C36.5178 3.35459 35.7804 4.61343 35.5704 5.45346L35.3461 6.52997H37.0174C37.432 4.56306 38.7119 2.99732 40.9202 2.99575C43.2589 2.99575 44.5252 4.45641 45.0069 6.52997ZM37.5453 14.311C39.636 15.4371 42.2818 15.361 44.4136 14.3968L45.2867 13.8761H36.7396L37.5453 14.311ZM35.717 13.0398H46.3088C46.631 13.0398 47.4362 11.998 47.6098 11.7388H34.4167C34.6065 12.0206 35.351 13.0398 35.717 13.0398Z"
            fill="white"
            fill-opacity="0.4"
          />
          <path
            d="M41 30.4226C41 31.622 40.7808 32.7448 40.3425 33.7907C39.9043 34.8228 39.3017 35.7294 38.5347 36.5104C37.7813 37.2776 36.8911 37.8842 35.8641 38.3305C34.8506 38.7768 33.7617 39 32.5974 39H9V34.7113H32.5974C33.1865 34.7113 33.7342 34.5998 34.2411 34.3765C34.7477 34.1535 35.186 33.8466 35.5558 33.4562C35.9394 33.0655 36.2406 32.6123 36.4598 32.0963C36.679 31.5662 36.7885 31.0084 36.7885 30.4226C36.7885 29.8367 36.679 29.286 36.4598 28.7699C36.2406 28.2538 35.9394 27.8005 35.5558 27.41C35.186 27.0195 34.7477 26.7127 34.2411 26.4895C33.7342 26.2664 33.1865 26.1548 32.5974 26.1548H15.4229C14.2725 26.1548 13.1837 25.9317 12.1565 25.4853C11.1293 25.0391 10.2322 24.4324 9.46526 23.6653C8.71198 22.8842 8.10936 21.9707 7.6574 20.9247C7.21913 19.8787 7 18.7629 7 17.5774C7 16.4059 7.21913 15.2971 7.6574 14.251C8.10936 13.205 8.71198 12.2985 9.46526 11.5314C10.2322 10.7503 11.1293 10.1367 12.1565 9.69038C13.1837 9.23013 14.2725 9 15.4229 9H38V13.2887H15.4229C14.8477 13.2887 14.2999 13.4003 13.7794 13.6234C13.2727 13.8466 12.8276 14.1534 12.4441 14.5439C12.0606 14.9345 11.7593 15.3947 11.5402 15.9247C11.321 16.4407 11.2115 16.9916 11.2115 17.5774C11.2115 18.1632 11.321 18.7211 11.5402 19.251C11.7593 19.7671 12.0606 20.2204 12.4441 20.6109C12.8276 21.0014 13.2727 21.3082 13.7794 21.5314C14.2999 21.7545 14.8477 21.8661 15.4229 21.8661H32.5974C33.7617 21.8661 34.8506 22.0962 35.8641 22.5565C36.8911 23.0028 37.7813 23.6165 38.5347 24.3975C39.3017 25.1646 39.9043 26.0711 40.3425 27.1171C40.7808 28.1492 41 29.2511 41 30.4226Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_25853_94502"
          x1="0"
          y1="0"
          x2="51.4743"
          y2="4.07103"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--color-orange200)" />
          <stop offset="1" stop-color="var(--color-orange400)" />
        </linearGradient>
        <clipPath id="clip0_25853_94502">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoMobileSvg;
