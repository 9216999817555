import React from 'react';
type TProps = {
  color?: string;
  style?: any;
  onClick?: () => void;
};

export const QuestionSvg = ({ color = 'var(--color-blue800)', style = {}, onClick = () => {} }: TProps) => {
  return (
    <svg
      style={style}
      onClick={onClick}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_29721_73195)">
        <path
          d="M8.00065 5.16634C7.58644 5.16634 7.25065 5.50213 7.25065 5.91634C7.25065 6.19248 7.02679 6.41634 6.75065 6.41634C6.47451 6.41634 6.25065 6.19248 6.25065 5.91634C6.25065 4.94984 7.03415 4.16634 8.00065 4.16634C8.96715 4.16634 9.75065 4.94984 9.75065 5.91634C9.75065 6.55857 9.40442 7.11939 8.89083 7.42325C8.75966 7.50085 8.65138 7.58559 8.58084 7.66872C8.51235 7.74943 8.50065 7.80208 8.50065 7.83301V8.66634C8.50065 8.94248 8.27679 9.16634 8.00065 9.16634C7.72451 9.16634 7.50065 8.94248 7.50065 8.66634V7.83301C7.50065 7.49575 7.6483 7.2221 7.81838 7.02168C7.9864 6.82369 8.19591 6.67248 8.38163 6.5626C8.60361 6.43127 8.75065 6.19071 8.75065 5.91634C8.75065 5.50213 8.41487 5.16634 8.00065 5.16634Z"
          fill={color}
        />
        <path
          d="M8.00065 11.333C8.36884 11.333 8.66732 11.0345 8.66732 10.6663C8.66732 10.2982 8.36884 9.99967 8.00065 9.99967C7.63246 9.99967 7.33398 10.2982 7.33398 10.6663C7.33398 11.0345 7.63246 11.333 8.00065 11.333Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.833984 7.99967C0.833984 4.04163 4.04261 0.833008 8.00065 0.833008C11.9587 0.833008 15.1673 4.04163 15.1673 7.99967C15.1673 11.9577 11.9587 15.1663 8.00065 15.1663C4.04261 15.1663 0.833984 11.9577 0.833984 7.99967ZM8.00065 1.83301C4.5949 1.83301 1.83398 4.59392 1.83398 7.99967C1.83398 11.4054 4.5949 14.1663 8.00065 14.1663C11.4064 14.1663 14.1673 11.4054 14.1673 7.99967C14.1673 4.59392 11.4064 1.83301 8.00065 1.83301Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_29721_73195">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
