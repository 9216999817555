import React, { useRef, useState } from 'react';
import styles from './SelectLk.module.scss';
import ChevronUpSvg from './assets/ChevronUpSvg';
import ChevronDownSvg from './assets/ChevronDownSvg';
import useClickOutside from '../../../CustomHooks/useClickOutside';
import cx from 'classnames';
import { IOption, IPropsSelectLk } from './type';
import { generateStyleButtonContainer, generateStyleButtonText } from './utils';

const SelectLk = ({
  placeholder,
  width = '100%',
  options,
  error,
  errorText,
  disabled,
  value,
  setValue,
  heightSelectWindow = 'fit-content',
  size = 'big',
}: IPropsSelectLk) => {
  const [openSelectWindow, setOpenSelectWindow] = useState<boolean>(false);
  const selectContainerRef = useRef<HTMLDivElement>(null);
  useClickOutside(selectContainerRef, setOpenSelectWindow);
  const changingChevronSvg = (openState: boolean) => {
    if (openState) {
      return <ChevronUpSvg />;
    }
    return <ChevronDownSvg />;
  };
  const handleSelect = (option: IOption) => {
    setValue(option);
    setOpenSelectWindow(false);
  };
  const handleOpenWindow = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    setOpenSelectWindow(!openSelectWindow);
  };

  return (
    <div ref={selectContainerRef} style={{ width: width }} className={styles.container}>
      <button
        disabled={disabled}
        onClick={e => handleOpenWindow(e)}
        className={cx(...generateStyleButtonContainer(size, openSelectWindow, error))}
      >
        <div className={styles.textAndSvgContainer}>
          <p className={cx(...generateStyleButtonText(size, value?.value))}>{value?.label || placeholder}</p>
          <div>{changingChevronSvg(openSelectWindow)}</div>
        </div>
      </button>
      {error && errorText && <p className={styles.errorText}>{errorText}</p>}
      {openSelectWindow && (
        <div style={{ width: width, height: heightSelectWindow }} className={styles.optionsContainer}>
          {options.map(option => (
            <div key={option.value} className={styles.optionContainer} onClick={() => handleSelect(option)}>
              <div className={styles.optionContent}>{option.label}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectLk;
