// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EmployeesPage_Container__rmPvu {\n  padding: 8px;\n  border-radius: 12px;\n  background: var(--color-blue50);\n}\n\n.EmployeesPage_titleAndSubTitleContainer__Kjsql {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin-bottom: 40px;\n}\n\n.EmployeesPage_titleAndAddButton__EBsKs {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.EmployeesPage_text__bIvat {\n  color: #373D50;\n  font-family: \"Golos Text\";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n}\n\n.EmployeesPage_textSoft__Aa0O0 {\n  color: #6C779C;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 20px;\n}\n\n@media (max-width: 768px) {\n  .EmployeesPage_titleAndSubTitleContainer__Kjsql {\n    gap: 4px;\n    margin-bottom: 24px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/EmployeesPage/EmployeesPage.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,mBAAA;EACA,+BAAA;AACF;;AACA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,mBAAA;AAEF;;AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAGF;;AADA;EACE,cAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAIF;;AAFA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAKF;;AAHA;EACE;IACE,QAAA;IACA,mBAAA;EAMF;AACF","sourcesContent":[".Container {\n  padding: 8px;\n  border-radius: 12px;\n  background: var(--color-blue50);\n}\n.titleAndSubTitleContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin-bottom: 40px;\n}\n.titleAndAddButton {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.text{\n  color: #373D50;\n  font-family:\"Golos Text\";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n}\n.textSoft{\n  color: #6C779C;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 20px;\n}\n@media (max-width: 768px) {\n  .titleAndSubTitleContainer {\n    gap: 4px;\n    margin-bottom: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "EmployeesPage_Container__rmPvu",
	"titleAndSubTitleContainer": "EmployeesPage_titleAndSubTitleContainer__Kjsql",
	"titleAndAddButton": "EmployeesPage_titleAndAddButton__EBsKs",
	"text": "EmployeesPage_text__bIvat",
	"textSoft": "EmployeesPage_textSoft__Aa0O0"
};
export default ___CSS_LOADER_EXPORT___;
