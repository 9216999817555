import React from 'react';
import styles from './TodayEarnings.module.scss';
import { ISalary } from '../../tipe';
import { formPrice } from '../../../../../common/form-price.helper';
interface IProps {
  todaySalary: ISalary;
}
const TodayEarnings = ({ todaySalary }: IProps) => {
  const today = new Date().toISOString().split('T')[0].replace(/-/g, '.').split('.').reverse().join('.');
  const currentMonth = today.split('.')[1];
  const thisYear = today.split('.')[2];
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <svg width="68" height="65" viewBox="0 0 68 65" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.2">
            <path
              d="M5.78577 43.6437C5.78577 45.1822 7.03297 46.4294 8.57148 46.4294C10.11 46.4294 11.3572 45.1822 11.3572 43.6437V21.358C11.3572 19.8195 10.11 18.5723 8.57148 18.5723C7.03297 18.5723 5.78577 19.8195 5.78577 21.358V43.6437Z"
              fill="var(--color-orange400)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M61.2712 18.5863C61.04 18.5721 60.7883 18.5722 60.5272 18.5723L60.4621 18.5723H50.2268C41.7903 18.5723 34.5714 25.0205 34.5714 33.4294C34.5714 41.8383 41.7903 48.2865 50.2268 48.2865H60.4621L60.5272 48.2865C60.7883 48.2866 61.04 48.2867 61.2712 48.2725C64.6981 48.0624 67.7287 45.4585 67.9835 41.8215C68.0002 41.583 68.0001 41.326 67.9999 41.0877L67.9999 41.023V25.8358L67.9999 25.7711C68.0001 25.5328 68.0002 25.2758 67.9835 25.0373C67.7287 21.4003 64.6981 18.7964 61.2712 18.5863ZM49.3193 37.3913C51.4913 37.3913 53.252 35.6175 53.252 33.4294C53.252 31.2413 51.4913 29.4675 49.3193 29.4675C47.1473 29.4675 45.3865 31.2413 45.3865 33.4294C45.3865 35.6175 47.1473 37.3913 49.3193 37.3913Z"
              fill="var(--color-orange400)"
            />
            <path
              opacity="0.5"
              d="M61.0892 18.5771C61.0892 14.1919 60.9297 9.48803 58.1265 6.11586C57.8559 5.79033 57.5678 5.47425 57.2611 5.16747C54.4814 2.38784 50.9568 1.15426 46.6021 0.568789C42.3707 -0.000100566 36.9641 -5.55994e-05 30.1382 1.12943e-06H22.2904C15.4645 -5.44925e-05 10.0578 -9.85732e-05 5.82648 0.568791C1.47177 1.15427 -2.05289 2.38784 -4.83253 5.16748C-7.61216 7.94711 -8.84574 11.4718 -9.43121 15.8265C-10.0001 20.0578 -10.0001 25.4644 -10 32.2904V32.7095C-10.0001 39.5355 -10.0001 44.9421 -9.43121 49.1735C-8.84573 53.5282 -7.61216 57.0529 -4.83252 59.8325C-2.05289 62.6121 1.47178 63.8457 5.82648 64.4312C10.0578 65.0001 15.4644 65 22.2903 65H30.1381C36.964 65 42.3707 65.0001 46.6021 64.4312C50.9568 63.8457 54.4814 62.6121 57.2611 59.8325C58.0207 59.0729 58.6658 58.2562 59.2142 57.3803C60.8874 54.7081 61.0892 51.4329 61.0892 48.28C60.9101 48.2858 60.7214 48.2857 60.5274 48.2857L50.2268 48.2857C41.7902 48.2857 34.5714 41.8374 34.5714 33.4286C34.5714 25.0197 41.7902 18.5715 50.2268 18.5715L60.5272 18.5714C60.7212 18.5714 60.9101 18.5713 61.0892 18.5771Z"
              fill="var(--color-orange400)"
            />
          </g>
        </svg>
      </div>
      <div className={styles.firstContentBlock}>
        <p className={styles.ContentBlockTitle}>За сегодня</p>
        <p className={styles.ContentBlockPrice}>{formPrice(todaySalary?.todaySalary?.driversSalary || 0, 'RUB')}</p>
      </div>

      <div>
        <p className={styles.ContentBlockTitle}>
          01.{currentMonth}.{thisYear} - {today}
        </p>
        <p className={styles.ContentBlockPrice}>
          {formPrice(todaySalary?.salaryFromMonthStart?.driversSalary || 0, 'RUB')}
        </p>
      </div>
    </div>
  );
};

export default TodayEarnings;
