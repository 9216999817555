import React from 'react';

export const OwnershipButton = ({ children, disabled, isSvg, svg, color, modifier, fontColor, ...props }) => {
  return (
    <>
      <button {...props} disabled={disabled} className={`carType ${color} ${modifier}`}>
        {isSvg && <div className={`svg-ownership`}>{svg}</div>}
        <div style={{ color: `${fontColor}` }}>{children}</div>
      </button>
    </>
  );
};
