import React from 'react';

export const AdminPanelActiveSvg = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_31421_45031)">
        <path
          d="M3.5 13H11.5V3H3.5V13ZM3.5 21H11.5V15H3.5V21ZM13.5 21H21.5V11H13.5V21ZM13.5 3V9H21.5V3H13.5Z"
          fill="#F4753A"
        />
      </g>
      <defs>
        <clipPath id="clip0_31421_45031">
          <rect width="24" height="24" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
