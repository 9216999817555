import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../../../../../../components/PageWrapper';
import { SkeletonDocuments } from '../../../../../../components/skeletons/SkeletonDocuments';
import { Link } from 'react-router-dom';
import { FrameScroll } from '../../../../../../components/FrameScroll';
import { useMediaQuery } from 'react-responsive';
import { fetchGet } from '../../../../../../common/proxy-1C-fetch-auth';
import { formPrice } from '../../../../../../common/form-price.helper';
import { EmptyList } from '../../../../../../components/EmptyList';
import { DownloadSvg } from '../../../../../../components/UI/svg-icon/DownloadSvg';
import { IDocumentList } from '../type';
import { initialStateOfDocumentList } from '../constans';
import ButtonNotFilled from '../../../../../../components/newUI/ButtonNotFilled/ButtonNotFilled';

export const DocumentRegistriesPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const [skeleton, setSkeleton] = useState<boolean>(true);
  const [, setButtonDisabled] = useState<boolean>(true);
  const [documentList, setDocumentList] = useState<IDocumentList[]>(initialStateOfDocumentList);
  const documentDta = [];
  const optionsNomenclature = [];
  const optionsAddress = [];

  const getDocuments = async () => {
    try {
      const response = await fetchGet(`/registries/${window.location.href.split('/').reverse()[0]}`, {
        category: 'registry',
      });

      for (const data of response.response.flights) {
        optionsNomenclature.push({
          value: data?.nomenclature?.nameByDocument,
          label: data?.nomenclature?.nameByDocument,
        });

        optionsAddress.push({
          value: data?.arrival?.address,
          label: data?.arrival?.address,
        });
        documentDta.push({
          id: data.id,
          item: {
            'Дата документа': {
              text: `${data?.dateTn.toLocaleString().split('T')[0].split('-').reverse().join('.')} 
                  (${data?.dateTn.toLocaleString().split('T')[1].slice(0, 2)}:
                      ${data?.dateTn.toLocaleString().split('T')[1].slice(3, 5)})`,
            },
            'Пункт отправления': {
              text: !!data?.quarry?.address?.address ? data?.quarry?.address?.address : 'Нет данных',
            },
            Заказчик: {
              text: !!data?.client?.title ? data?.client?.title : 'Нет данных',
            },
            'Пункт назначения': {
              text: !!data?.object?.address?.address ? data?.object?.address?.address : 'Нет данных',
            },
            Номенклатура: {
              text: !!data?.supplierSpecification?.nomenclatureSpecification?.nomenclature?.nameByDocument
                ? data?.supplierSpecification?.nomenclatureSpecification?.nomenclature?.nameByDocument
                : 'Нет данных',
            },
            '№ТТН/ТН': {
              text: !!data?.numberTn ? data?.numberTn : 'Нет данных',
            },
            '№ТС': {
              text: !!data?.car?.stateNumber ? `${data?.car?.carBrand?.brand} ${data?.car?.stateNumber}` : 'Нет данных',
            },
            Водитель: {
              text: data?.driver?.fullName ? data?.driver?.fullName : 'Нет данных',
            },
            'Вес/Объем': {
              text: !!data?.unloadingVolume ? data?.unloadingVolume : 'Нет данных',
            },
            'Ед. изм.': {
              text: !!data?.unitUnloading ? data?.unitUnloading : 'Нет данных',
            },
            'Цена перевозки': {
              text: !!data?.carrierPrice ? formPrice(Number(data?.carrierPrice) || 0, 'RUB') : 'Нет данных',
            },
            Сумма: {
              text:
                !!data?.carrierPrice && !!data?.unloadingVolume
                  ? formPrice(Number((data?.carrierPrice * data?.unloadingVolume).toFixed(2)) || 0, 'RUB')
                  : 'Нет данных',
            },
          },
          active: false,
        });
      }

      setDocumentList(documentDta);

      setTimeout(() => {
        setSkeleton(false);
      }, 800);
    } catch (e) {
      setTimeout(() => {
        setSkeleton(false);
      }, 800);
      console.log(e);
    }
  };

  const pickCheck = (id: number) => {
    const newDocumentList = [];

    documentList.forEach(item => {
      if (item.id === id) {
        item.active = !item.active;
      }

      newDocumentList.push(item);
    });

    setDocumentList(newDocumentList);
  };

  useEffect(() => {
    getDocuments().catch(console.log);
  }, []);

  useEffect(() => {
    setButtonDisabled(!documentList.some(({ active }) => active));
  }, [pickCheck]);

  const downloadedFile = async (id: number) => {
    try {
      const { response } = await fetchGet(`/reports/registry`, {
        registryId: id,
      });
      const link = document.createElement('a');
      link.download = response.name;

      link.href = response.file;

      link.click();

      return;
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {skeleton ? (
        <PageWrapper>
          <SkeletonDocuments />
        </PageWrapper>
      ) : (
        <PageWrapper>
          <div className={'document-registries-page'}>
            <Link
              className={'button-back'}
              to={`/document/registry/${Number(window.location.href.split('/').reverse()[0])}`}
            >
              <div className="button-back__svg">
                <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              Назад
            </Link>
            <div className={'document-registries-page__selects'}>
              <ButtonNotFilled
                color={'var(--color-orange400)'}
                svg={<DownloadSvg />}
                onClick={() => downloadedFile(Number(window.location.href.split('/').reverse()[0]))}
                text="Скачать реестр"
                width={'100%'}
              />
            </div>
            {documentList.length ? (
              <FrameScroll
                items={documentList}
                gridTemplateColumnsLeft={isMobile ? '1fr' : '150px 170px'}
                gridTemplateColumnsRight={
                  isMobile ? '1fr' : '128px 128px 149px 128px 128px 149px 128px 128px 149px 128px'
                }
                paddingLeft={'330px'}
                asideElement={null}
                slice={2}
              />
            ) : (
              <EmptyList title={'Реестры не найдено'} subTitle={'В данном разделе пока нет Реестров'} />
            )}
          </div>
        </PageWrapper>
      )}
    </>
  );
};
