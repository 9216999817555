import React from 'react';

export const SuccessGreenSvg = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.0002 3.33203C10.8335 3.33203 3.3335 10.832 3.3335 19.9987C3.3335 29.1654 10.8335 36.6654 20.0002 36.6654C29.1668 36.6654 36.6668 29.1654 36.6668 19.9987C36.6668 10.832 29.1668 3.33203 20.0002 3.33203ZM16.6668 28.332L8.3335 19.9987L10.6835 17.6487L16.6668 23.6154L29.3168 10.9654L31.6668 13.332L16.6668 28.332Z"
        fill="var(--color-green600)"
      />
    </svg>
  );
};
