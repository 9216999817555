import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { closePopUp } from '../common/open-close-popup.function';
import ButtonFilled from './newUI/ButtonFilled/ButtonFilled';
import ButtonNotFilled from './newUI/ButtonNotFilled/ButtonNotFilled';

export const DeletePopUp = ({
  title,
  deleted,
  svg,
  popUpActive,
  setPopUpActive,
  name,
  numberTC,
  sendData,
  closeWindow,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <div className={popUpActive ? 'deletePopUp active' : 'deletePopUp'}>
      <div className="deletePopUp__white"></div>
      {isMobile && <div className="deletePopUp__line"></div>}
      <div className="deletePopUp__wrapper">
        <div className="deletePopUp__svg">{svg}</div>
        <div className="deletePopUp__title">{deleted ? title[1] : title[0]}</div>
        {!deleted && (
          <div className="deletePopUp__subTitle">
            Вы действительно хотите удалить &nbsp;<span>{name}</span>, номер&nbsp;
            <span>{numberTC}</span>?
          </div>
        )}
        <div className={`deletePopUp__buttons ${deleted && 'deletePopUp__buttons_delete'}`}>
          <ButtonNotFilled
            disabled={false}
            svg={''}
            onClick={() => setPopUpActive(closePopUp)}
            text={'Отменить'}
            width={'100%'}
          />
          <ButtonFilled
            onClick={async () => {
              await sendData(false);
              closeWindow();
            }}
            text={'Удалить'}
            width={'100%'}
            svg={null}
            disabled={false}
          />
        </div>
      </div>
    </div>
  );
};
