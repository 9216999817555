// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicHeader_header__Lz6ee {\n  display: flex;\n  gap: 9px;\n  padding: 24px 0;\n  color: var(--color-gray700);\n  font-weight: 300;\n  width: 100%;\n  background: white;\n}\n\n.PublicHeader_logoContainer__Flz-I {\n  display: flex;\n  align-items: center;\n  gap: 14px;\n  margin-left: 16px;\n  cursor: pointer;\n}\n\n.PublicHeader_textLogo__wDhyX {\n  color: var(--color-gray900);\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 20px;\n}\n\n@media (max-width: 768px) {\n  .PublicHeader_logoContainer__Flz-I {\n    gap: 8px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/PublicHeader/PublicHeader.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;EACA,eAAA;EACA,2BAAA;EACA,gBAAA;EACA,WAAA;EACA,iBAAA;AACF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,iBAAA;EACA,eAAA;AAEF;;AAAA;EACE,2BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAGF;;AADA;EACE;IACE,QAAA;EAIF;AACF","sourcesContent":[".header {\n  display: flex;\n  gap: 9px;\n  padding: 24px 0;\n  color: var(--color-gray700);\n  font-weight: 300;\n  width: 100%;\n  background: white;\n}\n.logoContainer {\n  display: flex;\n  align-items: center;\n  gap: 14px;\n  margin-left: 16px;\n  cursor: pointer;\n}\n.textLogo {\n  color: var(--color-gray900);\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 20px;\n}\n@media (max-width: 768px) {\n  .logoContainer {\n    gap: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "PublicHeader_header__Lz6ee",
	"logoContainer": "PublicHeader_logoContainer__Flz-I",
	"textLogo": "PublicHeader_textLogo__wDhyX"
};
export default ___CSS_LOADER_EXPORT___;
