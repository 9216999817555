import React from 'react';
import { WarningSvg } from './UI/svg-icon/WarningSvg';
import { CloseCircleRedSvg } from './UI/svg-icon/CloseCircleRedSvg';
import { SuccessGreenSvg } from './UI/svg-icon/SuccessGreenSvg';
import { closePopUp } from '../common/open-close-popup.function';
import ButtonNotFilled from './newUI/ButtonNotFilled/ButtonNotFilled';
import ButtonFilled from './newUI/ButtonFilled/ButtonFilled';

export const NotificationPopup = ({
  popUpActive,
  content,
  setPopUpActive,
  send,
  title,
  subTitle,
  titleError,
  subTitleError,
  titleSuccess,
  subTitleSuccess,
  textButtonSend,
  textButtonClose,
}) => {
  const contents = {
    send: (
      <div className={'notification-popup-content'}>
        <div className={'notification-popup-content__svg'}>
          <WarningSvg />
        </div>
        <div className={'notification-popup-content__title textH5 textH5_medium'}>{title}</div>
        <div className={'notification-popup-content__sub-title textH6'}>{subTitle}</div>
        <div className={'notification-popup-content__buttons'}>
          <div className={'notification-popup-content__button'}>
            <ButtonFilled onClick={send} svg={null} disabled={false} text={textButtonSend} width="100%" />
          </div>
          <div className={'notification-popup-content__button'}>
            <ButtonNotFilled
              disabled={false}
              svg={''}
              onClick={() => setPopUpActive(false)}
              text={textButtonClose}
              width={'100%'}
            />
          </div>
        </div>
      </div>
    ),
    error: (
      <div className={'notification-popup-content'}>
        <div className={'notification-popup-content__svg'}>
          <CloseCircleRedSvg />
        </div>
        <div className={'notification-popup-content__title textH5 textH5_medium'}>{titleError}</div>
        <div className={'notification-popup-content__sub-title textH6'}>{subTitleError}</div>
        <div className={'notification-popup-content__buttons'}>
          <div className={'notification-popup-content__button'}>
            <ButtonFilled onClick={send} svg={null} disabled={false} text={'Повторить'} width="100%" />
          </div>
        </div>
      </div>
    ),
    success: (
      <div className={'notification-popup-content'}>
        <div className={'notification-popup-content__svg'}>
          <SuccessGreenSvg />
        </div>
        <div className={'notification-popup-content__title textH5 textH5_medium'}>{titleSuccess}</div>
        <div className={'notification-popup-content__sub-title textH6'}>{subTitleSuccess}</div>
        <div className={'notification-popup-content__buttons'}>
          <div className={'notification-popup-content__button'}>
            <ButtonFilled
              svg={null}
              disabled={false}
              onClick={() => setPopUpActive(closePopUp)}
              text={'Хорошо'}
              width="100%"
            />
          </div>
        </div>
      </div>
    ),
  };

  return (
    <div className={`notification-popup ${popUpActive ? 'active' : ''}`}>
      <div className={'notification-popup__black'} onClick={() => setPopUpActive(closePopUp)}></div>
      {contents[content]}
    </div>
  );
};
