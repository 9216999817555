import React from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom';
import { LayoutDriver } from '../layouts/LayoutDriver';
import { FlightsPage } from '../Pages/FlightPages/FlightsPage';
import { FlightPage } from '../Pages/FlightPages/FlightPage';
import { ProfilePage } from '../Pages/Profile/ProfilePage';
import { HelpPage } from '../Pages/HelpPage/HelpPage';
import { LayoutCarrier } from '../layouts/LayoutCarrier';
import { DriversLicensePage } from '../Pages/DriverLicensePages/DriversLicensePage';
import { CarsPage } from '../Pages/CarsPage/CarsPage';
import { ProfileCarrierPage } from '../Pages/Profile/ProfileCarrierPage';
import { CarrierFlightsPage } from '../Pages/CarrierFlightPages/CarrierFlightsPage';
import { CarrierFlightPage } from '../Pages/CarrierFlightPages/CarrierFlightPage';
import { DriverLicensePage } from '../Pages/DriverLicensePages/DriverLicensePage';
import { CarPage } from '../Pages/CarPage/CarPage';
import { DispatchingPage } from '../Pages/DispatchingPages/DispatchingPage';
import { RegistryDocumentId } from '../Pages/DocumentsPages/Components/Registry/Components/RegistryDocumentId/RegistryDocumentId';
import { DispatchingItemPage } from '../Pages/DispatchingPages/DispatchingItemPage';
import { LayoutBuyer } from '../layouts/LayoutBuyer';
import { ProfileBuyerPage } from '../Pages/Profile/ProfileBuyerPage';
import { OrdersPage } from '../Pages/OrdersPages/OrdersPage';
import { ObjectsPage } from '../Pages/ObjectsPage/ObjectsPage';
import { ObjectPage } from '../Pages/ObjectPage/ObjectPage';
import { LayoutSupplier } from '../layouts/LayoutSupplier';
import { SupplierQuarriesPage } from '../Pages/SupplierQuarriesPages/SupplierQuarriesPage';
import { SupplierQuarryPage } from '../Pages/SupplierQuarriesPages/SupplierQuarryPage';
import { OrderPage } from '../Pages/OrdersPages/OrderPage';
import { CreationOfRegistry } from '../Pages/DocumentsPages/Components/Registry/Components/CreationOfRegistry/CreationOfRegistry';
import { DocumentRegistriesPage } from '../Pages/DocumentsPages/Components/Registry/Components/ShowRegistryDocument/DocumentRegistriesPage';
import { ShipmentsPage } from '../Pages/ShipmentsPage/ShipmentsPage';
import { SpecificationPage } from '../Pages/SpecificationPage/SpecificationPage';
import { ProfileSupplierPage } from '../Pages/Profile/ProfileSupplierPage';
import DocumentRegistriesEditPage from '../Pages/DocumentsPages/Components/Registry/Components/EditRegistryDocument/DocumentRegistriesEditPage';
import SupplierUpdAndRegistryPageId from '../Pages/SupplierUpdAndRegistryPage/SupplierUpdAndRegistryPageId';
import BuyerUpdAndRegistryPageId from '../Pages/UpdAndRegistryPage/BuyerUpdAndRegistryPageId';
import MainPageDriver from '../Pages/MainPages/MainPageDriver/MainPageDriver';
import SalaryHistoryPage from '../Pages/MainPages/MainPageDriver/Components/SalaryHistory/SalaryHistoryPage';
import EmployeesPage from '../Pages/EmployeesPage/EmployeesPage';
import PaymentPage from '../Pages/PaymentProcessPage/PaymentPage';
import DocumentsPage from '../Pages/DocumentsPages/DocumentsPage';
import { ShowcaseCreatePage } from 'src/Pages/ShowcasesPage/ShowcaseCreatePage/ShowcaseCreatePage';
import { ShowcasePage } from 'src/Pages/ShowcasesPage/ShowcasePage/ShowcasePage';
import ShowcasesPage from '../Pages/ShowcasesPage/ShowcasesPage';
import EditAndAddMaterialPage from '../Pages/ShowcasesPage/EditAndAddMaterialPage/EditAndAddMaterialPage';
import IndividualMaterialPage from '../Pages/ShowcasesPage/IndividualMaterialPage/IndividualMaterialPage';
import { Catalog } from 'src/Pages/Catalog/Catalog';
import { FavoritesPage } from 'src/Pages/Catalog/FavoritesPage/FavoritesPage';
import SelectedMaterialPage from '../Pages/Catalog/SelectedMaterialPage/SelectedMaterialPage';
import { CatalogMaterialsPage } from 'src/Pages/Catalog/CatalogMaterialCategories/CatalogMaterialsPage/CatalogMaterialsPage';
import { ComparsionsPage } from 'src/Pages/Catalog/ComparsionsPage/ComparsionsPage';
import OrderMaterialPage from 'src/Pages/Catalog/OrderMaterialPage/OrderMaterialPage';
import { GSMNewPage } from 'src/Pages/GSMNewPage/GSMNewPage';
import GSMNewPageCarrier from '../Pages/GSMNewPageCarrier/GSMNewPageCarrier';
import { GSMNewCardPage } from 'src/Pages/GSMNewPageCarrier/GSMNewCardPage/GSMNewCardPage';
import { ReplenishmentsPage } from 'src/Pages/GSMNewPageCarrier/ReplenishmentsPage/ReplenishmentsPage';
import FormReplenishmentsPage from '../Pages/GSMNewPageCarrier/FormReplenishmentsPage/FormReplenishmentsPage';
import FormReplenishmentsCardPage from 'src/Pages/GSMNewPageCarrier/FormReplenishmentsCardPage/FormReplenishmentsCardPage';
import OperationHistory from '../Pages/PaymentProcessPage/OperationHistoryPage/OperationHistory';
import { LayoutAdmin } from 'src/layouts/LayoutAdmin';
import { AdminPage } from 'src/Pages/AdminPage/AdminPage';

export type RoutsState = {
  [state: string]: any;
};

export const switchRoutes = user => {
  const type = user?.type;
  const companyType = user?.type === 'root' ? 'admin' : user?.companyType;
  const createIndexRout = (firstPage, secondPage) => {
    if (user?.isFirstLogin) {
      return 'profile';
    }
    if (type === 'accountant') {
      return firstPage;
    } else return secondPage;
  };

  const routes = {
    driver: (
      <Route key="driver" path="/" element={<LayoutDriver />}>
        <Route index element={<Navigate to={user?.isFirstLogin ? 'profile' : 'main'} replace />} />
        <Route path="flights" element={<FlightsPage />} />
        <Route path="flights/:id" element={<FlightPage />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="main" element={<MainPageDriver />} />
        <Route path="salaryHistory" element={<SalaryHistoryPage />} />
        {/*<Route path="gsm" element={<GSMNewPage />} />*/}
        <Route path="help" element={<HelpPage />} />
      </Route>
    ),
    carrier: (
      <Route key="carrier" path="/" element={<LayoutCarrier />}>
        <Route index element={<Navigate to={createIndexRout('paymentProcess', 'flights')} replace />} />
        <Route path="flights" element={<CarrierFlightsPage />} />
        <Route path="flights/:id" element={<CarrierFlightPage />} />
        <Route path="dispatching" element={<DispatchingPage />} />
        <Route path="employees" element={<EmployeesPage />} />
        <Route path="dispatching/:id" element={<DispatchingItemPage />} />
        <Route path="driverLicense" element={<DriversLicensePage />} />
        <Route path="driverLicense/:id" element={<DriverLicensePage />} />
        <Route path="cars" element={<CarsPage />} />
        <Route path="cars/:id" element={<CarPage />} />
        <Route path="paymentProcess" element={<PaymentPage />} />
        <Route path="paymentProcess/operationHistory" element={<OperationHistory />} />
        <Route path="document" element={<DocumentsPage />} />
        <Route path="document/registry/registries" element={<CreationOfRegistry />} />
        <Route path="document/registry/registryDocument/:id" element={<DocumentRegistriesPage />} />
        <Route path="document/registry/registryDocument/edit/:id" element={<DocumentRegistriesEditPage />} />
        <Route path="document/registry/:id" element={<RegistryDocumentId />} />
        <Route path="document/specifications/:id" element={<SpecificationPage supplier={false} />} />
        {/*<Route path="gsm" element={<GSMNewPageCarrier />} />
        <Route path="gsm/:id" element={<GSMNewCardPage />} />
        <Route path="gsm/:id/replenishments" element={<FormReplenishmentsCardPage />} />
        <Route path="gsm/replenishments" element={<ReplenishmentsPage />} />
        <Route path="gsm/replenishments/form" element={<FormReplenishmentsPage />} />*/}
        <Route path="profile" element={<ProfileCarrierPage />} />
        <Route path="help" element={<HelpPage />} />
      </Route>
    ),
    buyer: (
      <Route key="buyer" path="/" element={<LayoutBuyer />}>
        <Route index element={<Navigate to={createIndexRout('paymentProcess', 'orders#Открытые')} replace />} />
        <Route path="catalog" element={<Catalog />} />
        <Route path="catalog/favorites" element={<FavoritesPage />} />
        <Route path="catalog/comparisons" element={<ComparsionsPage />} />
        <Route path="catalog/materials/:material" element={<CatalogMaterialsPage />} />
        <Route path="catalog/materials/show/:id" element={<SelectedMaterialPage />} />
        <Route path="catalog/materials/order/:id" element={<OrderMaterialPage />} />
        <Route path="catalog/showcases/:id" element={<ShowcasePage />} />
        <Route path="orders" element={<OrdersPage />} />
        <Route path="employees" element={<EmployeesPage />} />
        <Route path="orders/:id" element={<OrderPage />} />
        <Route path="profile" element={<ProfileBuyerPage />} />
        <Route path="objects" element={<ObjectsPage />} />
        <Route path="objects/:id" element={<ObjectPage />} />
        <Route path="paymentProcess" element={<PaymentPage />} />
        <Route path="document" element={<DocumentsPage />} />
        <Route path="document/specifications/:id" element={<SpecificationPage supplier={false} />} />
        <Route path="document/UpdAndRegistry/:id" element={<BuyerUpdAndRegistryPageId />} />
        <Route path="help" element={<HelpPage />} />
      </Route>
    ),

    supplier: (
      <Route key="supplier" path="/" element={<LayoutSupplier />}>
        <Route index element={<Navigate to={createIndexRout('profile', 'showcases')} replace />} />
        <Route path="showcases" element={<ShowcasesPage />} />
        <Route path="showcases/:id" element={<ShowcasePage />} />
        <Route path="showcases/create/" element={<ShowcaseCreatePage />} />
        <Route path="showcases/materials/:idShowcase" element={<EditAndAddMaterialPage />} />
        <Route
          path="showcases/materials/edit/:material/:idMaterial/:idShowcase"
          element={<EditAndAddMaterialPage edit={true} />}
        />
        <Route
          path="showcases/materials/:material/:idShowcase"
          element={<EditAndAddMaterialPage routeFromIndividualMaterialPage={true} />}
        />
        <Route path="showcases/create/materials/:idShowcase" element={<EditAndAddMaterialPage create={true} />} />
        <Route path="showcases/materials/:material/:idMaterial/:idShowcase" element={<IndividualMaterialPage />} />
        <Route path="profile" element={<ProfileSupplierPage />} />
        <Route path="employees" element={<EmployeesPage />} />
        <Route path="quarries" element={<SupplierQuarriesPage />} />
        <Route path="quarries/:id" element={<SupplierQuarryPage />} />
        <Route path="shipments" element={<ShipmentsPage />} />
        <Route path="document/specifications/:id" element={<SpecificationPage supplier={true} />} />
        <Route path="help" element={<HelpPage />} />
        <Route path="document" element={<DocumentsPage />} />
        <Route path="document/UpdAndRegistry/:id" element={<SupplierUpdAndRegistryPageId />} />
        <Route path="supplierPayment" element={<PaymentPage />} />
      </Route>
    ),
    admin: (
      <Route key="admin" path="/" element={<LayoutAdmin />}>
        <Route index element={<Navigate to={'panel'} replace />} />
        <Route path="panel" element={<AdminPage />} />
        <Route path="help" element={<HelpPage />} />
      </Route>
    ),
  };
  return routes[type === 'driver' ? 'driver' : companyType];
};
