import { User } from './user/user.type';
import React from 'react';
import { fetchGet } from './proxy-1C-fetch-auth';
import { UserTypes } from './user/user-types.type';
export type StatusTabsRole = {
  // eslint-disable-next-line no-unused-vars
  [key in UserTypes]: {
    name: string;
    description: string;
    active: boolean;
    disabled: boolean;
    development: boolean;
  };
};
export const tabsRole: StatusTabsRole = {
  carrier: {
    name: 'Перевозчик',
    description: 'Распределение рейсов, добавление водителей, машин, топливных карт',
    active: false,
    disabled: false,
    development: true,
  },
  driver: {
    name: 'Водитель',
    description: 'Исполнение и контроль рейсов, загрузка и просмотр транспортных накладных',
    active: false,
    disabled: false,
    development: true,
  },
  buyer: {
    name: 'Покупатель',
    description: 'Покупка, оформление и отслеживание заказов в режиме реального времени',
    active: false,
    disabled: false,
    development: true,
  },
  supplier: {
    name: 'Поставщик',
    description: 'Отслеживание погрузочных накладных, взаиморасчеты, реестры материала',
    active: false,
    disabled: false,
    development: true,
  },
  accountant: {
    name: 'Поставщик',
    description: 'Отслеживание погрузочных накладных, взаиморасчеты, реестры материала',
    active: false,
    disabled: false,
    development: true,
  },
  manager: {
    name: 'Поставщик',
    description: 'Отслеживание погрузочных накладных, взаиморасчеты, реестры материала',
    active: false,
    disabled: false,
    development: true,
  },
  admin: {
    name: 'Поставщик',
    description: 'Отслеживание погрузочных накладных, взаиморасчеты, реестры материала',
    active: false,
    disabled: false,
    development: true,
  },
};
const fetchLogout = async () => {
  try {
    await fetchGet('/auth/logout', {});
  } catch (e) {
    console.log(e);
  }
};
export const logout = async (user: User, setUser: React.Dispatch<User>) => {
  setUser({
    ...user,
    id: null,
    companyId: null,
    type: '',
    phone: '',
    isInn: false,
    isLogin: false,
    isChangeInput: false,
    isPhone: false,
    apiKey: null,
    active: false,
    activeProfile: false,
    error: false,
    synchronize: false,
    preLoaded: false,
    root: false,
  });

  for (const [, tab] of Object.entries(tabsRole)) {
    tab.active = false;
  }

  await fetchLogout();
  localStorage.clear();
};
