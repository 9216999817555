export const styleStatusProfile = {
  Отклонен: { color: 'var(--color-red600)', borderColor: 'var(--color-red600)', background: 'var(--color-red50)' },
  'Первичная регистрация': {
    color: 'var(--color-orange400)',
    borderColor: 'var(--color-orange400)',
    background: 'var(--color-orange10)',
  },
  Проверен: {
    color: 'var(--color-green600)',
    borderColor: 'var(--color-green600)',
    background: 'var(--color-green50)',
  },
};
