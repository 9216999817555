import React from 'react';
import styles from './MaterialCatalog.module.scss';
import { useMediaQuery } from 'react-responsive';
import { CirclePlusSvg } from 'src/components/UI/svg-icon/CirclePlusSvg';
import { useLocation, useNavigate } from 'react-router-dom';
import { IMaterials } from '../../type';
import { MaterialItem } from './MaterialItem/MaterialItem';
import { convertValueFromNameMaterial } from '../../constans';
import { ButtonSimple } from 'src/components/newUI/ButtonSimple/ButtonSimple';
interface IProps {
  materials: IMaterials[];
  quarry?:
    | string
    | {
        value: string;
        label: string;
      };
}
export const MaterialCatalog = ({ materials, quarry = '' }: IProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const isPublicPath = location.pathname.startsWith('/store');
  const isCatalogPath = location.pathname.startsWith('/catalog');
  const isMobile = useMediaQuery({ maxWidth: 768 });
  let width = isMobile ? '24px' : '16px';

  return (
    <div className={styles.container}>
      <div className={styles.containerTitle}>
        <div className={styles.containerTitleText}>Каталог материалов</div>
        {!isCatalogPath && !isPublicPath && (
          <ButtonSimple
            isText={!isMobile}
            text={'Добавить материал'}
            svg={<CirclePlusSvg width={width} />}
            onClick={() => navigate(`/showcases/materials/${pathname.split('/')[pathname.split('/').length - 1]}`)}
          />
        )}
      </div>
      <div className={styles.cardsContainer + ' ' + (isCatalogPath ? styles.cardsContainerCatalog : '')}>
        {materials?.map(material => {
          const link = isPublicPath
            ? `/store/product/${material?.materialsId?.[0]}`
            : `/${isCatalogPath ? 'catalog' : 'showcases'}` +
              `/materials` +
              `/${isCatalogPath ? 'show' : convertValueFromNameMaterial[material?.breed]}` +
              `/${material?.id}${isCatalogPath || isPublicPath ? '' : '/' + material?.idShowCase}`;
          return (
            <div key={material?.id}>
              <MaterialItem material={material} quarry={quarry} link={link} sellUnitProps={'тн'} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
