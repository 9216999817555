import React from 'react';

const NextCarouselSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="17" viewBox="0 0 8 17" fill="none">
      <path
        d="M1 1.5L7 8.5L1 15.5"
        stroke="var(--color-blue900)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NextCarouselSvg;
