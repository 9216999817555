// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QuarryLink_infoTextValueMobile__qYXe5 {\n  color: var(--color-gray900);\n  font-size: 14px;\n  line-height: 20px;\n}\n\n.QuarryLink_quarryLink__q0iaK {\n  display: flex;\n  flex-direction: row;\n  align-items: stretch;\n  gap: 10px;\n  cursor: pointer;\n}\n\n.QuarryLink_quarryLink__q0iaK * {\n  color: var(--color-orange400) !important;\n  font-weight: 700;\n  text-wrap: nowrap;\n}\n\n.QuarryLink_quarryLink__q0iaK *:hover {\n  color: var(--color-orange200) !important;\n}\n\n.QuarryLink_quarryLink__q0iaK *:active {\n  color: var(--color-orange300) !important;\n}", "",{"version":3,"sources":["webpack://./src/Pages/Catalog/components/QuarryLink/QuarryLink.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,eAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,SAAA;EACA,eAAA;AACF;;AAEA;EACE,wCAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,wCAAA;AACF;;AAEA;EACE,wCAAA;AACF","sourcesContent":[".infoTextValueMobile {\n  color: var(--color-gray900);\n  font-size: 14px;\n  line-height: 20px;\n}\n\n.quarryLink {\n  display: flex;\n  flex-direction: row;\n  align-items: stretch;\n  gap: 10px;\n  cursor: pointer;\n}\n\n.quarryLink * {\n  color: var(--color-orange400) !important;\n  font-weight: 700;\n  text-wrap: nowrap;\n}\n\n.quarryLink *:hover {\n  color: var(--color-orange200) !important;\n}\n\n.quarryLink *:active {\n  color: var(--color-orange300) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoTextValueMobile": "QuarryLink_infoTextValueMobile__qYXe5",
	"quarryLink": "QuarryLink_quarryLink__q0iaK"
};
export default ___CSS_LOADER_EXPORT___;
