import React, { useRef } from 'react';
// @ts-ignore
import styles from './NewAsideNavSupplier.module.css';
import NewNavItem from '../newUI/NewNavItem/NewNavItem';
import { HelpSvg } from '../UI/svg-icon/HelpSvg';
import { HelpSvgActive } from '../UI/svg-icon/HelpSvgActive';
import { AdminPanelSvg } from '../UI/svg-icon/AdminPanelSvg';
import { AdminPanelActiveSvg } from '../UI/svg-icon/AdminPanelActiveSvg';

const NewAsideNavAdmin = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const adminNavLink = [
    {
      name: 'Панель администратора',
      ref: ref1,
      link: '/panel',
      type: ['root'],
      notActiveProfile: false,
      svg: <AdminPanelSvg />,
      svgActive: <AdminPanelActiveSvg />,
      errorProfile: false,
    },
    {
      name: 'Помощь',
      link: '/help',
      ref: ref2,
      type: ['root'],
      notActiveProfile: false,
      disable: true,
      svg: <HelpSvg />,
      svgActive: <HelpSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },
  ];
  return (
    <div className={styles.AsideBox} style={{ zIndex: '8000', paddingBottom: '20px' }}>
      {adminNavLink.map(
        ({ name, type, ref, link, notActiveProfile, svg, svgActive, svgProfileError, errorProfile }) => {
          return (
            <div key={name} ref={ref}>
              <NewNavItem
                ref={ref}
                disabled={null}
                typeAccess={type}
                key={name}
                name={name}
                link={link}
                notActiveProfile={notActiveProfile}
                svg={svg}
                svgActive={svgActive}
                svgProfileError={svgProfileError}
                errorProfile={errorProfile}
              />
            </div>
          );
        },
      )}
    </div>
  );
};

export default NewAsideNavAdmin;
