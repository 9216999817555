// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdminPage_container__i3epi {\n  display: flex;\n  flex-direction: column;\n  gap: 40px;\n}\n\n.AdminPage_titleAndSubtitleContainer__nZu84 {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.AdminPage_inputsAndSelectorsContainer__G\\+omL {\n  display: flex;\n  flex-direction: row;\n  gap: 16px;\n}\n\n.AdminPage_customTableContainer__4otzr {\n  padding: 8px;\n  border-radius: 12px;\n  background: var(--color-blue50);\n}\n\n.AdminPage_row__U0Oy- {\n  border-right: 1px solid var(--color-gray200);\n}\n\n@media (max-width: 768px) {\n  .AdminPage_container__i3epi {\n    gap: 24px;\n  }\n  .AdminPage_titleAndSubtitleContainer__nZu84 {\n    gap: 4px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/AdminPage/AdminPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACF;;AAEA;EACE,YAAA;EACA,mBAAA;EACA,+BAAA;AACF;;AAEA;EACE,4CAAA;AACF;;AAEA;EACE;IACE,SAAA;EACF;EAEA;IACE,QAAA;EAAF;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 40px;\n}\n\n.titleAndSubtitleContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.inputsAndSelectorsContainer {\n  display: flex;\n  flex-direction: row;\n  gap: 16px;\n}\n\n.customTableContainer {\n  padding: 8px;\n  border-radius: 12px;\n  background: var(--color-blue50);\n}\n\n.row {\n  border-right: 1px solid var(--color-gray200);\n}\n\n@media (max-width: 768px) {\n  .container {\n    gap: 24px;\n  }\n\n  .titleAndSubtitleContainer {\n    gap: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AdminPage_container__i3epi",
	"titleAndSubtitleContainer": "AdminPage_titleAndSubtitleContainer__nZu84",
	"inputsAndSelectorsContainer": "AdminPage_inputsAndSelectorsContainer__G+omL",
	"customTableContainer": "AdminPage_customTableContainer__4otzr",
	"row": "AdminPage_row__U0Oy-"
};
export default ___CSS_LOADER_EXPORT___;
