import { AUTHSUBSERVER } from '../sub-server.constant';
import { localStorageWrite } from './local-storage.helper';
export const getMe = async (user, setUser, setRole?) => {
  const requestOptionsGet: any = {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
  };
  const responseGet = await fetch(`${AUTHSUBSERVER}/api/auth/getMe`, requestOptionsGet);
  const data = await responseGet.json();
  if (data.permissions?.AUTH?.[0].toLowerCase()) {
    setUser({ ...user, type: data.permissions?.AUTH[0].toLowerCase() });
    localStorageWrite({ ...user, type: data.permissions?.AUTH[0].toLowerCase() });
    setRole && setRole();
  } else {
    setUser({ ...user, type: data.permissions?.LK?.[0].toLowerCase() });
    localStorageWrite({ ...user, type: data.permissions?.LK?.[0].toLowerCase() });
  }
};
