import styles from './SelectLk.module.scss';

export const generateStyleButtonContainer = (size: string, openSelectWindow: boolean, error: boolean) => {
  const styleButtonSmall = size === 'small' && styles.buttonSmallContainer;
  const styleButtonContainerProcessing = openSelectWindow && styles.buttonContainerProcessing;
  const styleButtonError = error && styles.buttonContainerError;
  return [styles.buttonContainer, styleButtonSmall, styleButtonContainerProcessing, styleButtonError];
};
export const generateStyleButtonText = (size: string, value: string) => {
  const styleButtonSelectedText = value && styles.buttonSelectedText;
  const styleButtonSmallText = size === 'small' && styles.buttonSmallText;
  return [styles.buttonText, styleButtonSelectedText, styleButtonSmallText];
};
