import React from 'react';

const TruckFastOutline = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.3335 7.5H11.0835L11.8335 9H2.0835L1.3335 7.5ZM2.3335 10.5H12.0835L12.8335 12H3.0835L2.3335 10.5ZM18.5835 18.5C19.4135 18.5 20.0835 17.83 20.0835 17C20.0835 16.17 19.4135 15.5 18.5835 15.5C17.7535 15.5 17.0835 16.17 17.0835 17C17.0835 17.83 17.7535 18.5 18.5835 18.5ZM20.0835 9.5H17.5835V12H22.0435L20.0835 9.5ZM8.5835 18.5C9.4135 18.5 10.0835 17.83 10.0835 17C10.0835 16.17 9.4135 15.5 8.5835 15.5C7.7535 15.5 7.0835 16.17 7.0835 17C7.0835 17.83 7.7535 18.5 8.5835 18.5ZM20.5835 8L23.5835 12V17H21.5835C21.5835 18.66 20.2435 20 18.5835 20C16.9235 20 15.5835 18.66 15.5835 17H11.5835C11.5835 18.66 10.2335 20 8.5835 20C6.9235 20 5.5835 18.66 5.5835 17H3.5835V13.5H5.5835V15H6.3435C6.8935 14.39 7.6935 14 8.5835 14C9.4735 14 10.2735 14.39 10.8235 15H15.5835V6H3.5835C3.5835 4.89 4.4735 4 5.5835 4H17.5835V8H20.5835Z"
        fill="var(--color-orange400)"
      />
    </svg>
  );
};

export default TruckFastOutline;
