import React from 'react';

interface IProps {
  fill: boolean;
  margin: string;
  bgError: boolean | string;
  isValue: string | number;
  setIsValue: any;
  placeholder: string;
  maxLength?: number;
  error?: boolean;
  textError?: string;
  type?: string;
  disabled?: boolean;
}
export const InputNumber = ({
  fill,
  margin,
  bgError,
  isValue,
  setIsValue,
  placeholder,
  maxLength = 100,
  error,
  textError,
  type = 'phone',
  disabled = false,
}: IProps) => {
  return (
    <div className={`InputNumber form-item`} style={{ marginBottom: margin }}>
      <input
        type={type}
        maxLength={maxLength}
        className={`InputNumber__input form-input ${disabled ? 'disabled' : ''} ${bgError ? '' : 'error'}`}
        value={isValue == null ? '' : isValue}
        onChange={({ target: { value } }) => setIsValue(value)}
        disabled={disabled}
      />
      <label className={isValue ? 'InputNumber__label form-label valid' : 'InputNumber__label form-label'}>
        {placeholder}
      </label>
      {fill && <div className="InputText__text">Поле обязательное для заполнения</div>}
      {error && (
        <div className="InputText__text" style={{ color: 'var(--color-red600)' }}>
          {textError}
        </div>
      )}
    </div>
  );
};
