import React, { useEffect, useState } from 'react';
import styles from './AddContract.module.scss';
import { Input, Modal } from 'antd';
import FrameModalSvg from '../../../../components/UI/svg-icon/FrameModalSvg';
import ButtonNotFilled from '../../../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import ButtonFilled from '../../../../components/newUI/ButtonFilled/ButtonFilled';
import { fetchGet, fetchPost } from '../../../../common/proxy-1C-fetch-auth';
import { toast } from 'react-toastify';
import SuccessContractModal from '../SuccessContractModal/SuccessContractModal';
import { useMediaQuery } from 'react-responsive';
import { CloseSvg } from '../../../../components/UI/svg-icon/CloseSvg';
import LoadingSpin from 'src/components/newUI/LoadingSpin/LoadingSpin';
import { TypeTitlesOfTheCreatedContract } from '../../type';
import { InitStateTitlesOfTheCreatedContract } from '../../constans';
import { toastError } from 'src/common/toastError.helper';
import { CustomSelect } from '../../../../components/UI/select/CustomSelect';

interface IProps {
  openAddContract: boolean;
  setOpenAddContract: React.Dispatch<React.SetStateAction<boolean>>;
  getData: () => Promise<void>;
}
const AddContract = ({ openAddContract, setOpenAddContract, getData }: IProps) => {
  const [successContractModal, setSuccessContractModal] = useState<boolean>(false);
  const [systemOptions, setSystemOptions] = useState([]);
  const [identifierOptions, setIdentifierOptions] = useState([]);
  const [identifierEdo, setIdentifierEdo] = useState([]);
  const [selectedSystemOptions, setSelectedSystemOptions] = useState<{ value: string; label: string }>();
  const [contractIdentifier, setContractIdentifier] = useState<{ value: string; label: string }>();
  const [titlesOfTheCreatedContract, setTitlesOfTheCreatedContract] = useState<TypeTitlesOfTheCreatedContract>(
    InitStateTitlesOfTheCreatedContract,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const closeAddContract = () => {
    setOpenAddContract(false);
  };
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const getContractIdentifiers = async () => {
    try {
      const response = await fetchGet('/information/edo-identificators', {});
      const responseData = response?.response?.map(identifier => {
        return { value: identifier.provider, label: identifier.provider };
      });
      setSystemOptions(responseData);
      setIdentifierEdo(response?.response);
    } catch (e) {
      console.log(e);
    }
  };
  const addNewContract = async () => {
    setLoading(true);
    try {
      const response = await fetchPost('/information/contracts', 'POST', {
        identificator: contractIdentifier?.value,
      });

      if (toastError(response)) {
        setLoading(false);
        setContractIdentifier(null);
        return;
      }

      toast.success('Данные успешно отправлены');
      closeAddContract();
      setSuccessContractModal(true);
      setContractIdentifier(null);
      setTitlesOfTheCreatedContract(response?.response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  useEffect(() => {
    setContractIdentifier(null);
    let identifierEdoOptions = identifierEdo?.find(element => element?.provider === selectedSystemOptions?.value);
    identifierEdoOptions = identifierEdoOptions?.identificator?.map((option: string) => {
      return { value: option, label: option };
    });
    if (identifierEdoOptions?.length < 2) {
      setContractIdentifier(identifierEdoOptions[0]);
    }
    setIdentifierOptions(identifierEdoOptions);
  }, [selectedSystemOptions]);
  useEffect(() => {
    getContractIdentifiers();
  }, []);
  useEffect(() => {}, [openAddContract]);

  return (
    <>
      {isMobile ? (
        <div className={openAddContract ? 'DriverPagePopup active' : 'DriverPagePopup'} style={{ overflowY: 'auto' }}>
          <div onClick={() => closeAddContract()} className="DriverPagePopup__white"></div>
          <div className="DriverPagePopup__line"></div>
          <div
            className="DriverPagePopup__wrapper"
            style={{
              height: 'fit-content',
              padding: '24px 15px',
              top: 'auto',
              bottom: '0',
              borderRadius: '12px 12px 0px 0px',
              background: `var(--color-blue50)`,
            }}
          >
            <div className={styles.drawerTriangleContainer}>
              <div className={styles.drawerTriangle} />
            </div>
            <div className={styles.container}>
              <div className={styles.topContainer}>
                <div className={styles.titleAndCloseButton}>
                  <h1 className={styles.title}>Добавление договора</h1>
                  <div className={styles.mobileCloseIconDrawer}>
                    <div className={styles.mobileCloseIconBlockDrawer} onClick={closeAddContract}>
                      <CloseSvg />
                    </div>
                  </div>
                </div>
                {loading ? (
                  <LoadingSpin />
                ) : (
                  <div className={styles.contentContainer}>
                    <div className={styles.blockContainer}>
                      <p className={styles.blockTitleText}>Получатель</p>
                      <Input className={styles.inputContainer} value={'Неруд центр'} disabled={true} />
                    </div>
                    <div className={styles.border} />
                    <div className={styles.blockContainer}>
                      <div className={styles.blockTitleContainer}>
                        <p className={styles.blockTitleText}>Тип документа</p>
                      </div>
                      <Input className={styles.inputContainer} value={'Договор'} disabled={true} />
                    </div>
                    <div className={styles.border} />
                    <div className={styles.blockContainer}>
                      <div className={styles.blockTitleContainer}>
                        <p className={styles.blockTitleText}>Система ЭДО</p>
                      </div>
                      <CustomSelect
                        error={false}
                        isDisabled={false}
                        defaultValue={null}
                        setValue={value => setSelectedSystemOptions(value)}
                        options={systemOptions}
                        placeholder={'Выберите систему ЭДО'}
                        noOptionsMessage={'Нет вариантов'}
                        isSearchable={true}
                        isClearable={true}
                      />
                    </div>

                    <div className={styles.border} />
                    <div className={styles.blockContainer}>
                      <div className={styles.blockTitleContainer}>
                        <p className={styles.blockTitleText}>Идентификатор</p>
                      </div>
                      <CustomSelect
                        value={contractIdentifier}
                        error={false}
                        isDisabled={false}
                        defaultValue={null}
                        setValue={value => setContractIdentifier(value)}
                        options={identifierOptions}
                        placeholder={'Выберите значение'}
                        noOptionsMessage={'Нет вариантов'}
                        isSearchable={true}
                        isClearable={true}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.buttonContainer}>
                <ButtonNotFilled
                  disabled={loading}
                  sizeText={'small'}
                  onClick={closeAddContract}
                  text={'Отмена'}
                  width={'100%'}
                />
                <ButtonFilled
                  sizeText={'small'}
                  disabled={loading}
                  onClick={addNewContract}
                  text={'Отправить'}
                  width={'100%'}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Modal
          zIndex={9500}
          width={480}
          footer={null}
          open={openAddContract}
          onCancel={closeAddContract}
          closeIcon={false}
        >
          <div className={styles.container}>
            <div className={styles.topContainer}>
              <h1 className={styles.title}>Добавление договора</h1>
              {loading ? (
                <LoadingSpin />
              ) : (
                <div className={styles.contentContainer}>
                  <div className={styles.blockContainer}>
                    <p className={styles.blockTitleText}>Получатель</p>
                    <Input className={styles.inputContainer} value={'Неруд центр'} disabled={true} />
                  </div>
                  <div className={styles.border} />
                  <div className={styles.blockContainer}>
                    <div className={styles.blockTitleContainer}>
                      <p className={styles.blockTitleText}>Тип документа</p>
                    </div>
                    <Input className={styles.inputContainer} value={'Договор'} disabled={true} />
                  </div>
                  <div className={styles.border} />
                  <div className={styles.blockContainer}>
                    <div className={styles.blockTitleContainer}>
                      <p className={styles.blockTitleText}>Система ЭДО</p>
                    </div>
                    <CustomSelect
                      height40px={true}
                      error={false}
                      isDisabled={false}
                      defaultValue={null}
                      setValue={value => setSelectedSystemOptions(value)}
                      options={systemOptions}
                      placeholder={'Выберите систему ЭДО'}
                      noOptionsMessage={'Нет вариантов'}
                      isSearchable={true}
                      isClearable={true}
                    />
                  </div>

                  <div className={styles.border} />
                  <div className={styles.blockContainer}>
                    <div className={styles.blockTitleContainer}>
                      <p className={styles.blockTitleText}>Идентификатор</p>
                    </div>
                    <CustomSelect
                      value={contractIdentifier}
                      error={false}
                      isDisabled={false}
                      defaultValue={null}
                      setValue={value => setContractIdentifier(value)}
                      options={identifierOptions}
                      placeholder={'Выберите значение'}
                      noOptionsMessage={'Нет вариантов'}
                      isSearchable={true}
                      isClearable={true}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className={styles.buttonContainer}>
              <ButtonNotFilled onClick={closeAddContract} disabled={loading} text={'Отмена'} width={'100%'} />
              <ButtonFilled disabled={loading} onClick={addNewContract} text={'Отправить'} width={'100%'} />
            </div>
          </div>
          <div className={styles.closeButton}>
            <FrameModalSvg onClick={closeAddContract} />
          </div>
        </Modal>
      )}
      <SuccessContractModal
        successContractModal={successContractModal}
        titlesOfTheCreatedContract={titlesOfTheCreatedContract}
        setSuccessContractModal={setSuccessContractModal}
        getData={getData}
      />
    </>
  );
};

export default AddContract;
