import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import styles from './DispatchingPage.module.scss';
import { InputSearch } from '../../components/UI/inputs/InputSearch';
import { optionVolume } from '../../common/select-format.type';
import { SelectCustom } from '../../components/UI/select/SelectCustom';
import { Tabs } from 'antd';
import CustomTable from '../../components/newUI/CustomTable/CustomTable';
import { columnsDispatching } from './Constans/Constans';
import { fetchGet } from '../../common/proxy-1C-fetch-auth';
import { IGetProps } from './types';
import useDebounce from '../../CustomHooks/useDebounce';
import { Filter } from '../../common/filter.type';
import { useMediaQuery } from 'react-responsive';
import MobileColumnsDispatching from './Components/mobileColumnsDispatching/MobileColumnsDispatching';
import TitleMain from '../../components/UI/TitleAndSubtitle/TitleMain';
import SubTitleMain from '../../components/UI/TitleAndSubtitle/SubTitleMain';
import TitleAndSubTitleMain from '../../components/UI/TitleAndSubtitle/TitleAndSubTitleMain';
import ButtonNotFilled from '../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import PrintTnModal from './Components/PrintTNMdal/PrintTNModal';
import { toastError } from '../../common/toastError.helper';
import SelectLk from '../../components/newUI/SelectLk/SelectLk';

export const DispatchingPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [openPrintTN, setOpenPrintTN] = useState<boolean>(false);
  const [countTN, setCountTN] = useState<string>('0');
  const [numberOfScheduledFlights, setNumberOfScheduledFlights] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [tab, setTab] = useState<string>(localStorage.getItem('tabOfOrderDispatching') || 'new');
  const [dataResponse, setDataResponse] = useState<IGetProps>();
  const [filter, setFilter] = useState<Filter>({ search: '', sort: { field: 'сначала новые', order: 'DESC' } });
  const debouncedSearchTerm = useDebounce(filter.search, 500);

  const getData = async () => {
    setLoading(true);
    const requsetOption = {
      status: tab,
      searchString: debouncedSearchTerm,
      sortByDate: filter?.sort?.order,
    };

    try {
      const response = await fetchGet('/dispatching', requsetOption);
      const responseTableData = response?.response?.map(item => {
        return {
          ...item,
          consignee: item?.consignee?.title,
          unloadAndTime: {
            unload: item?.object?.address?.address,
            timeFrom: item?.object?.workSchedulesGraph?.from,
            timeTo: item?.object?.workSchedulesGraph?.to,
            aroundTheClock: item?.object?.workSchedulesGraph?.aroundTheClock,
          },
          distribution: {
            volume: item?.volume,
            unit: item?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.unit,
            distributedVolume: item?.distributedVolume,
            status: item?.status?.title,
            id: item?.id,
            orderNumber: item?.orderNumber,
            rejectComment: item?.rejectComment,
            isRedRow: item?.rejectComment === 'Отклонена',
            content: item?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.nameByDocument,
          },
        };
      });
      await setDataResponse(responseTableData);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  const onChange = (key: string) => {
    setTab(key);
    localStorage.setItem('tabOfOrderDispatching', key);
  };
  useEffect(() => {
    getNumberOfScheduledFlights();
  }, []);
  useEffect(() => {
    getData();
  }, [tab, filter.sort, debouncedSearchTerm]);

  const tabs: {
    key: string;
    label: string;
    children: JSX.Element;
  }[] = [
    {
      key: 'new',
      label: 'Новые',
      children: (
        <>
          {isMobile ? (
            <div>
              <MobileColumnsDispatching loading={loading} dataResponse={dataResponse} getData={getData} />
            </div>
          ) : (
            <div className={styles.blockRegister}>
              <CustomTable
                isRowRedData={'isRedRow'}
                loading={loading}
                emptyTitle={'Новых заявок не найдено'}
                emptyDescription={'По выбранным фильтрам нет заявок'}
                link={true}
                columns={columnsDispatching(getData)}
                data={dataResponse}
              />
            </div>
          )}
        </>
      ),
    },
    {
      key: 'active',
      label: 'Активные',
      children: (
        <>
          {isMobile ? (
            <div>
              <MobileColumnsDispatching loading={loading} dataResponse={dataResponse} getData={getData} />
            </div>
          ) : (
            <div className={styles.blockRegister}>
              <CustomTable
                isRowRedData={'isRedRow'}
                loading={loading}
                emptyTitle={'Активных заявок не найдено'}
                emptyDescription={'По выбранным фильтрам нет заявок'}
                link={true}
                columns={columnsDispatching(getData)}
                data={dataResponse}
              />
            </div>
          )}
        </>
      ),
    },
    {
      key: 'completed',
      label: 'Выполненные',
      children: (
        <>
          {isMobile ? (
            <div>
              <MobileColumnsDispatching loading={loading} dataResponse={dataResponse} getData={getData} />
            </div>
          ) : (
            <div className={styles.blockRegister}>
              <CustomTable
                isRowRedData={'isRedRow'}
                loading={loading}
                emptyTitle={'Выполненных заявок не найдено'}
                emptyDescription={'По выбранным фильтрам нет заявок'}
                link={true}
                columns={columnsDispatching(getData)}
                data={dataResponse}
              />
            </div>
          )}
        </>
      ),
    },
  ];
  const getDownloadTN = async () => {
    try {
      const response = await fetchGet('/bill-lading/template', { amount: Number(countTN) });
      if (toastError(response)) return;
      const link = document.createElement('a');
      link.download = 'ТН';
      link.href = response.response.file;
      link.click();
      return;
    } catch (e) {
      console.log(e);
    }
  };
  const getNumberOfScheduledFlights = async () => {
    try {
      const response = await fetchGet('/flights/active-flights-number', {});
      setNumberOfScheduledFlights(String(response.response));
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <PageWrapper>
      <div className={styles.titleContainer}>
        <TitleAndSubTitleMain
          title={'Диспетчеризация'}
          subTitle={'Список заявок с нераспределенным объемом нерудных материалов'}
        />
        <ButtonNotFilled
          onClick={() => setOpenPrintTN(true)}
          disabled={loading}
          text={'Печать ТН'}
          width={'140px'}
          height={'40px'}
        />
      </div>
      <PrintTnModal
        numberOfScheduledFlights={numberOfScheduledFlights}
        getDownloadTN={getDownloadTN}
        setCountTN={setCountTN}
        countTN={countTN}
        open={openPrintTN}
        setOpen={setOpenPrintTN}
      />
      <div>
        <div className={styles.filterAndTableContainer}>
          <div className={styles.filterContainer}>
            <div className={styles.filterSearch}>
              <InputSearch
                placeholder="Поиск заявок"
                onInput={({ currentTarget: { value: search } }) => setFilter(prevState => ({ ...prevState, search }))}
                value={filter.search}
              />
            </div>
            <div className={styles.filterSelect}>
              <SelectLk
                width={'100%'}
                value={filter.sort.field}
                setValue={value => {
                  setFilter(prevState => ({
                    ...prevState,
                    sort: {
                      field: value,
                      order: value?.label === 'сначала старые' ? 'ASC' : 'DESC',
                    },
                  }));
                }}
                options={optionVolume}
              />
            </div>
          </div>
          <Tabs defaultActiveKey={tab} items={tabs} onChange={onChange} />
        </div>
      </div>
    </PageWrapper>
  );
};
