import styles from './Constans.module.scss';
import DistributionAndSubmitRequest from '../Components/DistributionAndSubmbitRequest/DistributionAndSubmitRequest';
import React from 'react';

export const columnsDispatching = getData => {
  return [
    {
      title: 'Дата выгрузки',
      dataIndex: 'orderDate',
      width: 80,
      key: 'orderDate',
      render: (text: string) => (
        <div className={styles.containerColumn} style={{ marginLeft: '24px' }}>
          <p className={styles.thickTextColumn}>{text?.slice(0, 10).split('-').reverse().join('.')}</p>
          <div
            style={{
              color: 'var(--color-gray200)',
              height: '28px',
              border: '1px solid var(--color-gray200)',
              margin: '0 24px',
            }}
          ></div>
        </div>
      ),
    },
    {
      title: 'Номер заявки',
      dataIndex: 'orderNumber',
      width: 80,
      key: 'orderNumber',
      render: text => (
        <div className={styles.containerColumn}>
          <p className={styles.thickTextColumn}>{text}</p>
          <div
            style={{
              color: 'var(--color-gray200)',
              height: '28px',
              border: '1px solid var(--color-gray200)',
              margin: '0 24px',
            }}
          ></div>
        </div>
      ),
    },
    {
      title: 'Контрагент получателя',
      dataIndex: 'consignee',
      width: 160,
      key: 'consignee',
      render: text => (
        <div className={styles.containerColumn}>
          <p className={styles.mediumTextColumn}>{text}</p>
          <div
            style={{
              color: 'var(--color-gray200)',
              height: '28px',
              border: '1px solid var(--color-gray200)',
              margin: '0 24px',
            }}
          ></div>
        </div>
      ),
    },
    {
      title: 'Загрузка',
      dataIndex: 'quarry',
      width: 160,
      key: 'quarry',
      render: text => (
        <div className={styles.containerColumn}>
          <p className={styles.mediumTextColumn}>{text?.address?.address}</p>
          <div
            style={{
              color: 'var(--color-gray200)',
              height: '28px',
              border: '1px solid var(--color-gray200)',
              margin: '0 24px',
            }}
          ></div>
        </div>
      ),
    },
    {
      title: 'Выгрузка',
      dataIndex: 'unloadAndTime',
      width: 200,
      key: 'unloadAndTime',
      render: text => (
        <div className={styles.containerColumn}>
          <div>
            <p className={styles.mediumTextColumn}>{text?.unload}</p>
            <p className={styles.softTextColumn}>
              {text?.aroundTheClock ? 'круглосуточно' : 'часы работы:' + ' ' + text?.timeFrom + '-' + text?.timeTo}
            </p>
          </div>
          <div
            style={{
              color: 'var(--color-gray200)',
              height: '28px',
              border: '1px solid var(--color-gray200)',
              margin: '0 24px',
            }}
          ></div>
        </div>
      ),
    },
    {
      title: 'Номенклатура',
      dataIndex: 'buyerSpecification',
      width: 200,
      key: 'supplierSpecification',
      render: text => (
        <div className={styles.containerColumn}>
          <p className={styles.mediumTextColumn}>{text?.nomenclatureSpecification?.nomenclature?.nameByDocument}</p>
          <div
            style={{
              color: 'var(--color-gray200)',
              height: '28px',
              border: '1px solid var(--color-gray200)',
              margin: '0 24px',
            }}
          ></div>
        </div>
      ),
    },
    {
      title: 'Распределение',
      dataIndex: 'distribution',
      width: 168,
      key: 'distribution',
      render: text => (
        <div>
          <DistributionAndSubmitRequest getData={getData} data={text} />
        </div>
      ),
    },
  ];
};

export const columnsDispatchingItemTable = [
  {
    title: 'Дата',
    dataIndex: 'flightDate',
    width: 168,
    key: 'flightDate',
    render: (text: string) => (
      <div className={styles.containerColumn}>
        <p className={styles.mediumTextColumn}>{text?.slice(0, 10).split('-').reverse().join('.')}</p>
        <div
          style={{
            color: 'var(--color-gray200)',
            height: '28px',
            border: '1px solid var(--color-gray200)',
            marginLeft: 'auto',
          }}
        ></div>
      </div>
    ),
  },
  {
    title: 'Водитель',
    dataIndex: 'driver',
    width: 420,
    key: 'driver',
    render: text => (
      <div className={styles.containerColumn}>
        <p className={styles.mediumTextColumn}>{text}</p>
        <div
          style={{
            color: 'var(--color-gray200)',
            height: '28px',
            border: '1px solid var(--color-gray200)',
            marginLeft: 'auto',
          }}
        ></div>
      </div>
    ),
  },
  {
    title: 'Назначенное ТС',
    dataIndex: 'car',
    width: 420,
    key: 'car',
    render: text => (
      <div className={styles.containerColumn}>
        <p className={styles.mediumTextColumn}>
          {text?.carModel} {text?.carStateNumber}
        </p>
        <div
          style={{
            color: 'var(--color-gray200)',
            height: '28px',
            border: '1px solid var(--color-gray200)',
            marginLeft: 'auto',
          }}
        ></div>
      </div>
    ),
  },
  {
    title: 'Кол-во рейсов',
    dataIndex: 'flightsNumber',
    width: 128,
    key: 'flightsNumber',
    render: text => (
      <div className={styles.containerColumn}>
        <p className={styles.mediumTextColumn}>{text}</p>
        <div
          style={{
            color: 'var(--color-gray200)',
            height: '28px',
            border: '1px solid var(--color-gray200)',
            marginLeft: 'auto',
          }}
        ></div>
      </div>
    ),
  },
  {
    title: 'Общий объем',
    dataIndex: 'volumeOfMaterial',
    width: 152,
    key: 'volumeOfMaterial',
    render: text => (
      <div className={styles.containerColumn}>
        <div>
          <p className={styles.mediumTextColumn}>
            {formVolume(text?.flightsSumVolume)} {text?.unit}
          </p>
        </div>
      </div>
    ),
  },
];
export const ViewOfPassingVehicle = {
  everyone: 'Проходят все',
  'semitrailer-30-45': 'Пролуприцеп 30 куб.м. – 45 т.',
  'four-axis-30-45': '4х-осные 30 куб.м. – 45 т.',
  'four-axis-20-30': '4х-осные 20 куб.м.',
  'three-axis-10-15': '3х-осные 10 куб.м. – 15 т.',
};

export const formVolume = (volume, locales = 'en') => {
  return new Intl.NumberFormat(locales, { maximumFractionDigits: 2 }).format(volume);
};
