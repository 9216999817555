// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_textStartTour__MlTmV {\n  cursor: pointer;\n  color: var(--color-orange400);\n  text-align: center;\n  font-family: LiberationSans;\n  font-size: 14px;\n  font-style: normal;\n  margin-right: 32px;\n  font-weight: 700;\n  line-height: 20px;\n}", "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,6BAAA;EACA,kBAAA;EACA,2BAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACF","sourcesContent":[".textStartTour {\n  cursor: pointer;\n  color: var(--color-orange400);\n  text-align: center;\n  font-family: LiberationSans;\n  font-size: 14px;\n  font-style: normal;\n  margin-right: 32px;\n  font-weight: 700;\n  line-height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textStartTour": "Header_textStartTour__MlTmV"
};
export default ___CSS_LOADER_EXPORT___;
