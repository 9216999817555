import React, { useEffect, useState } from 'react';
import styles from './UpdAndRegistryPage.module.scss';
import CustomTable from '../../components/newUI/CustomTable/CustomTable';
import CustomRangePicker from '../../components/newUI/CustomRangePicker/CustomRangePicker';
import { fetchGet } from '../../common/proxy-1C-fetch-auth';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { columnsUpd, FiltersInitialState } from './constans';
import { IFilters, IRegistryBuy } from './type';
import { formPrice } from '../../common/form-price.helper';
import { clearObject } from '../../common/ClearObject.helper';
import { InputSearch } from '../../components/UI/inputs/InputSearch';
import FilterBox from '../../components/newUI/FilterBox/FilterBox';
// @ts-ignore
import LoadingGif from '../../assets/img/Loader-Solber.gif';
import ButtonNotFilled from '../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import { EmptyList } from '../../components/EmptyList';

interface IProps {
  tab: string;
}
const BuyerUpdAndRegistryPage = ({ tab }: IProps) => {
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [openCalendarSecond, setOpenCalendarSecond] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<IFilters>(FiltersInitialState);
  const [dataResponse, setDataResponse] = useState<[IRegistryBuy]>();
  const [isFirstRender, setIsFirstRender] = useState<number>(0);

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [more, setMore] = useState(isMobile ? 5 : 10);

  const Spin = () => {
    return (
      <div style={{ marginTop: '40px' }}>
        <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
      </div>
    );
  };
  const getData = async () => {
    setLoading(true);
    let requestData = {
      startDate: filters.dateRange[0] ? new Date(filters.dateRange[0]) : undefined,
      finishDate: filters.dateRange[1] ? new Date(filters.dateRange[1]) : undefined,
      startContractDate: filters.dateRangeSecond[0] ? new Date(filters.dateRangeSecond[0]) : undefined,
      finishContractDate: filters.dateRangeSecond[1] ? new Date(filters.dateRangeSecond[1]) : undefined,
      registryNumber: filters.filterSearch ? filters.filterSearch : undefined,
    };
    let correctRequestData = clearObject(requestData);

    try {
      const response = await fetchGet('/registries', correctRequestData);
      setLoading(false);
      const correctResponse = response.response.map(item => {
        const individualNomenclatureContent = new Set(
          item.flights.map(flight => {
            return flight.buyerSpecification?.nomenclatureSpecification?.nomenclature?.nameByDocument;
          }),
        );
        return {
          date: {
            registryDate: item.registryDate,
            contractDate: item.contract.date,
          },
          company: item.company,
          nomenclatureContentAll: Array.from(individualNomenclatureContent).join(', '),
          contractTitle: item.contract.title,
          documentAmount: item.documentAmount,
          registryNumber: item.registryNumber,
          id: item.id,
          flights: item.flights,
          volume: item.volume,
        };
      });

      setDataResponse(correctResponse);

      let nomenclature = [];
      let registry = [];
      if (isFirstRender === 0) {
        response.response.forEach((item: any) => {
          registry.push({ value: item?.contract?.title, label: item?.contract?.title });
          item.flights.forEach((item: any) => {
            nomenclature.push({
              value: item?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.nameByDocument,
              label: item?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.nameByDocument,
            });
          });
        });
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  const clearFilters = () => {
    setFilters(FiltersInitialState);
  };

  const disableButtonOfFilter = () => {
    let filtersValues = Object.values(filters);
    /*Если хоть какой фильтр не пустой, то выводить false */
    return !(filtersValues[0][0] || filtersValues[0][1] || filtersValues[1][0] || filtersValues[1][1]);
  };
  useEffect(() => {
    setIsFirstRender(prev => prev + 1);
    getData();
  }, []);
  useEffect(() => {
    setOpenCalendar(false);
    setOpenCalendarSecond(false);
  }, [openFilter]);

  useEffect(() => {
    if (tab === 'registryAndUpd' && isFirstRender > 0) {
      getData();
    }
  }, [filters]);

  useEffect(() => {
    if (tab === 'registryAndUpd' && isFirstRender > 0) {
      getData();
    }
    clearFilters();
    setOpenFilter(false);
  }, [tab]);
  return (
    <>
      <div className={styles.inputsAndSelectorsContainer}>
        <div className={styles.inputSearchContainer}>
          <InputSearch
            placeholder={'Поиск по номеру'}
            onInput={({ currentTarget: { value: search } }) =>
              setFilters(prevState => ({ ...prevState, filterSearch: search }))
            }
            value={filters.filterSearch}
          />
        </div>
        <FilterBox
          disabled={disableButtonOfFilter()}
          widthButton={isMobile ? '100%' : '290px'}
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          clearFilters={clearFilters}
        >
          <div>
            <CustomRangePicker
              width={'100%'}
              isPresets={true}
              open={openCalendarSecond}
              dateRange={filters.dateRange}
              setDateRange={value => setFilters(prevState => ({ ...prevState, dateRange: value }))}
              setOpen={setOpenCalendarSecond}
              placeholder={['По дате', 'док. УПД']}
            />
          </div>
          <div>
            <CustomRangePicker
              width={'100%'}
              isPresets={true}
              open={openCalendar}
              dateRange={filters.dateRangeSecond}
              setDateRange={value => setFilters(prevState => ({ ...prevState, dateRangeSecond: value }))}
              setOpen={setOpenCalendar}
              placeholder={['По дате', 'договора']}
            />
          </div>
        </FilterBox>
      </div>
      {isMobile ? (
        <>
          {loading ? (
            <div className={styles.loadingContainer}>
              <Spin />
            </div>
          ) : (
            <div className={styles.mobileSortInputsContainerMain}>
              {dataResponse?.length ? (
                dataResponse?.slice(0, more).map(item => (
                  <div onClick={() => navigate(`/document/UpdAndRegistry/${item.id}`)} className={styles.navigateToId}>
                    <div className={styles.mobileBlockTitle}>
                      <div className={styles.mobileTitle}>{item?.nomenclatureContentAll}</div>
                    </div>
                    <div className={styles.mobileBlock}>
                      <div className={styles.mobileElementsFirst} style={{ paddingTop: '12px' }}>
                        <div className={styles.mobileDescriptionString}>Дата УПД:</div>
                        <div className={styles.mobileDateString}>
                          {item?.date?.registryDate.slice(0, 10).split('-').reverse().join('.')}
                        </div>
                      </div>
                      <div className={styles.mobileElementsSecond}>
                        <div className={styles.mobileDescriptionString}>Номер УПД:</div>
                        <div className={styles.mobileUsualString}>{item?.registryNumber}</div>
                      </div>
                    </div>
                    <div className={styles.mobileBlock}>
                      <div className={styles.mobileElementsFirst}>
                        <div className={styles.mobileDescriptionString}>Дата договора:</div>
                        <div className={styles.mobileDateString}>
                          {item?.date?.contractDate.slice(0, 10).split('-').reverse().join('.')}
                        </div>
                      </div>
                      <div className={styles.mobileElementsSecond}>
                        <div className={styles.mobileDescriptionString}>Номер договора:</div>
                        <div className={styles.mobileUsualString}>{item?.contractTitle}</div>
                      </div>
                    </div>
                    <div className={styles.mobileLastBlock}>
                      <div className={styles.mobileElementsFirst}>
                        <div className={styles.mobileDescriptionString}>Количество:</div>
                        <div className={styles.mobileUsualString}>{item?.volume}</div>
                      </div>
                      <div className={styles.mobileElementsSecond} style={{ borderBottom: '0' }}>
                        <div className={styles.mobileDescriptionString}>Сумма с НДС:</div>
                        <div className={styles.mobileUsualString}>{formPrice(item?.documentAmount, 'RUB')}</div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <EmptyList
                  title={'Документов не найдено'}
                  subTitle={
                    <p className={'emptyDescription'}>
                      По выбранным фильтрам нет документов. <br />
                      Попробуйте изменить параметры
                    </p>
                  }
                />
              )}
            </div>
          )}
        </>
      ) : (
        <div className={styles.tableContainer}>
          <CustomTable
            loading={loading}
            linkTextBeforeId={'UpdAndRegistry'}
            link={true}
            columns={columnsUpd}
            data={dataResponse?.slice(0, more)}
          />
        </div>
      )}
      {dataResponse?.length > more && (
        <ButtonNotFilled
          margin={'40px 0 0 0'}
          onClick={() => setMore(isMobile ? more + 5 : more + 10)}
          text={'Показать ещё'}
          width={'100%'}
          color={'var(--color-orange400)'}
        />
      )}
    </>
  );
};

export default BuyerUpdAndRegistryPage;
