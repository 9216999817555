// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShowMore_svgContainer__7qOMG {\n  cursor: pointer;\n}\n\n.ShowMore_menu__ZKimv {\n  position: absolute;\n  top: 52px;\n  right: 12px;\n  width: 290px;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 4px;\n  background: var(--color-white);\n  border: 1px solid var(--color-gray200);\n  border-radius: 8px;\n  box-shadow: var(--shadow-medium);\n  z-index: 10;\n}\n\n.ShowMore_option__AlrzQ {\n  padding: 12px;\n  font-family: LiberationSans;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  color: var(--color-gray800);\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/Pages/AdminPage/components/ShowMore/ShowMore.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,QAAA;EACA,8BAAA;EACA,sCAAA;EACA,kBAAA;EACA,gCAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,2BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,2BAAA;EACA,eAAA;AACF","sourcesContent":[".svgContainer {\n  cursor: pointer;\n}\n\n.menu {\n  position: absolute;\n  top: 52px;\n  right: 12px;\n  width: 290px;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 4px;\n  background: var(--color-white);\n  border: 1px solid var(--color-gray200);\n  border-radius: 8px;\n  box-shadow: var(--shadow-medium);\n  z-index: 10;\n}\n\n.option {\n  padding: 12px;\n  font-family: LiberationSans;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  color: var(--color-gray800);\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"svgContainer": "ShowMore_svgContainer__7qOMG",
	"menu": "ShowMore_menu__ZKimv",
	"option": "ShowMore_option__AlrzQ"
};
export default ___CSS_LOADER_EXPORT___;
