import React from 'react';
import { useMediaQuery } from 'react-responsive';
import ButtonNotFilled from './newUI/ButtonNotFilled/ButtonNotFilled';

export const SuccessfulFormSubmission = ({ successfulActive, updatePage, setUpdatePage }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const send = () => {
    successfulActive(false);
    setUpdatePage(!updatePage);
  };

  return (
    <div className={successfulActive ? 'successfulFormSubmission active' : 'successfulFormSubmission'}>
      <div className="successfulFormSubmission__content">
        <div>
          <div className="successfulFormSubmission__svg">
            <svg width="100%" height="100%" viewBox="0 0 38 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M29 24.8337C30.3833 24.8337 31.5 23.717 31.5 22.3337C31.5 20.9503 30.3833 19.8337 29 19.8337C27.6166 19.8337 26.5 20.9503 26.5 22.3337C26.5 23.717 27.6166 24.8337 29 24.8337ZM31.5 9.83366H27.3333V14.0003H34.7666L31.5 9.83366ZM8.99996 24.8337C10.3833 24.8337 11.5 23.717 11.5 22.3337C11.5 20.9503 10.3833 19.8337 8.99996 19.8337C7.61663 19.8337 6.49996 20.9503 6.49996 22.3337C6.49996 23.717 7.61663 24.8337 8.99996 24.8337ZM32.3333 7.33366L37.3333 14.0003V22.3337H34C34 25.1003 31.7666 27.3337 29 27.3337C26.2333 27.3337 24 25.1003 24 22.3337H14C14 25.1003 11.7666 27.3337 8.99996 27.3337C6.23329 27.3337 3.99996 25.1003 3.99996 22.3337H0.666626V4.00033C0.666626 2.15033 2.14996 0.666992 3.99996 0.666992H27.3333V7.33366H32.3333ZM3.99996 4.00033V19.0003H5.26663C6.18329 17.9837 7.51663 17.3337 8.99996 17.3337C10.4833 17.3337 11.8166 17.9837 12.7333 19.0003H24V4.00033H3.99996ZM7.33329 11.5003L9.83329 9.00033L12.3333 11.5003L18.1666 5.66699L20.6666 8.16699L12.3333 16.5003L7.33329 11.5003Z"
                fill="currentColor"
              />
            </svg>
          </div>
          <div className="successfulFormSubmission__title">ТС и водитель назначены</div>
          <div className="successfulFormSubmission__subTitle">Данный рейс появится в личном кабинете водителя</div>
        </div>
        <ButtonNotFilled onClick={send} svg={null} disabled={false} text={'Хорошо'} />
        {!isMobile && <div onClick={() => successfulActive(false)} className="successfulFormSubmission__close"></div>}
        {isMobile && <div className="DriverPagePopup__line"></div>}
      </div>
    </div>
  );
};
