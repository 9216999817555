import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Drawer, Modal } from 'antd';
import { InputText } from '../../../../components/UI/inputs/InputText';
import ButtonNotFilled from '../../../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import ButtonFilled from '../../../../components/newUI/ButtonFilled/ButtonFilled';
import styles from './PrintTnModal.module.scss';
import FrameModalSvg from '../../../../components/UI/svg-icon/FrameModalSvg';
import { CloseSvg } from '../../../../components/UI/svg-icon/CloseSvg';
import { declinationWord } from '../../../../common/declinationWord.helper';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  setCountTN: React.Dispatch<string>;
  countTN: string;
  numberOfScheduledFlights: string;
  getDownloadTN: () => Promise<void>;
}

const PrintTnModal = ({ getDownloadTN, open, setOpen, countTN, setCountTN, numberOfScheduledFlights }: IProps) => {
  const validateSetCountTN = value => {
    const regex = /^[0-9]*$/;
    if (regex.test(value) && value < 50) {
      setCountTN(String(value));
    }
  };
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <>
      {isMobile ? (
        <div>
          <Drawer
            onClose={() => setOpen(false)}
            open={open}
            placement={`bottom`}
            closeIcon={false}
            zIndex={10001}
            height={'fit-content'}
            style={{ borderRadius: '12px 12px 0px 0px' }}
          >
            <div className={styles.mobileCloseIconDrawer} onClick={() => setOpen(false)}>
              <CloseSvg />
            </div>
            <div className={styles.Container}>
              <h1 className={styles.title}>Скачать транспортные накладные</h1>
              <div>
                <div className={styles.countTNContainer}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <g clipPath="url(#clip0_28724_192295)">
                      <path
                        d="M10.6254 5.62464V8.12464L12.5004 10.6246V13.7496M8.1254 5.62464V13.7496M0.884836 9.37327L9.37424 0.883859C9.37424 0.883859 10.0006 0.257481 10.627 0.883859L19.1164 9.37327C19.1164 9.37327 19.7428 9.99964 19.1164 10.626L10.627 19.1154C10.627 19.1154 10.0006 19.7418 9.37424 19.1154L0.884836 10.626C0.884836 10.626 0.258457 9.99964 0.884836 9.37327Z"
                        stroke="var(--color-gray900)"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_28724_192295">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p>
                    У вас {numberOfScheduledFlights}
                    {declinationWord(Number(numberOfScheduledFlights), [
                      'запланированный рейс',
                      'запланированных рейса',
                      'запланированных рейсов',
                    ])}
                  </p>
                </div>

                <p className={styles.inputTitle}>Выберите необходимое количество до 50</p>
                <InputText
                  isLabel={true}
                  error={false}
                  type={'text'}
                  value={countTN}
                  textError={null}
                  placeholder={'Транспортные накладные'}
                  setValue={value => validateSetCountTN(Number(value))}
                  disabled={false}
                  helpText={null}
                />
                <div className={styles.btnContainer}>
                  <ButtonNotFilled onClick={() => setOpen(false)} text={'Отмена'} width={'140px'} height={'48px'} />
                  <ButtonFilled
                    disabled={false}
                    text={'Скачать'}
                    onClick={() => getDownloadTN()}
                    height={'48px'}
                    width={'140px'}
                  />
                </div>
              </div>
            </div>
          </Drawer>
        </div>
      ) : (
        <div>
          <Modal
            width={'fit-content'}
            zIndex={9500}
            footer={null}
            closeIcon={false}
            open={open}
            onCancel={() => setOpen(false)}
            className={styles.modal}
          >
            <div className={styles.Container}>
              <h1 className={styles.title}>Скачать транспортные накладные</h1>
              <div>
                <div className={styles.countTNContainer}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <g clipPath="url(#clip0_28724_192295)">
                      <path
                        d="M10.6254 5.62464V8.12464L12.5004 10.6246V13.7496M8.1254 5.62464V13.7496M0.884836 9.37327L9.37424 0.883859C9.37424 0.883859 10.0006 0.257481 10.627 0.883859L19.1164 9.37327C19.1164 9.37327 19.7428 9.99964 19.1164 10.626L10.627 19.1154C10.627 19.1154 10.0006 19.7418 9.37424 19.1154L0.884836 10.626C0.884836 10.626 0.258457 9.99964 0.884836 9.37327Z"
                        stroke="var(--color-gray900)"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_28724_192295">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p>У вас {numberOfScheduledFlights} запланированных рейсов</p>
                </div>

                <p className={styles.inputTitle}>Выберите необходимое количество до 50</p>
                <InputText
                  isLabel={true}
                  error={false}
                  type={'text'}
                  value={countTN}
                  textError={null}
                  placeholder={'Транспортные накладные'}
                  setValue={value => validateSetCountTN(Number(value))}
                  disabled={false}
                  helpText={null}
                />
                <div className={styles.btnContainer}>
                  <ButtonNotFilled onClick={() => setOpen(false)} text={'Отмена'} width={'170px'} height={'48px'} />
                  <ButtonFilled
                    disabled={false}
                    text={'Скачать'}
                    onClick={() => getDownloadTN()}
                    height={'48px'}
                    width={'170px'}
                  />
                </div>
              </div>
            </div>

            <div className={styles.closeButton}>
              <FrameModalSvg onClick={() => setOpen(false)} />
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default PrintTnModal;
