import React, { useEffect, useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import useClickOutside from '../../../CustomHooks/useClickOutside';
import CustomTooltip from '../../newUI/CustomTooltip/CustomTooltip';
import { CloseCircleNewSvg } from '../svg-icon/CloseCircleNewSvg';
import { useMediaQuery } from 'react-responsive';

export const MyPhoneInput = ({
  value,
  disabled,
  onChange,
  isActive,
  btnHelp,
  setIsActive,
  isError,
  setIsError,
  isFunction,
  textError = '',
  onEnterDisabled = false,
  builtInErrorState = false,
  ...props
}) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [error, setError] = useState<boolean>(value?.length <= 10);
  const [inputValue, setInputValue] = useState<string>('');
  const [hoverInput, setHoverInput] = useState<boolean>(false);
  const onChangeErrorByBuiltInErrorState = builtInErrorState ? error : isError;
  const onChangeSetErrorByBuiltInErrorState = builtInErrorState ? setError : setIsError;
  const inputRef = useRef(null);
  const regex = /^[\+7||7].*/;
  useEffect(() => {
    if (inputValue === null) {
      return;
    }
    if (inputValue?.length < 2) {
      setInputValue('+7');
      return;
    }
    if (!regex.test(inputValue)) {
      setInputValue('+7' + inputValue.replace(/[^0-9]/g, '').slice(0, 10));
      return;
    }

    onChange(inputValue);
  }, [inputValue]);
  useEffect(() => {
    setInputValue(value ? value : '+7');
  }, []);
  const clickRef = useRef();
  useClickOutside(clickRef, setIsActive);

  const onEnterKeySendFunction = e => {
    if (!onEnterDisabled) {
      isFunction(e);
      return;
    }
    return;
  };
  return (
    <div
      onBlur={() => {
        !hoverInput && onChangeSetErrorByBuiltInErrorState(value?.length >= 0 && value?.length <= 10);
      }}
      onFocus={() => {
        onChangeSetErrorByBuiltInErrorState(false);
      }}
      onMouseOver={() => {
        setHoverInput(true);
      }}
      onMouseOut={() => {
        setHoverInput(false);
      }}
      className="MyPhoneInput form-item"
      {...props}
    >
      <PhoneInput
        disabled={disabled}
        specialLabel=""
        placeholder=""
        value={inputValue}
        onChange={setInputValue}
        inputClass={`MyPhoneInput__input form-input ${onChangeErrorByBuiltInErrorState && 'error'} ${disabled && 'disabled'}`}
        onEnterKeyPress={e => onEnterKeySendFunction(e)}
        inputProps={{ ref: inputRef }}
      />
      {builtInErrorState && error && (
        <p style={{ color: 'var(--color-red600)', fontSize: '12px' }}>Поле обязательное для заполнения</p>
      )}
      {
        <label
          className={
            onChangeErrorByBuiltInErrorState
              ? inputValue
                ? 'InputText__label form-label valid error'
                : 'InputText__label form-label error'
              : inputValue
                ? 'InputText__label form-label valid'
                : 'InputText__label form-label'
          }
        >
          Номер телефона
        </label>
      }
      {btnHelp ? (
        <div
          ref={clickRef}
          onMouseDown={() => {
            setInputValue('');
          }}
          className={onChangeErrorByBuiltInErrorState ? 'MyPhoneInput__help error' : 'MyPhoneInput__help'}
        >
          {!(inputValue === '' || inputValue === '+7' || inputValue === '7') && (
            <CustomTooltip
              MobileSideTooltip={'left'}
              SideTooltip={'left'}
              svg={<CloseCircleNewSvg width={isMobile ? '20px' : '16px'} />}
              text={''}
              widthSvg={'100%'}
              widthTooltip={'311px'}
              style={isMobile ? { paddingTop: '2px' } : { paddingTop: '4px' }}
            />
          )}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};
