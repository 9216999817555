import React from 'react';
interface IProps {
  onClick?: any;
}
const FrameModalSvg = ({ onClick }: IProps) => {
  return (
    <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame" filter="url(#filter0_d_21725_117212)" style={{ cursor: 'pointer' }} onClick={onClick}>
        <rect x="32" y="24" width="48" height="48" rx="24" fill="white" />
        <path
          id="Vector"
          d="M63 42.41L61.59 41L56 46.59L50.41 41L49 42.41L54.59 48L49 53.59L50.41 55L56 49.41L61.59 55L63 53.59L57.41 48L63 42.41Z"
          fill="var(--color-gray800)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_21725_117212"
          x="0"
          y="0"
          width="112"
          height="112"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="16" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.541933 0 0 0 0 0.532585 0 0 0 0 1 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_21725_117212" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_21725_117212" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default FrameModalSvg;
