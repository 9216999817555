import React from 'react';

export const ErrorSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_25576_213456)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.83398 8.00016C1.83398 4.59441 4.5949 1.8335 8.00065 1.8335C11.4064 1.8335 14.1673 4.59441 14.1673 8.00016C14.1673 11.4059 11.4064 14.1668 8.00065 14.1668C4.5949 14.1668 1.83398 11.4059 1.83398 8.00016ZM8.00065 0.833496C4.04261 0.833496 0.833984 4.04212 0.833984 8.00016C0.833984 11.9582 4.04261 15.1668 8.00065 15.1668C11.9587 15.1668 15.1673 11.9582 15.1673 8.00016C15.1673 4.04212 11.9587 0.833496 8.00065 0.833496ZM8.00065 4.16683C8.27679 4.16683 8.50065 4.39069 8.50065 4.66683V8.66683C8.50065 8.94297 8.27679 9.16683 8.00065 9.16683C7.72451 9.16683 7.50065 8.94297 7.50065 8.66683V4.66683C7.50065 4.39069 7.72451 4.16683 8.00065 4.16683ZM8.66732 10.6668C8.66732 11.035 8.36884 11.3335 8.00065 11.3335C7.63246 11.3335 7.33398 11.035 7.33398 10.6668C7.33398 10.2986 7.63246 10.0002 8.00065 10.0002C8.36884 10.0002 8.66732 10.2986 8.66732 10.6668Z"
          fill="var(--color-blue900)"
        />
      </g>
      <defs>
        <clipPath id="clip0_25576_213456">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

{
  /* <svg width="22px" height="22px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 13H11V15H9V13ZM9 5H11V11H9V5ZM10 0C4.47 0 0 4.5 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0M10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18V18Z"
        fill="currentColor"
      />
</svg> */
}
