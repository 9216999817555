import React, { useEffect, useState } from 'react';
import CustomTable from '../../components/newUI/CustomTable/CustomTable';
import styles from './SupplierUpdAndRegistryPage.module.scss';
import CustomRangePicker from '../../components/newUI/CustomRangePicker/CustomRangePicker';
import { fetchGet } from '../../common/proxy-1C-fetch-auth';
import { IFilters, IRegistry } from './type';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { formPrice } from '../../common/form-price.helper';
import { clearObject } from '../../common/ClearObject.helper';
import { InputSearch } from '../../components/UI/inputs/InputSearch';
import FilterBox from '../../components/newUI/FilterBox/FilterBox';
import { FiltersInitialState } from './const';
import { optionSumValue } from '../../common/select-format.type';
import { SelectCustom } from '../../components/UI/select/SelectCustom';
// @ts-ignore
import LoadingGif from '../../assets/img/Loader-Solber.gif';
import ButtonNotFilled from '../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import { EmptyList } from '../../components/EmptyList';
import { tabNames } from '../DocumentsPages/DocumentsPage';

interface IProps {
  tab: string;
}
const SupplierUpdAndRegistryPage = ({ tab }: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [filters, setFilters] = useState<IFilters>(FiltersInitialState);
  const [dataResponse, setDataResponse] = useState<[IRegistry]>();
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [more, setMore] = useState(isMobile ? 5 : 10);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const clearFilters = () => {
    setFilters(FiltersInitialState);
  };
  const disableButtonOfFilter = () => {
    let filtersValues = Object.values(filters);
    /*Если выбрана дата или селектор 'по сумме', то выводить false */
    return !(filtersValues[0][0] || filtersValues[0][1] || filtersValues[2].order);
  };

  const Spin = () => {
    return (
      <div style={{ marginTop: '40px' }}>
        <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
      </div>
    );
  };
  const getData = async () => {
    setLoading(true);
    let requestData = {
      startDate: filters.dateRange[0] ? new Date(filters.dateRange[0]) : undefined,
      finishDate: filters.dateRange[1] ? new Date(filters.dateRange[1]) : undefined,
      order: filters.sort.order ? filters.sort.order : undefined,
      registryNumber: filters.filterSearch ? filters.filterSearch : undefined,
    };
    let correctRequestData = clearObject(requestData);
    try {
      const response = await fetchGet(`/registries`, correctRequestData);
      setLoading(false);
      setDataResponse(response.response);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    setOpenCalendar(false);
  }, [openFilter]);

  useEffect(() => {
    if (tab === tabNames.REGISTRYANDUP) {
      getData();
    }
    clearFilters();
    setOpenFilter(false);
  }, [tab, filters]);

  const columns = [
    {
      title: 'Дата документа',
      dataIndex: 'registryDate',
      width: 330,
      key: 'registryDate',
      render: (text: string) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 0 0 24px',
            height: '30px',
          }}
        >
          <p className={styles.dateContainer}>{text.slice(0, 10).split('-').reverse().join('.')}</p>
          <div
            style={{
              color: 'var(--color-gray200)',
              height: '30px',
              border: '1px solid var(--color-gray200)',
              margin: '0 24px',
            }}
          ></div>
        </div>
      ),
    },
    {
      title: 'Номер документа',
      dataIndex: 'registryNumber',
      width: 767,
      key: 'registryNumber',
      render: text => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '30px' }}>
          <p className={styles.textOfNumDocAndSum}>{text}</p>
          <div
            style={{
              color: 'var(--color-gray200)',
              height: '28px',
              border: '1px solid var(--color-gray200)',
              margin: '0 24px',
            }}
          ></div>
        </div>
      ),
    },
    {
      title: 'Сумма документа с НДС',
      dataIndex: 'documentAmount',
      width: 189,
      key: 'documentAmount',
      render: text => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '30px' }}>
          <p className={styles.textOfNumDocAndSum}>{formPrice(text, 'RUB')}</p>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className={styles.container}>
        <div className={styles.sortInputsContainer}>
          <div className={styles.inputSearchContainer}>
            <InputSearch
              placeholder={'Поиск по номеру'}
              onInput={({ currentTarget: { value: search } }) =>
                setFilters(prevState => ({ ...prevState, filterSearch: search }))
              }
              value={filters.filterSearch}
            />
          </div>
          <FilterBox
            widthButton={isMobile ? '100%' : '290px'}
            disabled={disableButtonOfFilter()}
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            clearFilters={clearFilters}
          >
            <div style={{ width: '100%' }}>
              <CustomRangePicker
                placeholder={['Начало периода', 'Конец периода']}
                width={'100%'}
                isPresets={true}
                open={openCalendar}
                dateRange={filters.dateRange}
                setDateRange={value => setFilters(prevState => ({ ...prevState, dateRange: value }))}
                setOpen={setOpenCalendar}
              />
            </div>
            <div>
              <SelectCustom
                options={optionSumValue}
                maxTagCount={1}
                maxCount={1}
                placeholder={'Сумма'}
                mode={null}
                value={filters.sort.field}
                setValue={value => {
                  setFilters(prevState => ({
                    ...prevState,
                    sort: {
                      field: value.value,
                      order: value.value === 'по возрастанию' ? 'ASC' : 'DESC',
                    },
                  }));
                }}
                disabled={false}
                status={''}
                errorText={''}
              />
            </div>
          </FilterBox>
        </div>
        {isMobile ? (
          <>
            {loading ? (
              <div className={styles.loadingContainer}>
                <Spin />
              </div>
            ) : (
              <>
                {!dataResponse?.length ? (
                  <EmptyList
                    title={'Документов не найдено'}
                    subTitle={
                      <p>
                        По выбранным фильтрам нет документов. <br />
                        Попробуйте изменить параметры
                      </p>
                    }
                  />
                ) : (
                  <div className={styles.contentContainer}>
                    {dataResponse?.slice(0, more).map(item => (
                      <div
                        className={styles.mobileItemContainer}
                        onClick={() => {
                          navigate(`/document/UpdAndRegistry/${item.id}`);
                        }}
                        key={item?.id}
                      >
                        <p className={styles.dateContainer}>{item.registryDate.slice(0, 10).replaceAll('-', '.')}</p>
                        <div className={styles.mobileTextContainer}>
                          <p style={{ fontWeight: '500' }}>Номер УПД:</p>
                          <p>{item.registryNumber}</p>
                        </div>
                        <div className={styles.mobileTextContainer}>
                          <p style={{ fontWeight: '500' }}>Сумма УПД с НДС:</p>
                          <p>{formPrice(item.documentAmount, 'RUB')}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <div>
            <div className={styles.tableContainer}>
              <CustomTable
                loading={loading}
                link={true}
                linkTextBeforeId={'UpdAndRegistry'}
                columns={columns}
                data={dataResponse?.slice(0, more)}
              />
            </div>
          </div>
        )}
      </div>
      {dataResponse?.length > more && (
        <ButtonNotFilled
          margin={'40px 0 0 0'}
          onClick={() => setMore(isMobile ? more + 5 : more + 10)}
          text={'Показать ещё'}
          width={'100%'}
          color={'var(--color-orange400)'}
        />
      )}
    </>
  );
};

export default SupplierUpdAndRegistryPage;
