import React from 'react';

export const AdminPanelSvg = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_31373_44640)">
        <path
          d="M19.5 5V7H15.5V5H19.5ZM9.5 5V11H5.5V5H9.5ZM19.5 13V19H15.5V13H19.5ZM9.5 17V19H5.5V17H9.5ZM21.5 3H13.5V9H21.5V3ZM11.5 3H3.5V13H11.5V3ZM21.5 11H13.5V21H21.5V11ZM11.5 15H3.5V21H11.5V15Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_31373_44640">
          <rect width="24" height="24" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
