import React, { useCallback, useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import { useMediaQuery } from 'react-responsive';
import { Tabs } from '../../components/Tabs';
import { EmptyContent } from '../../components/UI/EmptyContent/EmptyContent';
import { Frame } from '../../components/Frame';
import { fetchGet, fetchPost } from '../../common/proxy-1C-fetch-auth';
import { toast } from 'react-toastify';
import { FrameDataType } from '../../common/frame-data.type';
import { SelectBox } from '../../components/SelectBox';
import { SelectCustom } from '../../components/UI/select/SelectCustom';
import { SearchSvg } from '../../components/UI/svg-icon/SearchSvg';
import { WarningSvg } from '../../components/UI/svg-icon/WarningSvg';
import { CloseCircleRedSvg } from '../../components/UI/svg-icon/CloseCircleRedSvg';
import { ProgressElement } from '../../components/ProgressElement';
import { closePopUp, openPopUp } from '../../common/open-close-popup.function';
import { CloseSvg } from '../../components/UI/svg-icon/CloseSvg';
import { InputText } from '../../components/UI/inputs/InputText';
import { MyPhoneInput } from '../../components/UI/inputs/MyPhoneInput';
import { RadioBoxCircle } from '../../components/UI/checkBox/RadioBoxCircle';
import { AsidePopup } from '../../components/AsidePopup';
import { CheckBoxCustom } from '../../components/UI/checkBox/CheckBoxCustom';
import { TimePicker } from 'antd';
import { SuccessGreenSvg } from '../../components/UI/svg-icon/SuccessGreenSvg';
import dayjs from 'dayjs';
import { StopSvg } from '../../components/UI/svg-icon/StopSvg';
import { CirclePlusSvg } from '../../components/UI/svg-icon/CirclePlusSvg';
import CustomRangePicker from '../../components/newUI/CustomRangePicker/CustomRangePicker';
import styles from './OrdersPage.module.scss';
// @ts-ignore
import LoadingGif from '../../assets/img/Loader-Solber.gif';
import { clearObject } from '../../common/ClearObject.helper';
import TitleMain from '../../components/UI/TitleAndSubtitle/TitleMain';
import NewPlusSvg from '../../components/UI/svg-icon/NewPlusSvg';
import ButtonFilled from '../../components/newUI/ButtonFilled/ButtonFilled';
import ButtonNotFilled from '../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import SubTitleMain from 'src/components/UI/TitleAndSubtitle/SubTitleMain';
import { EmptyList } from '../../components/EmptyList';
import imgError from '../../assets/img/ErrorPng.png';
import img from '../../assets/img/EmptyPng.png';
import { ButtonSimple } from 'src/components/newUI/ButtonSimple/ButtonSimple';

export const OrdersPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [orders, setOrders] = useState<FrameDataType[]>([]);
  const [filterOrders, setFilterOrders] = useState<FrameDataType[]>();
  const [more, setMore] = useState<number>(isMobile ? 4 : 10);
  const [popup, setPopup] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState<number>(0);

  const [isActive, setIsActive] = useState(false);
  const [isErrorPhone, setIsErrorPhone] = useState(false);
  const [errorCheckBox, setErrorCheckBox] = useState(false);

  const [updatePage, setUpdatePage] = useState(false);
  const [errorGetData, setErrorGetData] = useState(false);
  const [skeleton, setSkeleton] = useState(true);

  const tabsData = {
    Открытые: {
      content: orders?.length ? (
        <Frame
          gridTemplateColumns={'68px 83px 120px 460px 120px 56px 175px'}
          link={'orders'}
          items={filterOrders.slice(0, more)}
          orderTab={'Открытые'}
          help={true}
          contentHelp={<div></div>}
        />
      ) : (
        <EmptyContent
          svg={<SearchSvg />}
          title={'Результатов не найдено.'}
          subTitle={'Нет Заказов по выбранному периоду времени. Пожалуйста, попробуйте другой период.'}
        />
      ),
      active: true,
    },
    Закрытые: {
      content: orders?.length ? (
        <Frame
          gridTemplateColumns={' 120px 160px 160px 120px 70px 150px'}
          link={'orders'}
          items={filterOrders.slice(0, more)}
          orderTab={'Закрытые'}
        />
      ) : (
        <EmptyContent
          svg={<SearchSvg />}
          title={'Результатов не найдено.'}
          subTitle={'Нет Заказов по выбранному периоду времени. Пожалуйста, попробуйте другой период.'}
        />
      ),
      active: false,
    },
  };
  const [tabs, setTabs] = useState(tabsData);
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<any>(['', '']);
  const [openLeftTimer, setOpenLeftTimer] = useState(false);
  const [openRightTimer, setOpenRightTimer] = useState(false);
  const [isObject, setIsObject] = useState<any>(null);
  const [popupContentState, setPopupContentState] = useState('default');
  const [orderData, setOrderData] = useState<{
    contract: string;
    specification: string;
    phone: string | number;
    typeTC: {
      id: string;
      active: boolean;
      title: string;
    }[];
    TypeOfAcceptance1: {
      name: string;
    };
    TypeOfAcceptance2: {
      name: string;
    };
    nomenclaturesType: string;
    ordersNomenclatures: any;
    workingHoursType: {
      name: string;
      workTo: any;
      defaultWorkTo?: any;
      workFrom: any;
      defaultWorkFrom?: any;
    };
    orderContacts: {
      orderContactsMain: {
        person: string | null;
        phone: string | null;
      };
      orderContactsAdditionally: {
        id?: number | null;
        person: string | null;
        phone: string | null;
        deleted: boolean | null;
      }[];
    };
  }>({
    contract: '',
    specification: '',
    phone: '',
    typeTC: [
      {
        id: 'everyone',
        active: false,
        title: 'Проходят все',
      },
      {
        id: 'semitrailer-30-45',
        active: false,
        title: 'Полуприцеп 30 м³ - 45 т',
      },
      {
        id: 'four-axis-30-45',
        active: false,
        title: '4х-осные 30 м³ - 45 т',
      },
      {
        id: 'four-axis-20-30',
        active: false,
        title: '4х-осные 20 м³ - 30 т',
      },
      {
        id: 'three-axis-10-15',
        active: false,
        title: '3х-осные 10 м³ - 15 т',
      },
    ],
    ordersNomenclatures: '',
    TypeOfAcceptance1: {
      name: 'Доверенность',
    },
    TypeOfAcceptance2: {
      name: 'Весы',
    },
    nomenclaturesType: '',
    workingHoursType: {
      name: 'Круглосуточная',
      workTo: '',
      workFrom: '',
    },
    orderContacts: {
      orderContactsMain: {
        person: '',
        phone: '',
      },

      orderContactsAdditionally: [],
    },
  });

  const [ordersSelects, setOrdersSelects] = useState<{
    ordersContracts: {
      options:
        | {
            value: string | number;
            label: string;
          }[]
        | any;
      value: {
        value: string | number;
        label: string;
      };
    };
    ordersSpecifications: {
      options:
        | {
            value: string | number;
            label: string;
          }[]
        | any;
      value: {
        value: string | number;
        label: string;
      };
    };
    ordersNomenclatures: {
      options:
        | {
            value: string | number;
            label: string;
          }[]
        | any;
      value: {
        value: string | number;
        label: string;
      };
    };
    ordersObjects: {
      options:
        | {
            value: string | number;
            label: string;
          }[]
        | any;
      value: {
        value: string | number;
        label: string;
      };
    };
  }>({
    ordersContracts: {
      options: [],
      value: {
        value: 0,
        label: '',
      },
    },
    ordersSpecifications: {
      options: [],
      value: {
        value: 0,
        label: '',
      },
    },
    ordersNomenclatures: {
      options: [],
      value: {
        value: 0,
        label: '',
      },
    },
    ordersObjects: {
      options: [],
      value: {
        value: 0,
        label: '',
      },
    },
  });
  const [nomenclatureSelects, setNomenclatureSelects] = useState<{
    options:
      | {
          value: string | number;
          label: string;
        }[]
      | any;
    value: any | null;
  }>({
    options: [{}],
    value: [],
  });
  const [objectSelects, setObjectSelects] = useState<{
    options:
      | {
          value: string | number;
          label: string;
        }[]
      | any;
    value: any | null;
  }>({
    options: [{}],
    value: [],
  });
  const [getOrders, setGetOrders] = useState({
    contractId: 1,
    newSpecification: false,
    specificationId: 0,
    nomenclatureId: 0,
    nomenclature: '',
    volume: 0,
    unit: '',
    price: 0,
    objectId: 1,
    needAttorney: false,
    needSeal: false,
    acceptanceScales: true,
    acceptanceVolume: false,
    semitrailers: ['four-axis-30-45', 'semitrailer-30-45'],
    object: {
      time: {
        workingHoursType: 'daytime', //aroundTheClock
        from: '08:00',
        to: '17:00',
      },
      mainContactPerson: {
        person: 'Синицин Андрей Константинович',
        phone: '+79999999999',
      },
      contactPersons: [
        {
          person: 'Синицин Андрей Константинович',
          phone: '+79999999998',
        },
      ],
    },
  });
  const [errorData, setErrorData] = useState({
    ordersContracts: false,
    ordersSpecifications: false,
    ordersNomenclatures: false,
    ordersNomenclaturesUnit: false,
    ordersObjects: false,
    nomenclatureInput: false,
    volume: false,
    price: false,
    mainContactName: false,
  });

  const [data, setData] = useState([]);
  const [specification, setSpecification] = useState<any>({});

  const ordersDates = [];
  const ordersObjects = [];

  const statusesCall = () => {
    return tabsData[decodeURIComponent(window.location.hash).split('#')[1]].content;
  };

  interface OrderType {
    id: string;
    active: boolean;
  }

  const pickCheck = id => {
    const newTypeTC = [];
    const newTypeTCSend = [];

    if (id === 'everyone') {
      const { active } = orderData.typeTC.find(item => item.id === id);

      orderData.typeTC.forEach((item: OrderType) => {
        active ? (item.active = false) : (item.active = true);

        newTypeTC.push(item);
      });

      const activeSemitrailers = orderData.typeTC.filter(item => item.active && item.id !== 'everyone');
      const semitrailersIds = activeSemitrailers.map(item => item.id);

      setOrderData(prevState => ({ ...prevState, typeTC: newTypeTC, semitrailers: semitrailersIds }));

      return;
    }

    orderData.typeTC.forEach((item: OrderType) => {
      if (item.id === 'everyone') {
        item.active = false;
      }

      newTypeTC.push(item);
    });

    newTypeTC.splice(0, newTypeTC.length);

    orderData.typeTC.forEach((item: OrderType) => {
      if (item.id === id) {
        item.active = !item.active;
      }

      newTypeTC.push(item);
      if (item.active) {
        newTypeTCSend.push(item.id);
      }
    });

    setOrderData(prevState => ({ ...prevState, typeTC: newTypeTC, semitrailers: newTypeTCSend }));
  };
  useEffect(() => {
    if (popup) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [popup]);
  const changeTime = (time: any, timeString: string | string[], input: string, inputTime: string) => {
    setOrderData(prevState => ({
      ...prevState,
      workingHoursType: {
        ...prevState.workingHoursType,
        [input]: timeString,
        [inputTime]: timeString,
      },
    }));
  };

  const statusMapping = {
    Открыт: 'blue',
    'На согласовании': 'blue',
    Подтвержден: 'green',
    Отклонен: 'red',
  };

  const tabsMapping = {
    Открытые: 'open',
    Закрытые: 'closed',
  };

  const successSend = () => {
    setPopupContentState('default');
    setOrdersSelects(prevState => ({
      ...prevState,
      ordersContracts: {
        options: [],
        value: {
          value: 0,
          label: '',
        },
      },
      ordersSpecifications: {
        options: [],
        value: {
          value: 0,
          label: '',
        },
      },
      ordersNomenclatures: {
        options: [],
        value: {
          value: 0,
          label: '',
        },
      },
      ordersObjects: {
        options: [],
        value: {
          value: 0,
          label: '',
        },
      },
    }));
    setIsObject(null);
    orderData.typeTC.forEach(type => {
      type.active = false;
    });
    setGetOrders(prevState => ({ ...prevState, volume: 0, price: 0, unit: '' }));
    setOrderData(prevState => ({
      ...prevState,
      contract: '',
      phone: '',
      typeTC: orderData.typeTC,
      TypeOfAcceptance1: {
        name: 'Доверенность',
      },
      TypeOfAcceptance2: {
        name: 'Весы',
      },
      nomenclaturesType: '',
      workingHoursType: {
        name: 'Круглосуточная',
        workTo: '',
        workFrom: '',
      },
    }));
    setPopup(closePopUp);
    setUpdatePage(!updatePage);
  };

  const customTimePickerButton = (isLeft: boolean) => {
    const closeTimer = () => {
      if (isLeft) {
        setOpenLeftTimer(false);
      } else {
        setOpenRightTimer(false);
      }
    };

    return (
      <button onClick={closeTimer} className={styles.customRangePickerBtnСhoose}>
        Готово
      </button>
    );
  };

  const getData = async () => {
    await setSkeleton(true);

    let requestData = {
      content: nomenclatureSelects.value.length > 0 ? nomenclatureSelects.value.map(item => item.label) : undefined,
      startDate: dateRange[0] ? new Date(dateRange[0]) : undefined,
      finishDate: dateRange[1] ? new Date(dateRange[1]) : undefined,
      objectsId: objectSelects.value.length > 0 ? objectSelects.value.map(item => item.value) : undefined,
      status: tabsMapping[decodeURIComponent(window.location.hash).split('#')[1]],
    };
    let correctRequestData = clearObject(requestData);
    if (
      !(
        correctRequestData.hasOwnProperty('startDate') &&
        correctRequestData.hasOwnProperty('finishDate') &&
        correctRequestData.hasOwnProperty('nomenclaturesId') &&
        correctRequestData.hasOwnProperty('objectsId')
      )
    ) {
      setIsFirstRender(isFirstRender + 1);
    }

    try {
      const response = await fetchGet(`/orders`, correctRequestData);

      const responseContracts = await fetchGet(`/information/contracts`, {});

      const responseObjects = await fetchGet(`/objects`, {});

      setData(responseContracts.response);

      const contracts = [];
      const objects = [];
      const nomenclature = [];
      const object = [];
      if (isFirstRender === 0) {
        response?.response?.forEach((item: any) => {
          if (nomenclature?.find(({ value }) => value === item?.nomenclature?.nameByDocument)) {
            return;
          }

          nomenclature?.push({ value: item?.nomenclature?.id, label: item?.nomenclature?.nameByDocument });

          setNomenclatureSelects(prevState => ({
            ...prevState,
            options: nomenclature?.filter(
              (value, index, array) => array.findIndex(value2 => value2.label === value.label) === index,
            ),
          }));
        });

        response?.response?.forEach((item: any) => {
          if (object.find(({ value }) => value === item?.specification?.contract?.organization?.title)) {
            return;
          }
          object.push({
            value: item?.object?.id,
            label: item?.object?.name,
          });

          setObjectSelects(prevState => ({
            ...prevState,
            options: object.filter(
              (value, index, array) => array.findIndex(value2 => value2.value === value.value) === index,
            ),
          }));
        });
      }

      responseContracts?.response?.forEach(contract => {
        if (contracts.find(({ label }) => label === contract?.title)) {
          return;
        }

        contracts.push({ value: contract?.id, label: contract?.title });
      });

      responseObjects.response.forEach(({ id, name }) => {
        if (objects.find(({ label }) => label === name)) {
          return;
        }

        objects.push({ value: id, label: name });
      });

      setOrdersSelects(prevState => ({
        ...prevState,
        ordersContracts: { ...prevState.ordersContracts, options: contracts },
        ordersObjects: { ...prevState.ordersObjects, options: objects },
      }));

      const ordersData: FrameDataType[] = [];

      response?.response?.forEach((order, index) => {
        ordersDates?.push(new Date(order.date).toISOString().split('T')[0]);
        ordersObjects?.push([]);
        ordersData?.push({
          id: order.id,
          item: {
            Статус:
              decodeURIComponent(window.location.hash).split('#')[1] !== 'Закрытые'
                ? {
                    title: {
                      up: (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: isMobile ? 'flex-start' : 'center',
                          }}
                        >
                          <span className={`orders-page-content__status ${statusMapping[order?.status?.title]}`}></span>
                          {isMobile && (
                            <span className={`orders-page-content__status-text ${statusMapping[order?.status?.title]}`}>
                              {order?.status?.title}
                            </span>
                          )}
                        </div>
                      ),
                      down: null,
                    },
                    help: true,
                    contentHelp: (
                      <div className={'orders-page-content-help-balloon'}>
                        <div className={'orders-page-content-help-balloon__item'}>
                          <div
                            className={
                              'orders-page-content-help-balloon__circle orders-page-content-help-balloon__circle_blue'
                            }
                          ></div>
                          <div className={'orders-page-content-help-balloon__text textMedium'}>На согласовании</div>
                        </div>
                        <div className={'orders-page-content-help-balloon__item'}>
                          <div
                            className={
                              'orders-page-content-help-balloon__circle orders-page-content-help-balloon__circle_green'
                            }
                          ></div>
                          <div className={'orders-page-content-help-balloon__text textMedium'}>Подтвержден</div>
                        </div>
                        <div className={'orders-page-content-help-balloon__item'}>
                          <div
                            className={
                              'orders-page-content-help-balloon__circle orders-page-content-help-balloon__circle_red'
                            }
                          ></div>
                          <div className={'orders-page-content-help-balloon__text textMedium'}>Отклонен</div>
                        </div>
                      </div>
                    ),
                  }
                : undefined,
            'Дата начала': {
              title: {
                up: new Date(order.date).toLocaleString().split(',')[0],
                down: null,
              },
              help: false,
              contentHelp: null,
            },
            Контрагент: {
              title: {
                up: order?.specification?.contract?.organization?.title,
                down: '',
              },
              help: false,
              contentHelp: null,
            },
            'Адрес объекта': {
              title: {
                up: order?.object?.address?.address,
                down: '',
              },
              help: false,
              contentHelp: null,
            },
            Номенклатура: {
              title: {
                up: order?.nomenclature?.content,
                down: '',
              },
              help: false,
              contentHelp: null,
            },
            'Ед. изм.': {
              title: {
                up: order?.nomenclature?.unit,
                down: '',
              },
              help: false,
              contentHelp: null,
            },
            'Объем заказа (факт/план)': {
              title: {
                up: (
                  <>
                    <div className={'orders-page__progress'}>
                      <ProgressElement
                        startText={''}
                        endText={''}
                        intermediateNumber={order?.volumeDelivered | 0}
                        endNumber={order?.volume | 0}
                        unit={order?.nomenclature?.unit}
                      />
                    </div>
                  </>
                ),
                down: '',
              },
              help: false,
              contentHelp: null,
            },
          },
        });
      });
      if (decodeURIComponent(window.location.hash).split('#')[1] !== 'Закрытые') {
        setOrders(ordersData);
        setFilterOrders(ordersData);
      } else {
        const CorrectionOrdersData = ordersData?.map(itemOrder => {
          const clearItems = clearObject(itemOrder?.item);
          return { ...itemOrder, item: clearItems };
        });
        setOrders(CorrectionOrdersData);
        setFilterOrders(CorrectionOrdersData);
      }

      setSkeleton(false);
    } catch (e) {
      setSkeleton(false);
      setErrorGetData(true);
      toast.error('Ошибка получение данных');
      console.log(e);
    }
  };

  const sendData = async () => {
    if (
      !ordersSelects?.ordersContracts?.value?.value ||
      !ordersSelects?.ordersSpecifications?.value?.value ||
      (ordersSelects?.ordersSpecifications?.value?.value !== 'Новая спецификация' &&
        !ordersSelects?.ordersNomenclatures.value.value) ||
      (ordersSelects?.ordersSpecifications?.value?.value === 'Новая спецификация' && !getOrders?.nomenclature) ||
      (ordersSelects?.ordersSpecifications?.value?.value === 'Новая спецификация' && !getOrders?.volume) ||
      (ordersSelects?.ordersSpecifications?.value?.value === 'Новая спецификация' &&
        !orderData?.ordersNomenclatures?.value) ||
      (ordersSelects?.ordersSpecifications?.value?.value === 'Новая спецификация' && !getOrders?.price) ||
      !ordersSelects?.ordersObjects?.value?.value ||
      !orderData?.contract ||
      !orderData?.phone ||
      !getOrders?.semitrailers.length
    ) {
      setErrorData(prevState => ({
        ...prevState,
        ordersContracts: !ordersSelects?.ordersContracts?.value?.value,
        ordersSpecifications: !ordersSelects?.ordersSpecifications?.value?.value,
        ordersNomenclatures: !ordersSelects?.ordersNomenclatures?.value?.value,
        ordersNomenclaturesUnit: !orderData?.ordersNomenclatures,
        ordersObjects: !ordersSelects?.ordersObjects?.value?.value,
        nomenclatureInput: !getOrders?.nomenclature,
        volume: !getOrders?.volume,
        price: !getOrders?.price,
        mainContactName: !orderData?.contract,
      }));

      setErrorCheckBox(!getOrders?.semitrailers?.length);

      setIsErrorPhone(!orderData?.phone);

      return;
    }

    try {
      const response = await fetchPost(`/orders`, 'POST', {
        contractId: ordersSelects?.ordersContracts?.value?.value,
        newSpecification: ordersSelects?.ordersSpecifications?.value?.value === 'Новая спецификация',
        nomenclatureId: ordersSelects?.ordersNomenclatures?.value?.value,
        nomenclature: getOrders?.nomenclature,
        volume: Number(getOrders?.volume),
        price:
          ordersSelects?.ordersSpecifications?.value?.value === 'Новая спецификация' ? Number(getOrders?.price) : null,
        unit: orderData?.ordersNomenclatures?.value,
        specificationId: ordersSelects?.ordersSpecifications?.value?.value,
        objectId: ordersSelects?.ordersObjects?.value?.value,
        needAttorney: orderData?.TypeOfAcceptance1?.name === 'Доверенность',
        needSeal: orderData?.TypeOfAcceptance1?.name === 'Печать',
        acceptanceScales: orderData?.TypeOfAcceptance2?.name === 'Весы',
        acceptanceVolume: orderData?.TypeOfAcceptance2?.name === 'Объём',
        object: {
          semitrailers: getOrders?.semitrailers,
          time: {
            workingHoursType: orderData?.workingHoursType?.name === 'Круглосуточная' ? 'aroundTheClock' : 'daytime',
            from:
              orderData?.workingHoursType?.name === 'Круглосуточная' ? null : `${orderData?.workingHoursType?.workTo}`,
            to:
              orderData?.workingHoursType?.name === 'Круглосуточная'
                ? null
                : `${orderData?.workingHoursType?.workFrom}`,
          },
          contactPersons: [
            {
              person: orderData?.contract,
              phone: orderData?.phone,
              main: true,
            },
            ...orderData?.orderContacts?.orderContactsAdditionally?.map(contact => ({
              person: contact?.person,
              phone: contact?.phone,
              main: false,
            })),
          ],
        },
      });

      if (response.error) {
        setPopupContentState('error');
        return;
      }

      setPopupContentState('success');
    } catch (e) {
      console.log(e);
    }

    setUpdatePage(!updatePage);
  };

  const changeValueContact = (key, value, input) => {
    setOrderData(prevState => {
      const copy = { ...prevState };
      switch (input) {
        case 'person': {
          copy.orderContacts.orderContactsAdditionally.find((el, index) => index === key).person = value;
          break;
        }

        case 'phone': {
          copy.orderContacts.orderContactsAdditionally.find((el, index) => index === key).phone = value;
          break;
        }
        default: {
          return copy;
        }
      }

      return copy;
    });
  };
  useEffect(() => {
    setDateRange(['', '']);
    setNomenclatureSelects(prevState => ({ ...prevState, value: [] }));
    setObjectSelects(prevState => ({ ...prevState, value: [] }));
  }, [updatePage]);

  const addPerson = counter => {
    setOrderData({
      ...orderData,
      orderContacts: {
        ...orderData?.orderContacts,
        orderContactsAdditionally: [
          ...orderData?.orderContacts?.orderContactsAdditionally,
          {
            person: '',
            phone: '',
            deleted: false,
          },
        ],
      },
    });
  };

  const deleteContact = value => {
    setOrderData(prevState => {
      const copy = { ...prevState };
      copy?.orderContacts?.orderContactsAdditionally?.splice(value, 1);

      return copy;
    });
  };

  const popupContent = {
    default: (
      <>
        <div className={'orders-page-popup-header'}>
          <div className={`orders-page-popup-header__title ${isMobile ? 'H4Medium' : 'H2Medium'}`}>
            Добавление заказа
          </div>
          <div className={'orders-page-popup-header__close'} onClick={() => setPopup(closePopUp)}>
            <CloseSvg />
          </div>
        </div>
        <div className={'orders-page-popup__content'}>
          <div className={'orders-page-popup-box'}>
            <div className={'orders-page-popup-box__title H4Medium'}>
              1. Документы
              <sup>*</sup>
            </div>
            <div className={'orders-page-popup-box__select'}>
              <SelectCustom
                options={ordersSelects.ordersContracts.options}
                maxTagCount={1}
                placeholder={'Договор'}
                mode={null}
                value={!!ordersSelects.ordersContracts.value.value ? ordersSelects.ordersContracts.value : []}
                setValue={value =>
                  setOrdersSelects(prevState => ({
                    ...prevState,
                    ordersContracts: {
                      ...prevState.ordersContracts,
                      value,
                      options: ordersSelects.ordersContracts.options,
                    },
                  }))
                }
                disabled={false}
                status={errorData.ordersContracts ? 'error' : ''}
                errorText={'Поле обязательное для заполнения'}
              />
            </div>
            <div className={'orders-page-popup-box__select'}>
              <SelectCustom
                options={ordersSelects?.ordersSpecifications?.options}
                maxTagCount={1}
                placeholder={'Спецификация'}
                mode={null}
                value={
                  !!ordersSelects?.ordersSpecifications?.value?.value ? ordersSelects?.ordersSpecifications?.value : []
                }
                setValue={value =>
                  setOrdersSelects(prevState => ({
                    ...prevState,
                    ordersSpecifications: {
                      ...prevState?.ordersSpecifications,
                      value,
                      options: ordersSelects?.ordersSpecifications?.options,
                    },
                  }))
                }
                disabled={!ordersSelects.ordersContracts.value.value}
                status={errorData.ordersSpecifications ? 'error' : ''}
                errorText={'Поле обязательное для заполнения'}
              />
            </div>
          </div>
          <div className={'orders-page-popup-box'}>
            <div className={'orders-page-popup-box__title H4Medium'}>
              2. Номенклатура
              <sup>*</sup>
            </div>
            {ordersSelects.ordersSpecifications.value.value === 'Новая спецификация' ? (
              <div className={'orders-page-popup-box__select'}>
                <div className={'statistics-page-popup__input'}>
                  <InputText
                    type={''}
                    disabled={ordersSelects.ordersSpecifications.value.value !== 'Новая спецификация'}
                    placeholder={'Номенклатура'}
                    error={errorData.nomenclatureInput}
                    textError={'Поле обязательное для заполнения'}
                    helpText={''}
                    widthHelp={0}
                    margin={isMobile ? '12px' : '0'}
                    value={getOrders.nomenclature}
                    setValue={value =>
                      setGetOrders(prevState => ({
                        ...prevState,
                        nomenclature: value,
                      }))
                    }
                    isLabel={true}
                  />
                </div>
              </div>
            ) : (
              <div className={'orders-page-popup-box__select'}>
                <SelectCustom
                  options={ordersSelects.ordersNomenclatures.options}
                  maxTagCount={1}
                  placeholder={'Номенклатура'}
                  mode={null}
                  value={
                    !!ordersSelects.ordersNomenclatures.value.value ? ordersSelects.ordersNomenclatures.value.value : []
                  }
                  setValue={value =>
                    setOrdersSelects(prevState => ({
                      ...prevState,
                      ordersNomenclatures: {
                        ...prevState.ordersNomenclatures,
                        value,
                        options: ordersSelects.ordersNomenclatures.options,
                      },
                    }))
                  }
                  disabled={!ordersSelects.ordersSpecifications.value.value}
                  status={errorData.ordersNomenclatures ? 'error' : ''}
                  errorText={'Поле обязательное для заполнения'}
                />
              </div>
            )}
            {ordersSelects.ordersSpecifications.value.value ? (
              <div>
                <div className={'orders-page-popup-box__wrapper'}>
                  <div className={'orders-page-popup-box__input'}>
                    <InputText
                      type={'number'}
                      disabled={
                        ordersSelects.ordersSpecifications.value.value !== 'Новая спецификация' &&
                        !ordersSelects.ordersNomenclatures.value.value
                      }
                      placeholder={'Плановый объем'}
                      error={errorData.volume}
                      textError={'Поле обязательное для заполнения'}
                      helpText={'Введите объем заказа. Например: “10 000”'}
                      widthHelp={0}
                      margin={isMobile ? '12px' : '0'}
                      value={getOrders.volume}
                      setValue={value =>
                        setGetOrders(prevState => ({
                          ...prevState,
                          volume: value,
                        }))
                      }
                      isLabel={true}
                    />
                  </div>
                  <div className={'orders-page-popup-box__select'}>
                    <SelectCustom
                      options={[
                        { value: 'м³', label: 'м³' },
                        { value: 'тонн', label: 'ТН' },
                      ]}
                      maxTagCount={1}
                      placeholder={'Ед. измерения'}
                      mode={null}
                      value={!!orderData.ordersNomenclatures ? orderData.ordersNomenclatures : []}
                      setValue={value =>
                        setOrderData(prevState => ({
                          ...prevState,
                          ordersNomenclatures: value,
                        }))
                      }
                      disabled={ordersSelects.ordersSpecifications.value.value !== 'Новая спецификация'}
                      status={errorData.ordersNomenclaturesUnit ? 'error' : ''}
                      errorText={'Поле обязательное для заполнения'}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {ordersSelects.ordersSpecifications.value.value === 'Новая спецификация' ? (
              <div>
                <div className={'statistics-page-popup__input'}>
                  <InputText
                    type={'number'}
                    disabled={false}
                    placeholder={'Проходная цена '}
                    error={errorData.price}
                    textError={'Поле обязательное для заполнения'}
                    helpText={''}
                    widthHelp={0}
                    margin={isMobile ? '12px' : '0'}
                    value={getOrders.price}
                    setValue={value =>
                      setGetOrders(prevState => ({
                        ...prevState,
                        price: value,
                      }))
                    }
                    isLabel={true}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className={'orders-page-popup-box'}>
            <div className={'orders-page-popup-box__title H4Medium'}>
              3. Объект
              <sup>*</sup>
            </div>
            <div className={'orders-page-popup-box__select'}>
              <SelectCustom
                options={ordersSelects.ordersObjects.options}
                maxTagCount={1}
                placeholder={'Объект'}
                mode={null}
                value={!!ordersSelects.ordersObjects.value.value ? ordersSelects.ordersObjects.value : []}
                setValue={value =>
                  setOrdersSelects(prevState => ({
                    ...prevState,
                    ordersObjects: {
                      ...prevState.ordersObjects,
                      value,
                      options: ordersSelects.ordersObjects.options,
                    },
                  }))
                }
                disabled={false}
                status={errorData.ordersObjects ? 'error' : ''}
                errorText={'Поле обязательное для заполнения'}
              />
            </div>
          </div>
          {isObject ? (
            <>
              <div className={'orders-page-popup-box'}>
                <div className={'orders-page-popup-box__title H4Medium'}>
                  4. Вид приемки
                  <sup>*</sup>
                </div>
                <div className={'orders-page-popup-box__box-radio'}>
                  <div className={'orders-page-popup-box__radio'}>
                    <RadioBoxCircle
                      check={orderData.TypeOfAcceptance1.name === 'Доверенность'}
                      onInput={value =>
                        setOrderData({
                          ...orderData,
                          TypeOfAcceptance1: {
                            ...orderData.TypeOfAcceptance1,
                            name: value.target.value,
                          },
                        })
                      }
                      name={'TypeOfAcceptance1'}
                      id={'needAttorney'}
                      value={'Доверенность'}
                    />
                  </div>
                  <div className={'orders-page-popup-box__radio'}>
                    <RadioBoxCircle
                      check={orderData.TypeOfAcceptance1.name === 'Печать'}
                      onInput={value =>
                        setOrderData({
                          ...orderData,
                          TypeOfAcceptance1: {
                            ...orderData.TypeOfAcceptance1,
                            name: value.target.value,
                          },
                        })
                      }
                      name={'TypeOfAcceptance1'}
                      id={'needSeal'}
                      value={'Печать'}
                    />
                  </div>
                </div>
                <div className={'orders-page-popup-box__box-radio'}>
                  <div className={'orders-page-popup-box__radio'}>
                    <RadioBoxCircle
                      check={orderData.TypeOfAcceptance2.name === 'Весы'}
                      onInput={value =>
                        setOrderData({
                          ...orderData,
                          TypeOfAcceptance2: {
                            ...orderData.TypeOfAcceptance2,
                            name: value.target.value,
                          },
                        })
                      }
                      name={'TypeOfAcceptance2'}
                      id={'3'}
                      value={'Весы'}
                    />
                  </div>
                  <div className={'orders-page-popup-box__radio'}>
                    <RadioBoxCircle
                      check={orderData.TypeOfAcceptance2.name === 'Объём'}
                      onInput={value =>
                        setOrderData({
                          ...orderData,
                          TypeOfAcceptance2: {
                            ...orderData.TypeOfAcceptance2,
                            name: value.target.value,
                          },
                        })
                      }
                      name={'TypeOfAcceptance2'}
                      id={'4'}
                      value={'Объём'}
                    />
                  </div>
                </div>
              </div>
              <div className={'orders-page-popup-box'}>
                <div className={'orders-page-popup-box__title H4Medium'}>
                  5. Вид ТС
                  <sup>*</sup>
                </div>
                {orderData.typeTC.map(item => (
                  <div key={item.id} className={'orders-page-popup-box__check-box'}>
                    <CheckBoxCustom
                      children={item.title}
                      error={errorCheckBox}
                      check={item.active}
                      pick={pickCheck}
                      name={'typeTC'}
                      id={item.id}
                    />
                  </div>
                ))}
              </div>
              <div className={'orders-page-popup-box'}>
                <div className={'orders-page-popup-box__title H4Medium'}>
                  6. График приемки
                  <sup>*</sup>
                </div>
                <div>
                  <div className={'orders-page-popup-box__radio'}>
                    <RadioBoxCircle
                      check={orderData.workingHoursType.name === 'Круглосуточная'}
                      onInput={value =>
                        setOrderData({
                          ...orderData,
                          workingHoursType: { ...orderData.workingHoursType, name: value.target.value },
                        })
                      }
                      name={'TypeOfAcceptance1'}
                      id={'5'}
                      value={'Круглосуточная'}
                    />
                  </div>
                  <div className={'orders-page-popup-box__radio'}>
                    <RadioBoxCircle
                      check={orderData.workingHoursType.name === 'Дневная'}
                      onInput={value =>
                        setOrderData({
                          ...orderData,
                          workingHoursType: { ...orderData.workingHoursType, name: value.target.value },
                        })
                      }
                      name={'TypeOfAcceptance1'}
                      id={'6'}
                      value={'Дневная'}
                    />
                  </div>
                </div>
                <div className={'orders-page-popup-box__box-time'}>
                  <div className={'orders-page-popup-box__time'}>
                    <TimePicker
                      placeholder={'Период времени'}
                      format={'HH:mm'}
                      needConfirm={false}
                      open={openLeftTimer}
                      onClick={() => setOpenLeftTimer(true)}
                      renderExtraFooter={() => customTimePickerButton(true)}
                      onChange={(time, timeString) => changeTime(time, timeString, 'workTo', 'workTimeTo')}
                      disabled={orderData.workingHoursType.name === 'Круглосуточная'}
                      defaultValue={orderData.workingHoursType.defaultWorkTo}
                    />
                  </div>
                  <div className={'orders-page-popup-box__or'}>до</div>
                  <div className={'orders-page-popup-box__time'}>
                    <TimePicker
                      needConfirm={false}
                      placeholder={'Период времени'}
                      format={'HH:mm'}
                      open={openRightTimer}
                      onClick={() => setOpenRightTimer(true)}
                      renderExtraFooter={() => customTimePickerButton(false)}
                      onChange={(time, timeString) => changeTime(time, timeString, 'workFrom', 'workTimeFrom')}
                      disabled={orderData.workingHoursType.name === 'Круглосуточная'}
                      status={
                        orderData.workingHoursType.workFrom.split(':')[0] -
                          orderData.workingHoursType.workTo.split(':')[0] <
                        4
                          ? 'error'
                          : ''
                      }
                      defaultValue={orderData.workingHoursType.defaultWorkFrom}
                    />
                  </div>
                </div>
              </div>
              <div className={'orders-page-popup-box'}>
                <div className={'orders-page-popup-box__title H4Medium'}>
                  7. Контакты ответственного на объекте
                  <sup>*</sup>
                </div>
                <div className={'orders-page-popup-box__input'}>
                  <InputText
                    type={''}
                    secondError={false}
                    disabled={false}
                    isLabel={true}
                    value={orderData.contract}
                    setValue={value => setOrderData({ ...orderData, contract: value })}
                    margin="0"
                    fill={true}
                    error={false}
                    helpText={''}
                    widthHelp={0}
                    textError={'Поле обязательное для заполнения'}
                    placeholder={'ФИО'}
                  />
                </div>
                <div className={'orders-page-popup-box__input'}>
                  <MyPhoneInput
                    disabled={false}
                    placeholder="Номер телефона"
                    setIsError={null}
                    isError={false}
                    isActive={true}
                    setIsActive={null}
                    value={orderData.phone}
                    onChange={value => setOrderData({ ...orderData, phone: value })}
                    isFunction={null}
                    btnHelp={false}
                  />
                </div>
                {orderData.orderContacts.orderContactsAdditionally.length ? (
                  <div>
                    {orderData.orderContacts.orderContactsAdditionally.map((el, index) => (
                      <div key={index}>
                        <div className={'objectPage-content__delete'}>
                          <div
                            className={'objectPage-content__delete-title'}
                          >{`Дополнительный контакт № ${index + 1}`}</div>
                          {!isMobile && (
                            <ButtonSimple text={'Удалить'} svg={<StopSvg />} onClick={() => deleteContact(index)} />
                          )}
                        </div>
                        <div className={'objectsPage-popup-box__input'}>
                          <InputText
                            type={''}
                            disabled={false}
                            isLabel={true}
                            value={orderData.orderContacts?.orderContactsAdditionally[index].person}
                            setValue={value => changeValueContact(index, value, 'person')}
                            margin="0"
                            error={false}
                            helpText={''}
                            widthHelp={0}
                            textError={'Поле обязательное для заполнения'}
                            placeholder={'ФИО'}
                          />
                        </div>
                        <div className={'objectsPage-popup-box__input'}>
                          <MyPhoneInput
                            disabled={false}
                            placeholder="Номер телефона"
                            setIsError={setIsErrorPhone}
                            isError={isErrorPhone}
                            isActive={isActive}
                            setIsActive={setIsActive}
                            value={
                              !!orderData.orderContacts?.orderContactsAdditionally[index]?.phone
                                ? orderData.orderContacts?.orderContactsAdditionally[index].phone.replace('+', '')
                                : ''
                            }
                            onChange={value => changeValueContact(index, value, 'phone')}
                            isFunction={null}
                            btnHelp={false}
                          />
                        </div>
                        {isMobile && (
                          <ButtonSimple text={'Удалить'} svg={<StopSvg />} onClick={() => deleteContact(index)} />
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <></>
                )}
                <div className={'orders-page-popup-box__add-button'} style={{ marginTop: '24px' }}>
                  <ButtonFilled
                    onClick={() =>
                      addPerson(Object.entries(orderData.orderContacts.orderContactsAdditionally).length + 1)
                    }
                    text={'Добавить контакт'}
                    svg={<CirclePlusSvg />}
                    widthSvg={'14px'}
                    width={'220px'}
                  />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <div>
            <ButtonFilled margin={'40px 0 0 0'} text={'Сохранить'} onClick={sendData} width={'100%'} />
          </div>
        </div>
      </>
    ),
    success: (
      <div className={'orders-page-popup-success'}>
        <div className={'orders-page-popup-success__wrapper'}>
          <div className={'orders-page-popup-success__svg'}>
            <SuccessGreenSvg />
          </div>
          <div
            className={`orders-page-popup-success__title ${isMobile ? 'textH6 textH6_medium' : 'textH4 textH4_medium'}`}
          >
            Заказ успешно добавлен
          </div>
          <div className={`orders-page-popup-success__sub-title ${isMobile ? 'textMedium' : 'textLarge'}`}>
            Он появится в общем списке заказов
          </div>
        </div>
        <div className={'orders-page-popup-success__button'}>
          <ButtonNotFilled
            color={'var(--color-green600)'}
            width={'100%'}
            onClick={successSend}
            margin={'40px 0 0 0'}
            text={'Хорошо'}
          />
        </div>
      </div>
    ),
    error: (
      <div className={'orders-page-popup-error'}>
        <div className={'orders-page-popup-error__wrapper'}>
          <div className={'orders-page-popup-error__svg'}>
            <CloseCircleRedSvg />
          </div>
          <div
            className={`orders-page-popup-error__title ${isMobile ? 'textH6 textH6_medium' : 'textH4 textH4_medium'}`}
          >
            Произошла ошибка
          </div>
          <div className={`orders-page-popup-error__sub-title ${isMobile ? 'textMedium' : 'textLarge'}`}>
            Заказ не был добавлен. Попробуйте ещё раз.
          </div>
        </div>
        <div className={'orders-page-popup-error__button'}>
          <ButtonNotFilled
            color={'red'}
            width={'100%'}
            onClick={() => setPopupContentState('default')}
            text={'Повтотрить'}
            margin={'40px 0 0 0'}
          />
        </div>
      </div>
    ),
  };

  const currentObject = useCallback(async () => {
    let mainContacts;

    const orderContactsAdditionally: {
      person: string;
      phone: string;
      deleted: boolean;
    }[] = [];

    try {
      const response = await fetchGet(`/objects/${ordersSelects.ordersObjects.value.value}`, {});

      if (!response.response) {
        return;
      }

      response.response.semitrailers.forEach(({ title }) => {
        pickCheck(title);
      });

      response.response.contactPersons.forEach(contact => {
        if (!contact.contact_persons_communications.main) {
          orderContactsAdditionally.push({
            person: contact.person,
            phone: contact.phone,
            deleted: false,
          });

          setOrderData(prevState => ({
            ...prevState,
            orderContacts: {
              ...prevState.orderContacts,
              orderContactsAdditionally,
            },
          }));
          return;
        }

        mainContacts = {
          contract: contact.person,
          phone: contact.phone,
        };
      });

      setOrderData(prevState => ({
        ...prevState,
        TypeOfAcceptance1: {
          ...prevState.TypeOfAcceptance1,
          name: response.response.needAttorney ? 'Доверенность' : 'Печать',
        },
        TypeOfAcceptance2: {
          ...prevState.TypeOfAcceptance2,
          name: response.response.acceptanceScales ? 'Весы' : 'Объём',
        },
        workingHoursType: {
          ...prevState.workingHoursType,
          name: response.response?.workSchedulesGraph?.aroundTheClock ? 'Круглосуточная' : 'Дневная',
          workTo: response.response?.workSchedulesGraph?.from,
          workFrom: response.response?.workSchedulesGraph?.to,
          defaultWorkTo: dayjs(response.response?.workSchedulesGraph?.from, 'HH:mm'),
          defaultWorkFrom: dayjs(response.response?.workSchedulesGraph?.to, 'HH:mm'),
        },
        typeTC: orderData.typeTC.map(item => {
          const semitrailerTitles = response.response.semitrailers.map(semitrailer => semitrailer.title);

          if (semitrailerTitles.includes(item.id) || semitrailerTitles.includes('everyone')) {
            return {
              ...item,
              active: true,
            };
          }

          if (semitrailerTitles.length === 4) {
            return {
              ...item,
              id: 'everyone',
              active: true,
              title: 'Проходят все',
            };
          }

          return {
            ...item,
          };
        }),
        contract: mainContacts?.contract,
        phone: mainContacts?.phone,
      }));

      setIsObject(true);
    } catch (e) {
      console.log(e);
    }
  }, [ordersSelects.ordersObjects.value.value]);

  useEffect(() => {
    getData().catch(console.error);
  }, [updatePage]);

  useEffect(() => {
    getData().catch(console.error);
  }, [dateRange, objectSelects.value, nomenclatureSelects.value]);

  useEffect(() => {
    if (!nomenclatureSelects.value?.length) {
      setFilterOrders(orders);
      return;
    }

    const newOrdersList = [];

    nomenclatureSelects.value.forEach(item => {
      const filterNomenclaturesData = orders.filter(unit => unit.item['Номенклатура'].title['up'] === item.value);

      if (!filterNomenclaturesData.length) {
        return;
      }

      newOrdersList.push(...filterNomenclaturesData);
    });

    setFilterOrders(newOrdersList);
  }, [nomenclatureSelects.value]);

  useEffect(() => {
    if (!objectSelects.value?.length) {
      setFilterOrders(orders);
      return;
    }

    const newObjectsList = [];

    objectSelects.value.forEach(item => {
      const filterObjectsData = orders.filter(unit => unit.item['Контрагент'].title['up'] === item.value);

      if (!filterObjectsData.length) {
        return;
      }

      newObjectsList.push(...filterObjectsData);
    });

    setFilterOrders(newObjectsList);
  }, [objectSelects.value]);

  useEffect(() => {
    const specificationsSelect = [];
    if (data?.length) {
      const specifications = data?.find(({ id }) => id === ordersSelects?.ordersContracts?.value?.value);

      setSpecification(specifications);

      if (specifications) {
        specifications?.specifications?.forEach(specification => {
          if (specificationsSelect?.find(({ label }) => label === specification?.name)) {
            return;
          }

          specificationsSelect?.push({ value: specification?.id, label: specification?.name });
        });

        setOrdersSelects(prevState => ({
          ...prevState,
          ordersSpecifications: { ...prevState.ordersSpecifications, options: specificationsSelect },
        }));
      }
    }

    setOrdersSelects(prevState => ({
      ...prevState,
      ordersSpecifications: { options: specificationsSelect, value: { value: 0, label: '' } },

      ordersNomenclatures: { options: [], value: { value: 0, label: '' } },
    }));
  }, [ordersSelects.ordersContracts]);

  useEffect(() => {
    const selectedSpecValue = ordersSelects?.ordersSpecifications?.value?.value;
    if (specification?.specifications?.length && selectedSpecValue !== 'Новая спецификация') {
      const nomenclatures = specification?.specifications?.find(({ id }) => id === selectedSpecValue);

      setOrdersSelects(prevState => ({
        ...prevState,
        ordersNomenclatures: {
          options: [],
          value: {
            value: 0,
            label: '',
          },
        },
      }));

      if (nomenclatures?.nomenclatureSpecification) {
        const nomenclatureValue = nomenclatures?.nomenclatureSpecification?.nomenclatureId;
        const nomenclatureLabel = nomenclatures?.nomenclatureSpecification?.content;

        setOrdersSelects(prevState => ({
          ...prevState,
          ordersNomenclatures: {
            ...prevState.ordersNomenclatures,
            options: [{ value: nomenclatureValue, label: nomenclatureLabel }],
          },
        }));
      }
    }
  }, [ordersSelects?.ordersSpecifications, specification]);

  useEffect(() => {
    const isNewSpecification = ordersSelects?.ordersSpecifications?.value?.value === 'Новая спецификация';
    const nomenclatureValue = ordersSelects?.ordersNomenclatures?.value?.value;

    if (!isNewSpecification && specification?.specifications?.length && nomenclatureValue) {
      const selectedSpecification = specification?.specifications.find(
        ({ id }) => id === ordersSelects?.ordersSpecifications.value.value,
      );

      if (selectedSpecification && selectedSpecification?.nomenclatureSpecification) {
        setOrderData(prevState => ({
          ...prevState,
          ordersNomenclatures: {
            value: selectedSpecification?.nomenclatureSpecification.unit,
            label: selectedSpecification?.nomenclatureSpecification.unit === 'м³' ? 'м³' : 'ТН',
          },
        }));
      }
    }
  }, [ordersSelects.ordersSpecifications, ordersSelects.ordersNomenclatures, specification]);

  useEffect(() => {
    if (!ordersSelects?.ordersObjects?.value?.value) {
      return;
    }

    currentObject().catch(console.error);
  }, [ordersSelects?.ordersObjects?.value?.value]);

  return (
    <PageWrapper style={{ zIndex: popup ? '10000' : '1000' }}>
      <div className={'orders-page'}>
        <div className={styles.titleAndSubTitleContainer}>
          <div className={styles.titleAndAddButton}>
            <TitleMain title={'Заказы'} />
            <ButtonFilled
              sizeText={'small'}
              svg={<NewPlusSvg />}
              width={isMobile ? '32px' : '165px'}
              height={isMobile ? '32px' : '40px'}
              onClick={() => setPopup(openPopUp)}
              text={isMobile ? '' : 'Добавить заказ'}
            />
          </div>
          <SubTitleMain subTitle={'Просматривайте ваши заказы и отслеживайте их статус в реальном времени'} />
        </div>
        <div className={'orders-page__tabs'}>
          <Tabs tabs={tabs} setTabs={setTabs} updatePage={updatePage} setUpdatePage={setUpdatePage} />
        </div>
        <div className={'orders-page__selects'}>
          <SelectBox gridTemplateColumns={isMobile ? '1fr' : '300px 300px 300px'}>
            <CustomRangePicker
              width={isMobile ? '100%' : '300px'}
              isPresets={true}
              open={openCalendar}
              dateRange={dateRange}
              setDateRange={setDateRange}
              setOpen={setOpenCalendar}
            />
            <SelectCustom
              options={nomenclatureSelects.options}
              maxTagCount={2}
              placeholder={'По номенклатуре'}
              mode={'tags'}
              value={nomenclatureSelects.value ? nomenclatureSelects.value : []}
              setValue={value => setNomenclatureSelects(prevState => ({ ...prevState, value }))}
              disabled={false}
              status={''}
              errorText={''}
            />
            <SelectCustom
              options={objectSelects.options}
              maxTagCount={1}
              placeholder={'По объектам'}
              mode={'tags'}
              value={objectSelects.value ? objectSelects.value : []}
              setValue={value => setObjectSelects(prevState => ({ ...prevState, value }))}
              disabled={false}
              status={''}
              errorText={''}
            />
          </SelectBox>
        </div>
        <div className={'orders-page-content'}>
          {tabsData[decodeURIComponent(window.location.hash).split('#')[1]]?.content?.props?.items?.length ? (
            skeleton ? (
              <div className="FlightSection__content">
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '485px ',
                  }}
                >
                  <div>
                    <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
                  </div>
                </div>
              </div>
            ) : (
              statusesCall()
            )
          ) : skeleton ? (
            <div className="FlightSection__content">
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '485px ',
                }}
              >
                <div>
                  <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
                </div>
              </div>
            </div>
          ) : (
            <EmptyList
              title={errorGetData ? 'Ошибка загрузки данных' : 'Результатов не найдено.'}
              subTitle={
                errorGetData
                  ? 'Мы не смогли загрузить данные таблицы. Попробуйте перезагрузить страницу или свяжитесь со службой поддержки.'
                  : 'Нет заказов по выбранному периоду времени. Пожалуйста, попробуйте другой период.'
              }
              svg={errorGetData ? <img width={300} src={imgError} alt="" /> : <img width={300} src={img} alt="" />}
            />
          )}
        </div>
        {orders?.length > more && (
          <ButtonNotFilled
            margin={'28px 0 0 0'}
            onClick={() => setMore(isMobile ? more + 5 : more + 10)}
            text={'Показать ещё'}
            width={'100%'}
            color={'var(--color-orange400)'}
          />
        )}
      </div>
      <AsidePopup open={popup} close={setPopup}>
        <div className={'orders-page-popup'}>{popupContent[popupContentState]}</div>
      </AsidePopup>
    </PageWrapper>
  );
};
