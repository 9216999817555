import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../../../../../components/PageWrapper';
import styles from './SalaryHistoryPage.module.scss';
import { PageTitle } from '../../../../../components/PageTitle';
import { SubTitle } from '../../../../../components/SubTitle';
import { fetchGet } from '../../../../../common/proxy-1C-fetch-auth';
import { formPrice } from '../../../../../common/form-price.helper';
import ArrowLeftOldSvg from 'src/components/UI/svg-icon/ArrowLeftOldSvg';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { ISalary } from '../../tipe';
import TitleAndSubTitleMain from '../../../../../components/UI/TitleAndSubtitle/TitleAndSubTitleMain';

const SalaryHistoryPage = () => {
  const [monthsSalary, setMonthsSalary] = useState<any>();
  const [weeksSalary, setWeeksSalary] = useState<any>();
  const [todaySalary, setTodaySalary] = useState<ISalary>();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();
  const getMonthsSalary = async () => {
    try {
      const response = await fetchGet('/salary/details/months', {});
      await setMonthsSalary(response?.response);
    } catch (e) {
      console.log(e);
    }
  };
  const getSalary = async () => {
    try {
      const response = await fetchGet('/salary', {});
      await setTodaySalary(response?.response);
    } catch (e) {
      console.log(e);
    }
  };
  const getWeeksSalary = async () => {
    try {
      const response = await fetchGet('/salary/details/weeks', {});
      await setWeeksSalary(response?.response);
    } catch (e) {
      console.log(e);
    }
  };
  const getPercent = () => {
    return Math.round((todaySalary?.salaryFromMonthStart?.driversSalary / monthsSalary?.[1]?.driversSalary) * 100);
  };
  useEffect(() => {
    getMonthsSalary().catch(e => console.log(e));
    getSalary().catch(e => console.log(e));
    getWeeksSalary().catch(e => console.log(e));
  }, []);
  return (
    <div>
      <PageWrapper>
        <div>
          <div
            className={isMobile ? styles.mobileBtnBackArrowContainer : styles.btnBackArrowContainer}
            onClick={() => navigate('/main')}
          >
            <div className={styles.btnBackArrowContent}>
              <ArrowLeftOldSvg />
              <p className={styles.btnBackArrowText}>На главную</p>
            </div>
          </div>
          <TitleAndSubTitleMain
            title={'История заработной платы'}
            subTitle={'Просматривайте полную историю ваших доходов'}
          />
        </div>
        <div className={styles.container}>
          <div className={styles.contentContainer}>
            <div className={styles.percentСontainer}>
              <svg width="115" height="116" viewBox="0 0 115 116" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Group 2609477">
                  <path
                    id="Vector"
                    opacity="0.5"
                    d="M24.7841 13.3682C27.3937 11.6245 30.1338 10.1122 32.9729 8.84072C41.026 5.23428 45.0525 3.43106 50.4092 6.90149C55.7659 10.3719 55.7659 16.0647 55.7659 27.4504V36.2555C55.7659 47.3243 55.7659 52.8586 59.2046 56.2973C62.6432 59.7359 68.1776 59.7359 79.2463 59.7359H88.0515C99.4371 59.7359 105.13 59.7359 108.6 65.0926C112.071 70.4493 110.268 74.4759 106.661 82.5289C105.39 85.368 103.877 88.1081 102.134 90.7178C96.006 99.8884 87.2965 107.036 77.1066 111.257C66.9167 115.478 55.7041 116.582 44.8865 114.43C34.069 112.279 24.1325 106.967 16.3335 99.1683C8.53448 91.3693 3.22329 81.4328 1.07155 70.6153C-1.08019 59.7978 0.0241617 48.5851 4.24495 38.3952C8.46574 28.2053 15.6134 19.4959 24.7841 13.3682Z"
                    fill="var(--color-blue300)"
                  />
                  <path
                    id="Vector_2"
                    d="M114.15 27.8536C109.385 15.7572 99.7429 6.11539 87.6465 1.35C78.5974 -2.21486 70.4412 5.98256 70.4412 15.7085V39.1888C70.4412 42.4308 73.0693 45.0589 76.3113 45.0589H99.7916C109.518 45.0589 117.715 36.9027 114.15 27.8536Z"
                    fill="var(--color-blue300)"
                  />
                </g>
              </svg>
              <div className={styles.percent}>{isNaN(getPercent()) ? 0 : getPercent()}%</div>
            </div>
            <div className={styles.textContainer}>
              <p className={styles.ContentBlockTitle}>За прошедший месяц</p>
              <p>{formPrice(monthsSalary?.[1]?.driversSalary || 0, 'RUB')}</p>
            </div>
          </div>
          <div className={styles.weekContainer}>
            <p>По неделям</p>
            <div className={styles.headersWeekContainer}>
              <p className={styles.ContentBlockTitle}>Период</p>
              <p className={styles.ContentBlockTitle}>Сумма</p>
            </div>
            {weeksSalary?.map(item => (
              <div className={styles.headersWeekContainer}>
                <p>{item?.title}</p>
                <p>{Math.round(item?.driversSalary)}</p>
              </div>
            ))}
          </div>

          <div className={styles.weekContainer}>
            <p>По месяцам</p>
            <table className={styles.tableWeek}>
              <thead>
                <tr>
                  <th className={styles.ContentBlockTitleColumn} style={{ textAlign: 'left' }}>
                    Месяц
                  </th>
                  <th className={styles.ContentBlockTitleColumn}>Кол-во рейсов</th>
                  <th className={styles.ContentBlockTitleColumn} style={{ textAlign: 'right' }}>
                    Сумма
                  </th>
                </tr>
              </thead>
              <tbody>
                {monthsSalary?.map(item => (
                  <tr>
                    <td className={styles.tableItem} style={{ textAlign: 'left' }}>
                      {item?.month || '0'}
                    </td>
                    <td className={styles.tableItem}>{item?.flightsQuantity || '0'}</td>
                    <td className={styles.tableItem} style={{ textAlign: 'right' }}>
                      {formPrice(Math.round(item?.driversSalary) || '0', 'RUB')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/*<p>По месяцам</p>
            <div className={styles.headersWeekContainer}>
              <p className={styles.ContentBlockTitle}>Месяц</p>
              <p className={styles.ContentBlockTitle}>Кол-во рейсов</p>
              <p className={styles.ContentBlockTitle}>Сумма</p>
            </div>
            {monthsSalary?.map(item => (
              <div className={styles.ContentContainerTextTable}>
                <p className={styles.monthText}>{item?.month || '0'}</p>
                <p className={styles.flightsQuantity}>{item?.flightsQuantity || '0'}</p>
                <p className={styles.driversSalary}>{formPrice(item?.driversSalary || '0','RUB')}</p>
              </div>
            ))}*/}
          </div>
        </div>
      </PageWrapper>
    </div>
  );
};

export default SalaryHistoryPage;
