import React from 'react';
import { Link } from 'react-router-dom';
import { StatusItem } from './UI/StatusItem';
import { RequestItemCarrier } from './RequestItemCarrier';
import { FlightListProps } from '../common/flight-list-props.type';
import { sortAndFilterListHelper } from '../common/sort-and-filter-list.helper';
import { flightSearchMechanism } from '../common/flight-search-mechanism.function';
import { openPopUp } from '../common/use-popup.functiopn';
import { fetchGet } from '../common/proxy-1C-fetch-auth';

export const CarrierFlightList = ({
  flightItems,
  more,
  setMore,
  statusTabs,
  filter,
  setPopUpActive,
  setFlightInfo,
  getFiles,
  statusName,
}: FlightListProps) => {
  const orderStrategyDate = {
    // @ts-ignore
    ASC: (a: string, b: string) => new Date(a) - new Date(b),
    // @ts-ignore
    DESC: (a: string, b: string) => new Date(b) - new Date(a),
  };
  const sortedList = sortAndFilterListHelper(
    statusTabs,
    'status',
    flightItems,
    filter,
    flightSearchMechanism,
    orderStrategyDate,
  );

  const flightInfo = async id => {
    const flights = await fetchGet(`/flights/${id}`, {});
    setFlightInfo(flights.response);
    localStorage.setItem('flightId', id);
    getFiles(id);
    openPopUp(setPopUpActive);
  };
  return (
    <div>
      {!flightItems.length ? (
        <div className="EmptyFlightList__wrapper">
          <div className="EmptyFlightList__content">
            <div className="EmptyFlightList__svg">
              <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9 13H11V15H9V13ZM9 5H11V11H9V5ZM10 0C4.47 0 0 4.5 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0M10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18V18Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <div className="EmptyFlightList__title">Рейсы отсутствуют</div>
            <div className="EmptyFlightList__subTitle">
              В данный момент у вас нет {statusTabs.Активные.active ? 'активных' : 'выполненных'} рейсов
            </div>
          </div>
        </div>
      ) : (
        flightItems?.map((item: any) =>
          item.validationStatus === 'Отклонен' ? (
            <RequestItemCarrier
              numberTn={item?.numberTn}
              carrierPrice={item?.carrierPrice}
              key={item?.flightName?.trim()}
              onClick={() => flightInfo(item?.id)}
              number={item?.flightName?.trim() || ''}
              date={
                statusName === 'completed'
                  ? `${new Date(item?.dateTn)?.toLocaleDateString()}`
                  : `${new Date(item?.date)?.toLocaleDateString()}`
              }
              nameLoading={item?.supplier?.title || ''}
              addressLoading={item?.quarry?.address?.address || ''}
              nameUnloading={item?.client?.title || ''}
              addressUnloading={item?.object?.address?.address || ''}
              carBrand={item.car?.carBrand?.brand || ''}
              carNumber={item.car?.stateNumber || ''}
              driverName={item.driver?.fullName || ''}
              volume={statusName === 'completed' ? item?.volumeDownload || 'Нет данных' : item?.volumePlan}
              volumeUnloading={statusName === 'completed' ? item?.unloadingVolume || 'Нет данных' : null}
              measurement={
                statusName === 'completed'
                  ? item?.supplierSpecification?.nomenclatureSpecification?.nomenclature?.unit
                  : item?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.unit
              }
              measurementUnloading={
                statusName === 'completed'
                  ? item?.buyerSpecification.nomenclatureSpecification?.nomenclature?.unit
                  : null
              }
              cargo={item?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.nameByDocument || ''}
              disabled={item?.status || ''}
              isErrorVolume={
                statusName === 'completed'
                  ? item?.volumeDownload === item?.unloadingVolume
                    ? 'requestItem__green'
                    : 'requestItem__red'
                  : ''
              }
              statusTab={item.status}
              status={<StatusItem status={item.validationStatus} />}
            />
          ) : (
            <Link key={item?.id} to={`/flights/${item?.id}`} onClick={() => localStorage.setItem('flightId', item?.id)}>
              <RequestItemCarrier
                numberTn={item?.numberTn}
                carrierPrice={item?.carrierPrice}
                number={item?.flightName?.trim() || ''}
                date={
                  statusName === 'completed'
                    ? `${new Date(item?.dateTn)?.toLocaleDateString()}`
                    : `${new Date(item?.date)?.toLocaleDateString()}`
                }
                nameLoading={item?.supplier?.title || ''}
                addressLoading={item?.quarry?.address?.address || ''}
                nameUnloading={item?.client?.title || ''}
                addressUnloading={item?.object?.address?.address || ''}
                carBrand={item.car?.carBrand?.brand || ''}
                carNumber={item.car?.stateNumber || ''}
                driverName={item?.driver?.fullName || ''}
                volume={statusName === 'completed' ? item?.volumeDownload || 'Нет данных' : item?.numberPlan}
                volumeUnloading={statusName === 'completed' ? item?.unloadingVolume || 'Нет данных' : null}
                measurement={
                  statusName === 'completed'
                    ? item?.supplierSpecification?.nomenclatureSpecification?.nomenclature?.unit
                    : item?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.unit
                }
                measurementUnloading={
                  statusName === 'completed'
                    ? item?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.unit
                    : null
                }
                cargo={item?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.nameByDocument || ''}
                disabled={item?.status || ''}
                isErrorVolume={
                  statusName === 'completed'
                    ? item?.volumeDownload === item?.unloadingVolume
                      ? 'requestItem__green'
                      : 'requestItem__red'
                    : ''
                }
                statusTab={item.status}
                status={
                  <StatusItem
                    status={
                      statusName === 'active'
                        ? item?.status
                        : item?.validationStatus === 'Повторная проверка'
                          ? 'На проверке'
                          : item?.validationStatus
                    }
                  />
                }
              />
            </Link>
          ),
        )
      )}
    </div>
  );
};
