import React, { useEffect, useRef, useState } from 'react';
import styles from './ShowcasePage.module.scss';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageWrapper } from 'src/components/PageWrapper';
import { toast } from 'react-toastify';
import { CreateEditButton } from '../components/CreateEditButton';
import { ShowcaseCover } from '../components/ShowcaseCover/ShowcaseCover';
import { ShowcaseDescription } from '../components/ShowcaseDescription/ShowcaseDescription';
import { ShowcaseMainInfo } from '../components/ShowcaseMainInfo/ShowcaseMainInfo';
import { MaterialCatalog } from '../components/MaterialCatalog/MaterialCatalog';
import { DaDataAddress, DaDataSuggestion } from 'react-dadata';
import { fetchGet, fetchPost } from 'src/common/proxy-1C-fetch-auth';
import { Basket } from 'src/components/UI/svg-icon/Basket';
import { SUBSERVER } from 'src/common/sub-server.constant';
import { ShowcaseDescriptionView } from '../components/ShowcaseDescription/ShowcaseDescriptionView';
import { DeleteShowcasePopup } from './DeleteShowcasePopup/DeleteShowcasePopup';
import ButtonNotFilled from 'src/components/newUI/ButtonNotFilled/ButtonNotFilled';
import { clearObject } from 'src/common/ClearObject.helper';
import { PublicShowcaseHeader } from './PublicShowcaseHeader/PublicShowcaseHeader';
import { CustomSelect } from 'src/components/UI/select/CustomSelect';
import { ShowcaseDescriptionButtons } from '../components/ShowcaseDescription/ShowcaseDescriptionButtons/ShowcaseDescriptionButtons';
import useClickOutside from 'src/CustomHooks/useClickOutside';
import { IMaterials } from '../type';
import { FavoriteSvg } from 'src/components/UI/svg-icon/FavoriteSvg';
import { FavoriteShowcasesSvg } from 'src/components/UI/svg-icon/FavoriteShowcasesSvg';
import { quarryData } from 'src/common/quarry-type';
import { toastError } from 'src/common/toastError.helper';

export const ShowcasePage = () => {
  const location = useLocation();
  const isShowcasePath = !location.pathname.startsWith('/store');
  const isCatalogPath = location.pathname.startsWith('/catalog');
  const [isSharePopupOpen, setIsSharePopupOpen] = useState<boolean>(false);
  const modalRef = useRef();
  useClickOutside(modalRef, setIsSharePopupOpen);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [popUpActive, setPopUpActive] = useState(false);
  const [isShowcasePublished, setIsShowcasePublished] = useState<boolean>(false);
  const [coverImage, setCoverImage] = useState<string>('');
  const [logoImage, setLogoImage] = useState<string>('');
  const [carrierName, setCarrierName] = useState<string>('');
  const [carrierDescription, setCarrierDescription] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [address, setAddress] = useState<DaDataSuggestion<DaDataAddress> | undefined>(undefined);
  const [quarryType, setQuarryType] = useState<string>('');
  const [materials, setMaterials] = useState<IMaterials[]>([]);
  const [workRange, setWorkRange] = useState<string>('');
  const [link, setLink] = useState<string>('');
  const [images, setImages] = useState([]);
  const [isChangedCover, setIsChangedCover] = useState<boolean>(false);
  const [isChangedLogo, setIsChangedLogo] = useState<boolean>(false);
  const [quarry, setQuarry] = useState<string | { value: string; label: string }>('');
  const [quarryOptions, setQuarryOptions] = useState<{ value: string; label: string }[]>([]);
  const [quarryId, setQuarryId] = useState<string>('');
  const [isFavorite, setIsFavorite] = useState<boolean>(false);

  useEffect(() => {
    getQuarriesNames();
  }, []);
  const [errors, setErrors] = useState<{ carrierName: boolean; quarry: boolean }>({
    carrierName: false,
    quarry: false,
  });

  useEffect(() => {
    if (errors?.carrierName && carrierName) setErrors({ ...errors, carrierName: false });
    if (errors?.quarry && typeof quarry !== 'string') setErrors({ ...errors, quarry: false });
  }, [carrierName, quarry]);

  useEffect(() => {
    setIsLoading(true);
    getShowcase();
    setIsLoading(false);
  }, [isEdit]);

  useEffect(() => {
    if (typeof quarry !== 'string' && isEdit) getInfoQuarry(quarry?.value);
  }, [quarry]);

  const handleFavoriteClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    changeFavorite();
  };

  const changeFavorite = async () => {
    try {
      if (!isFavorite) {
        const response = await fetchPost(`/users/favourites/`, 'POST', {
          category: 'showcases',
          itemId: Number(quarryId),
        });
        if (toastError(response)) return;
      } else {
        const response = await fetchPost(`/users/favourites/${quarryId}`, 'DELETE', {
          category: 'showcases',
        });
        if (toastError(response)) return;
      }

      setIsFavorite(!isFavorite);
    } catch (e) {
      console.log(e);
    }
  };

  const getInfoQuarry = async (id: string) => {
    try {
      if (isCatalogPath) return;
      const response = await fetchGet(`/quarries/${id}`, {});
      if (toastError(response)) return;
      const quarryInfo = response?.response;
      setPhoneNumber(quarryInfo?.dispatcher?.phone || 'Нет данных');
      setAddress({
        ...address,
        value: quarryInfo?.address?.address,
        data: {
          ...address?.data,
          geo_lat: quarryInfo?.address?.latitude?.toString(),
          geo_lon: quarryInfo?.address?.longitude?.toString(),
        },
      });
      setQuarryType(quarryInfo?.quarryType || 'Нет данных');
      setWorkRange(
        quarryInfo?.workSchedulesGraph?.aroundTheClock === true
          ? 'Круглосуточно'
          : quarryInfo?.workSchedulesGraph?.from + '–' + quarryInfo?.workSchedulesGraph?.to,
      );
    } catch (e) {
      console.log(e);
    }
  };

  const getQuarriesNames = async () => {
    try {
      if (isCatalogPath) return;
      const response = await fetchGet('/quarries/list', {});
      if (toastError(response)) return;
      setQuarryOptions(
        response?.response?.map(quarry => {
          return { value: quarry?.id, label: quarry?.name };
        }),
      );
    } catch (e) {
      console.log(e);
    }
  };

  const deleteShowcase = async () => {
    try {
      const response = await fetchPost(`/supplier/showcases/${id}`, 'DELETE', {});
      if (toastError(response)) return;
      toast.success(<div>Витрина удалена</div>);
      navigate('/showcases');
    } catch (e) {
      console.log(e);
    }
  };

  const saveChanges = () => {
    if (typeof quarry === 'string' || !carrierName) {
      setErrors({ quarry: typeof quarry === 'string', carrierName: !carrierName });
      return;
    }
    if (images?.[0] && isChangedLogo) deleteImages(images?.[0]?.id, images[0]?.name);
    if (images?.[1] && isChangedCover) deleteImages(images?.[1]?.id, images[1]?.name);
    editShowcase();
  };

  const publishShowcase = async () => {
    try {
      if (!link) {
        const publish = await fetchGet(`/supplier/showcases/get-link/${id}`, {});
        if (toastError(publish)) return;
      }
      const enable = await fetchGet(`/supplier/showcases/switch-link-mode/${id}`, {});
      if (toastError(enable)) return;
      toast.success(<div>{!isShowcasePublished ? 'Витрина опубликована' : 'Витрина снята с публикации'}</div>);
      setIsShowcasePublished(!isShowcasePublished);
    } catch (e) {
      console.log(e);
    }
  };

  const editShowcase = async () => {
    try {
      const response = await fetchPost(
        `/supplier/showcases/${id}`,
        'PATCH',
        clearObject({
          title: carrierName,
          description: carrierDescription || null,
          header: isChangedCover ? coverImage : undefined,
          logo: isChangedLogo ? logoImage : undefined,
          quarryId: typeof quarry !== 'string' ? quarry?.value : undefined,
        }),
      );
      if (toastError(response)) return;
      toast.success(<div>Изменения витрины сохранены</div>);
      setIsEdit(false);
      return;
    } catch (e) {
      console.log(e);
    }
  };

  const getShowcase = async () => {
    try {
      const response = await fetchGet(
        `/${isCatalogPath ? 'buyer' : 'supplier'}/showcases${isShowcasePath ? '' : '/store'}/${isCatalogPath ? Number(id) : id}`,
        {},
      );

      if (toastError(response)) return;
      const showcaseInfo = response?.response;
      if (isCatalogPath) {
        setQuarryId(showcaseInfo?.id);
        setIsFavorite(showcaseInfo?.favorite);
      }
      setAddress({
        ...address,
        value: showcaseInfo?.quarry?.address?.address,
        data: {
          ...address?.data,
          geo_lat: showcaseInfo?.quarry?.address?.latitude?.toString(),
          geo_lon: showcaseInfo?.quarry?.address?.longitude?.toString(),
        },
      });
      if (showcaseInfo?.description) setCarrierDescription(showcaseInfo?.description);
      setPhoneNumber(showcaseInfo?.quarry?.phone);
      setQuarryType(showcaseInfo?.quarry?.quarryType || 'Нет данных');
      setCarrierName(showcaseInfo?.title);
      setWorkRange(
        showcaseInfo?.quarry?.workSchedulesGraph?.aroundTheClock === true
          ? 'Круглосуточно'
          : showcaseInfo?.quarry?.workSchedulesGraph?.from + '–' + showcaseInfo?.quarry?.workSchedulesGraph?.to,
      );
      const coverImageInfo = showcaseInfo?.files?.find(item => item?.category === 'Шапка витрины');
      const logoImageInfo = showcaseInfo?.files?.find(item => item?.category === 'Лого витрины');
      setImages([logoImageInfo, coverImageInfo]);
      setCoverImage(coverImageInfo?.location ? coverImageInfo?.location : '');
      setLogoImage(logoImageInfo?.location ? logoImageInfo?.location : '');
      if (showcaseInfo?.link) setLink(showcaseInfo?.link);
      if (showcaseInfo?.isEnabledLink) setIsShowcasePublished(showcaseInfo?.isEnabledLink);
      setQuarry({ value: showcaseInfo?.quarry?.id, label: showcaseInfo?.quarry?.name });
      const responseMaterial: IMaterials[] = response?.response?.materialGroups?.map(material => {
        return {
          ...material,
          idShowCase: response?.response?.id,
        };
      });
      setMaterials(responseMaterial);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteImages = async (id: string, name: string) => {
    const deleteImage = await fetch(`${SUBSERVER}/api/files/${id}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
      credentials: 'include',
      body: JSON.stringify({
        communicationCategory: 'showcases',
        name,
        objectUuid: '',
      }),
    });
  };
  return (
    <>
      {!isShowcasePath && !isMobile && <PublicShowcaseHeader />}
      <div
        className={!isShowcasePath && 'Page'}
        style={isShowcasePath ? { width: '100%' } : isMobile ? {} : { marginTop: '24px' }}
      >
        <div className={!isShowcasePath && !isMobile && 'Container'} style={isShowcasePath ? { width: '100%' } : {}}>
          <PageWrapper style={!isShowcasePath && isMobile ? { padding: '40px 16px' } : {}}>
            {isShowcasePath && (
              <div
                className={styles.topContainer + ' ' + (isMobile && isCatalogPath ? styles.topContainerCatalog : '')}
              >
                <Link
                  className="button-back"
                  to={isCatalogPath ? '' : '/showcases'}
                  onClick={() => {
                    if (isCatalogPath) {
                      navigate(-1);
                      return;
                    }
                    navigate('/showcases');
                  }}
                >
                  <div className="button-back__svg">
                    <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  {isCatalogPath ? 'Назад' : 'Вернуться к списку витрин'}
                </Link>
                {!isCatalogPath ? (
                  !isEdit && (
                    <ShowcaseDescriptionButtons
                      setIsEdit={setIsEdit}
                      isSharePopupOpen={isSharePopupOpen}
                      setIsSharePopupOpen={setIsSharePopupOpen}
                      isShowcasePublished={isShowcasePublished}
                      link={link}
                      logoImage={logoImage}
                      carrierName={carrierName}
                    />
                  )
                ) : (
                  <div className={styles.favoriteButtonContainer} onClick={e => handleFavoriteClick(e)}>
                    {isFavorite ? (
                      <FavoriteShowcasesSvg color="currentColor" width={isMobile ? '24px' : '20px'} />
                    ) : (
                      <FavoriteSvg width={isMobile ? '24px' : '20px'} />
                    )}
                    {!isMobile && (
                      <p className={styles.favoriteButtonText}>{isFavorite ? 'В избранном' : 'В избранное'}</p>
                    )}
                  </div>
                )}
              </div>
            )}
            <div className={styles.container}>
              {isEdit && (
                <div className={styles.titleContainer}>
                  <div className={styles.title + ' textH4 textH4_medium'}>Редактирование витрины</div>

                  <div className={styles.buttonsContainer}>
                    <ButtonNotFilled
                      text={'Удалить витрину'}
                      disabled={false}
                      svg={<Basket />}
                      onClick={() => setPopUpActive(true)}
                      width={isMobile ? '100%' : '175px'}
                      height="40px"
                      sizeText="small"
                    />
                    <ButtonNotFilled
                      text={isShowcasePublished ? 'Снять с публикации' : 'Опубликовать витрину'}
                      disabled={false}
                      onClick={() => publishShowcase()}
                      width={isMobile ? '100%' : '186px'}
                      height="40px"
                      sizeText="small"
                    />
                    <CreateEditButton isCreatePage={false} disabled={false} createShowcase={saveChanges} />
                  </div>
                </div>
              )}
              <ShowcaseCover
                isCreatePage={false}
                isEdit={isEdit}
                coverImage={coverImage}
                setCoverImage={setCoverImage}
                setIsChangedCover={setIsChangedCover}
              />

              {isEdit ? (
                <ShowcaseDescription
                  isCreatePage={false}
                  logoImage={logoImage}
                  setLogoImage={setLogoImage}
                  carrierName={carrierName}
                  setCarrierName={setCarrierName}
                  carrierDescription={carrierDescription}
                  setCarrierDescription={setCarrierDescription}
                  setIsChangedLogo={setIsChangedLogo}
                  error={errors.carrierName}
                  setError={setErrors}
                />
              ) : (
                <ShowcaseDescriptionView
                  logoImage={logoImage}
                  carrierName={carrierName}
                  carrierDescription={carrierDescription}
                />
              )}

              <div className={styles.mainInfoContainer}>
                {isShowcasePath && isEdit && (
                  <CustomSelect
                    options={quarryOptions}
                    setValue={setQuarry}
                    placeholder={'Выберите карьер из списка'}
                    isClearable={false}
                    error={errors.quarry}
                    isSearchable={false}
                    noOptionsMessage={''}
                    isDisabled={!isEdit}
                    value={quarry}
                  />
                )}
                <div className={styles.mainInfoSubContainer} style={isEdit ? { paddingTop: '16px' } : {}}>
                  <div className={styles.mainInfoSubTitle}>Основная информация</div>
                  <ShowcaseMainInfo
                    phoneNumber={phoneNumber}
                    address={address}
                    quarryType={quarryType}
                    workRange={workRange}
                  />
                </div>
              </div>
              <MaterialCatalog materials={materials} quarry={quarry} />
            </div>
            {popUpActive && (
              <DeleteShowcasePopup
                popUpActive={popUpActive}
                setPopUpActive={setPopUpActive}
                carrierName={carrierName}
                deleteShowcase={deleteShowcase}
              />
            )}
          </PageWrapper>
        </div>
      </div>
    </>
  );
};
