import React from 'react';

type TClockTimeThreeOutlineSvg = {
  size?: string;
  color?: string;
};

const ClockTimeThreeOutlineSvg = ({ size = '16px', color = 'var(--color-gray600)' }: TClockTimeThreeOutlineSvg) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_30091_75115)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.83301 7.99967C1.83301 4.59392 4.59392 1.83301 7.99967 1.83301C11.4054 1.83301 14.1663 4.59392 14.1663 7.99967C14.1663 11.4054 11.4054 14.1663 7.99967 14.1663C4.59392 14.1663 1.83301 11.4054 1.83301 7.99967ZM7.99967 0.833008C4.04163 0.833008 0.833008 4.04163 0.833008 7.99967C0.833008 11.9577 4.04163 15.1663 7.99967 15.1663C11.9577 15.1663 15.1663 11.9577 15.1663 7.99967C15.1663 4.04163 11.9577 0.833008 7.99967 0.833008ZM8.49967 5.33301C8.49967 5.05687 8.27582 4.83301 7.99967 4.83301C7.72353 4.83301 7.49967 5.05687 7.49967 5.33301V7.99967C7.49967 8.13228 7.55235 8.25946 7.64612 8.35323L9.31279 10.0199C9.50805 10.2152 9.82463 10.2152 10.0199 10.0199C10.2152 9.82463 10.2152 9.50805 10.0199 9.31279L8.49967 7.79257V5.33301Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_30091_75115">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ClockTimeThreeOutlineSvg;
